/** @jsx jsx */
import { jsx, Text, useThemeUI } from "theme-ui";
import React, { ReactElement } from "react";
import {
  differenceInMinutes,
  addMinutes
} from "date-fns";
import { formatDistanceStrict } from "utils/datetime";
import { format, getRoundedDate } from "utils/datetime";
import Button from "components/Button/Button";
import { getFromNow, Time } from "./calculate";
import { Trans } from "@lingui/react";

interface OnclickTime {
  From: string;
  To: string;
  Duration: number;
}

interface Props {
  buttons: {
    id: string;
    label: string;
    /**
     * minutes
     */
    value: number;
  }[];
  localNextTime: {
      From: string;
      To: string;
      Duration: number;
  };
  currentDate: Date;
  equalWidth?: boolean;
  direct?: boolean;
  untilEndOfDay?: boolean;
  onClick: (val: number, time: OnclickTime) => void;
}

export default function BookingButtons({
  buttons,
  localNextTime,
  currentDate,
  onClick,
  equalWidth = true,
  direct = true,
  untilEndOfDay = false,
}: Props): ReactElement {
  const { theme } = useThemeUI();

  // @ts-ignore
  const buttonWidth = equalWidth ? theme?.sizes["48"] : "auto";

  return (
    <React.Fragment>
      {buttons
        .filter(
          (button) =>
            untilEndOfDay ||
            (localNextTime && button.value < localNextTime.Duration)
        )
        .map((button, index) => {
          const From = getRoundedDate(5, new Date(localNextTime?.From));
          const To = addMinutes(
            getRoundedDate(5, new Date(localNextTime?.From)),
            button.value
          );
          let time: Time = {
            From,
            To,
            Duration: differenceInMinutes(To, From),
          };

          const from = format(new Date(time.From), "p");
          const to = format(new Date(time.To), "p");
          const duration = formatDistanceStrict(
            addMinutes(currentDate, button.value),
            currentDate
          );

          const displayFrom = direct ? getFromNow(time) : new Date(time.From);

          return (
            <Button
              data-testid={`${from}-${to}-${duration}`}
              key={index}
              style={{ width: buttonWidth }}
              variant="outline"
              onClick={() =>
                onClick(button.value, {
                  From: time.From.toISOString(),
                  To: time.To.toISOString(),
                  Duration: time.Duration,
                })
              }
            >
              {untilEndOfDay ? <div sx={{ overflow: 'hidden',textOverflow: 'ellipsis' }}>
                <Trans id="common.bookRemainingTime" />
              </div> : null}
              <div>
                {duration}
              </div>
              <Text
                sx={{
                  fontSize: theme.fontSizes ? theme.fontSizes[0] : undefined,
                }}
              >
                {format(displayFrom, "p")} {` - `}
                {to}
              </Text>
            </Button>
          );
        })}
    </React.Fragment>
  );
}
