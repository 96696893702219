/** @jsx jsx */
import { jsx, useThemeUI, Box } from "theme-ui";
import React, { useMemo, useCallback } from "react";
import { useSelect } from "downshift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addOpacityToColor } from "../../theme";
import { useI18n } from "providers/I18nProvider/I18nProvider";
import SeFlag from "svg/flags/se.svg";
import FiFlag from "svg/flags/fi.svg";
import GbFlag from "svg/flags/gb.svg";
import NoFlag from "svg/flags/no.svg";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const LanguageUI: React.FC<Props> = ({ show = false }) => {
  const appSettings = useAppSettings();
  const i18n = useI18n();
  const onChange = useCallback((lang: any) => i18n?.setLanguage(lang), [i18n]);

  const languages = useMemo(
    () => [
      {
        value: "sv",
        flag: SeFlag,
      },
      {
        value: "fi",
        flag: FiFlag,
      },
      {
        value: "en",
        flag: GbFlag,
      },
      {
        value: "no",
        flag: NoFlag,
      },
    ],
    []
  );

  const selectedItem = useMemo(
    () => languages.find((item) => item.value === i18n?.language),
    [i18n, i18n?.language, languages]
  );

  const onSelectedItemChange = useCallback(
    (item) => {
      if (onChange) {
        try {
          onChange(item.selectedItem?.value);
        } catch (e) {
          console.error({ e });
        }
      }
    },
    [onChange]
  );

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    //@ts-ignore
    items: languages,
    selectedItem,
    onSelectedItemChange,
  });

  const context = useThemeUI();
  const { theme } = context;
  const chevronType = isOpen ? "chevron-up" : "chevron-down";

  return (
    <Box
      data-testid="languageSwitcher"
      sx={{
        zIndex: 100,
        position: "relative",
        opacity: appSettings?.ShowLanguage || show ? "" : 0,
        pointerEvents: appSettings?.ShowLanguage || show ? "all" : "none",
      }}
    >
      {false && <label {...getLabelProps()}>{false}</label>}
      <button
        {...getToggleButtonProps()}
        type="button"
        sx={{
          padding: "10px 0px",
          boxSizing: "border-box",
          borderColor: "transparent",
          borderRadius: "6px",
          fontSize: "1em",
          fontWeight: "600",
          letterSpacing: "2px",
          textDecoration: "none",
          transition: "all 0.2s ease-in-out",
          whiteSpace: "nowrap",
          WebkitFontSmoothing: "antialiased",
          WebkitAppearance: "none",
          MozAppearance: "none",
          color: `${theme?.colors?.text}`,
          cursor: "pointer",
          maxWidth: "100%",
          outline: "none",
          bg: "transparent",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          // @ts-ignore
          fontFamily: theme?.fonts?.body,
        }}
      >
        <img
          src={selectedItem?.flag}
          sx={{ width: "50px", height: "25px" }}
          alt={selectedItem?.value}
        />

        <FontAwesomeIcon
          style={{
            display: "flex",
            marginLeft: "12px",
            justifySelf: "flex-end",
          }}
          icon={["fas", chevronType]}
        />
      </button>
      <ul
        {...getMenuProps()}
        sx={{
          maxHeight: "200px",
          overflowY: "auto",

          position: "absolute",
          margin: "0",
          borderTop: "0",
          bg: `${addOpacityToColor(theme?.colors?.background, 50)}`,
          backdropFilter: "blur(5px)",
          color: `${theme?.colors?.text}`,
          listStyle: "none",
          padding: "0",
          outline: "none",
          minHeight: "200px",
        }}
      >
        {isOpen &&
          languages.map((item, index) => (
            <li
              aria-label={item.value}
              sx={{
                padding: "12px",
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                backgroundColor:
                  highlightedIndex === index ? `${theme?.colors?.primary}` : "",
                color:
                  highlightedIndex === index
                    ? `${theme?.colors?.background}`
                    : "",
              }}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              <img
                src={item.flag}
                sx={{ width: "50px", height: "25px" }}
                alt={selectedItem?.value}
              />
            </li>
          ))}
      </ul>
    </Box>
  );
};

export default LanguageUI;
