import React from "react";
import styles from "./Error.module.scss";
import Button from "components/Button/Button";

type OnCloseFn = () => void;

interface ErrorProps {
  message?: string;
  onClose?: OnCloseFn;
}

const Error: React.FC<ErrorProps> = ({ message, onClose }) => {
  return (
    <div className={styles.error}>
      <h1>Ett fel uppstod</h1>
      <h4>{message}</h4>
      <Button onClick={onClose}>OK</Button>
    </div>
  );
};

export default Error;
