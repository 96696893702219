import React from "react";
import { Trans } from "@lingui/macro";
import styles from "./Offline.module.scss";

const Offline = () => {
  return (
    <div className={styles.offline}>
      <h1>
        <Trans id="offline.youAreOffline">You are offline</Trans>
        <span className={styles.horizontalDivider} />
        <Trans id="offline.checkConnection">
          Check your internet connection.
        </Trans>
      </h1>
    </div>
  );
};

export default Offline;
