/** @jsx jsx */
import {  jsx, Flex, useThemeUI } from "theme-ui";
import React from "react";
import { format } from "utils/datetime";
import { Trans } from "@lingui/macro";
import {
    From,
    To,
    Booking,
    BookingFromTo,
    BookingMoreInfo,
    Divider,
    BookedComment,
    Customer,    
  } from "./styled";
import { Live } from "./Live";

interface LiveBookingProps {
    booking: any;
    currentDate: Date;
    ShowName: boolean
  }
  
const LiveBooking: React.FC<LiveBookingProps> = (props) => {
  const { booking, currentDate, ShowName } = props;
  const { theme } = useThemeUI();
  
    return (
      <Booking>
        <Flex id="BookingHolder">
          <BookingFromTo theme={theme}>
            <From theme={theme}>
              {format(new Date(props.booking.From), "p")}
            </From>
            <To theme={theme}>{format(new Date(booking.To), "p")}</To>
          </BookingFromTo>
          <BookingMoreInfo theme={theme}>
            <Flex
              sx={{
                flexDirection: "column",
              }}
            >
              {ShowName ? (
                (
                  <React.Fragment>
                    <Trans id="conference.bookedBy" />
                    <Customer>
                      {booking.Customer?.Firstname}{" "}
                      {booking.Customer?.Lastname}
                    </Customer>
                </React.Fragment>
                )
              ) : <Trans id="conference.occupied" />}
              <BookedComment>{booking.BookedComments}</BookedComment>
            </Flex>
  
            <Flex
              sx={{
                justifySelf: "flex-end",
                marginLeft: "auto",
              }}
            >
              <Live booking={booking} date={currentDate}>
                <span
                  sx={{
                    padding: "3px",
                  }}
                  role="img"
                  aria-label="Current event"
                >
                  ⚪
                </span>
              </Live>
            </Flex>
          </BookingMoreInfo>
        </Flex>
  
        <Divider theme={theme} />
      </Booking>
    );
  }

  export default LiveBooking;