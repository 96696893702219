/** @jsx jsx */
import { jsx, useThemeUI, Box } from "theme-ui";
import React, { useMemo, useCallback } from "react";
import { useSelect } from "downshift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBWColorFromContext } from "../../theme";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onChange?: (changes: any) => void;
  value?: string | number;
  items?: { value: string; text: string | number }[];
  label?: string;
  placeholder?: string;
}

const Select: React.FC<Props> = ({
  items = [],
  value,
  onChange,
  label,
  placeholder,
  ...restProps
}) => {
  const selectedItem = useMemo(
    () => items.find((item) => item.value === value),
    [items, value]
  );

  const onSelectedItemChange = useCallback(
    (item) => {
      if (onChange) {
        try {
          onChange(item.selectedItem?.value);
        } catch (e) {
          console.error({ e });
        }
      }
    },
    [onChange]
  );

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange,
  });

  const context = useThemeUI();
  const { theme } = context;
  const chevronType = isOpen ? "chevron-up" : "chevron-down";

  return (
    <Box sx={{ position: "relative" }}>
      {label && <label {...getLabelProps()}>{label}</label>}
      <button
        aria-label={restProps["aria-label"]}
        {...getToggleButtonProps()}
        type="button"
        sx={{
          width: "100%",
          boxSizing: "border-box",
          borderColor: "transparent",
          borderRadius: "6px",
          fontSize: "1em",
          fontWeight: "600",
          letterSpacing: "2px",
          margin: "0 0 15px",
          padding: "0 26px",
          textDecoration: "none",
          transition: "all 0.2s ease-in-out",
          whiteSpace: "nowrap",
          WebkitFontSmoothing: "antialiased",
          WebkitAppearance: "none",
          MozAppearance: "none",
          boxShadow:
            "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          color: `${theme?.colors?.text}`,
          height: "56px",
          cursor: "pointer",
          maxWidth: "100%",
          outline: "none",
          bg: `${theme?.colors?.background}`,
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          border: `1px solid ${getBWColorFromContext(
            theme.colors?.background
          )}`,
          // @ts-ignore
          fontFamily: theme?.fonts?.body,
        }}
      >
        {selectedItem?.text || placeholder}

        <FontAwesomeIcon
          style={{
            display: "flex",
            marginLeft: "auto",
            justifySelf: "flex-end",
          }}
          icon={["fas", chevronType]}
        />
      </button>
      <ul
        {...getMenuProps()}
        sx={{
          maxHeight: "200px",
          overflowY: "auto",
          width: "100%",
          position: "absolute",
          margin: "0",
          border: isOpen
            ? `1px solid ${getBWColorFromContext(theme.colors?.background)}`
            : "",
          borderTop: "0",
          bg: `${theme?.colors?.background}`,
          color: `${theme?.colors?.text}`,
          listStyle: "none",
          padding: "0",
          borderRadius: "6px",
          outline: "none",
          zIndex: 100,
        }}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              sx={{
                padding: " 12px 26px",
                fontSize: "18px",
                zIndex: 999,
                backgroundColor:
                  highlightedIndex === index ? `${theme?.colors?.primary}` : "",
                color:
                  highlightedIndex === index
                    ? `${theme?.colors?.background}`
                    : "",
              }}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.text}
            </li>
          ))}
      </ul>
    </Box>
  );
};

export default Select;
