/** @jsx jsx */
import { jsx, Text, Flex, Box } from "theme-ui";
import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
import { useTime } from "providers/TimeProvider/TimeProvider";
import {
  isAfter,
  isWithinInterval,
  addWeeks,
  getWeek,
  endOfWeek,
  startOfWeek,
  addYears,
  addMinutes,
} from "date-fns";
import { format, toDateISOString } from "utils/datetime";
import { Trans } from "@lingui/macro";
import { RouteParams } from "routes/Routes";
import Button from "components/Button/Button";
import Content, { ContentFull } from "components/Content/Content";
import Error from "components/Error/Error";
import Week from "./Week";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Settings from "components/SettingsMenu/SettingsMenu";
import Time from "components/Time/Time";
import UserButtons from "UserButtons/UserButtons";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import * as api from "api/api";
import { useToasts } from "react-toast-notifications";
import { queryClient } from "api/queryClient";
import { getErrorResponse } from "utils/common";

export const LEFT_TIME_MARGIN = -4; // minutes

const getAvailableTimes = async (key: any) => {
  const [, serviceId, companyId, from, to] = key.queryKey;

  return api.getAvailableTimes(
    {
      url: `/services/${serviceId}/availabletimes`,
      params: {
        ServiceId: serviceId,
        CompanyId: companyId,
        From: format(from, "yyyy-MM-dd"),
        To: format(to, "yyyy-MM-dd"),
      },
    },
    {}
  );
};

const Service: React.FC = () => {
  const { addToast } = useToasts();
  const [freeSlots, setFreeSlots] = useState(true);
  const [date, setDate] = useState(new Date());
  const [from, setFrom] = useState(startOfWeek(date, { weekStartsOn: 1 }));
  const [to, setTo] = useState(endOfWeek(date, { weekStartsOn: 1 }));
  const customer = useCustomer();
  const { authorised, companyId } = useAuthentication();

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const params = useParams<RouteParams>();

  const { currentDate } = useTime();

  useEffect(() => {
    setFrom(startOfWeek(date, { weekStartsOn: 1 }));
    setTo(endOfWeek(date, { weekStartsOn: 1 }));
  }, [date]);

  const bookingMutation = useMutation<BokaMera.Booking, any, any, any>(
    (data: any) => api.createBooking({}, data),
    {
      onMutate: (booking) => {
        return { booking };
      },
      onError: (error, newBookings, context) => {
        addToast(getErrorResponse(error), {
          appearance: "error",
          autoDismiss: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["bookings-customer", companyId, customer?.Id]);
        queryClient.invalidateQueries(["bookings"]);
        setTimeout(() => bookingMutation.reset(), 50);
      },
    }
  );

  const directBookingMutation = useMutation<BokaMera.Booking, {booking: BokaMera.Booking}, any, any>(
    (data: any) => {
      return api.createDirectBook({}, data);
    },
    {
      onMutate: (booking) => {
        return { booking };
      },
      onError: (error) => {
        addToast(getErrorResponse(error), {
          appearance: "error",
          autoDismiss: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["bookings"]);
        queryClient.invalidateQueries(["bookings-customer", companyId, customer?.Id]);
        setTimeout(() =>directBookingMutation.reset(), 50);
      }
    }
  );

  const availableTimes = useQuery<BokaMera.AvailableTimes, any, any, any>(
    ["getAvailableTimes", params.serviceId, params.companyId, from, to],
    getAvailableTimes,
    {
      enabled: !!(authorised && params.serviceId && params.companyId)
    }
  );

  const bookings = useQuery(
    ["bookings", companyId],
    ({ queryKey }) =>
      api.getBookings({
        params: {
          CompanyId: queryKey[1],
          CustomerId: queryKey[2],
          BookingStart: format(new Date(), "yyyy-MM-dd"),
          BookingEnd: format(addYears(new Date(), 1), "yyyy-MM-dd"),
          CompanyBookings: true,
          StatusIds: "1,5",
          IncludeCustomerInformation: true,
        },
      }),
    {
      enabled: !!(authorised && companyId),
    }
  );

  useEffect(() => {
    const nextAvailableTime = availableTimes.data?.Times.filter(
      (time: BokaMera.Time) => time.Free > 0
    )[0];

    if (
      !freeSlots &&
      getWeek(date) !== getWeek(new Date(nextAvailableTime?.From || date)) &&
      nextAvailableTime?.From
    ) {
      setDate(new Date(nextAvailableTime.From));
    }

    if (
      availableTimes.data?.Times.some((time: BokaMera.Time) => time.Free > 0)
    ) {
      setFreeSlots(true);
    } else {
      setFreeSlots(false);
    }
  }, [availableTimes.data, date, freeSlots]);

  const findNextAvailbleTime = () => {
    setFrom(addWeeks(startOfWeek(date, { weekStartsOn: 1 }), 1));
    setTo(addWeeks(endOfWeek(date, { weekStartsOn: 1 }), 3));
  };

  const onClickTime = useCallback(
    (time: BokaMera.Time, directBooking: boolean = false) => {
      directBooking
        ? directBookingMutation.mutate({
            DatesToRepeat: [
              {
                From: addMinutes(new Date(time.From), 1).toISOString(),
                To: time.To,
                Quantities: [
                  {
                    Quantity: 1,
                  },
                ],
              },
            ],
            ServiceId: params.serviceId,
            CustomerId: customer?.Id,
            AllowBookingOutsideSchedules: true,
          })
        : bookingMutation.mutate({
            From: time.From,
            To: time.To,
            CompanyId: params.companyId,
            ServiceId: params.serviceId,
            CustomerId: params.customerId,
            PinCode: customer?.PinCode,
          });
    },
    [
      bookingMutation,
      directBookingMutation,
      customer,
      params.companyId,
      params.customerId,
      params.serviceId,
    ]
  );

  const currentFutureBookings =
    bookings?.data?.Results?.filter(
      (booking) =>
        isAfter(new Date(booking?.To), currentDate) ||
        isWithinInterval(currentDate, {
          start: addMinutes(new Date(booking.From), LEFT_TIME_MARGIN),
          end: new Date(booking.To),
        })
    ) || null;

  const activeBookingMutation = bookingMutation.isLoading || bookingMutation.isSuccess
    ? bookingMutation
    : directBookingMutation;

  return (
    <Flex style={{ flex: 1 }} data-testid="service">
      <Settings
        visible={settingsVisible}
        setSettingsVisible={setSettingsVisible}
      />
      <Content>
        <ContentFull>
          <Flex
            id="header"
            sx={{ alignItems: "center", justifyContent: "start" }}
          >
            <Time />
            <Flex id="middle">
              <Button
                style={{ minWidth: "70px" }}
                onClick={() => setDate(addWeeks(date, -1))}
              >
                <FontAwesomeIcon size="1x" icon={["fas", "arrow-left"]} />
              </Button>
              <Text
                as="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px",
                }}
              >
                <Trans id="common.week">Vecka</Trans> {getWeek(date)}
              </Text>
              <Button
                style={{ minWidth: "70px" }}
                onClick={() => setDate(addWeeks(date, 1))}
              >
                <FontAwesomeIcon size="1x" icon={["fas", "arrow-right"]} />
              </Button>
            </Flex>
            <Box />
            <Box />
          </Flex>
          {availableTimes.error && (
            <Error message={availableTimes.error ? availableTimes.error : ""} />
          )}
          <Week
            loading={availableTimes.isLoading}
            onClick={onClickTime}
            availableTimes={availableTimes.data}
            bookings={currentFutureBookings}
            startOfWeek={startOfWeek(date, { weekStartsOn: 1 })}
            findNextAvailbleTime={findNextAvailbleTime}
            bookingMutation={activeBookingMutation}
          />
          <UserButtons style={{ alignSelf: "start" }} buttons={["back"]} />
        </ContentFull>
      </Content>
    </Flex>
  );
};

export default Service;
