/** @jsx jsx */
import { jsx, Alert, useThemeUI, Text, Flex, Box } from "theme-ui";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { useHistory } from "react-router-dom";
import useInterval from "hooks/useInterval";
import constants from "config/constants";
import { useModal } from "react-modal-hook";
import Dialog from "components/Dialog/Dialog";
import { Trans } from "@lingui/react";
import Button from "components/Button/Button";
import { deleteFromStorage } from "@rehooks/local-storage";
import { customAlphabet } from "nanoid";
import * as api from "api/api";
import { CancelLicense } from "interfaces/Licenses";
import { useQuery, useMutation, UseQueryResult } from "react-query";
import { useToasts } from "react-toast-notifications";
import { getErrorResponse } from "utils/common";
import { useCompany } from "providers/CompanyProvider/CompanyProvider";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";

const ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoidShort = customAlphabet(ALPHABET, 6);

interface ProviderProps {
  children: React.ReactNode;
  companyId?: string;
}

interface ContextProps {
  licenceId: string;
  license: any;
  handleCreateLicense: any;
  licenses: any;
  licenseTypes: any;
  customCode: any;
  
  billingInformation: any;
}

export const LICENSE_ID_STORAGE_KEY = "licenseId";

export const LicenceContext = React.createContext<ContextProps>({
  licenceId: "",
  billingInformation: null,
  handleCreateLicense: () => {},
  license: undefined,
  licenses: undefined,
  licenseTypes: undefined,
  customCode: "",
});

const CORRECT_LICENSE_TYPE_ID = 15;
const UPDATE_INTERVAL = 1000 * 60 * 60 * 24;

const shouldLoad = (state: UseQueryResult) =>
  !state.isError && !state.data && !state.isLoading;

export const useLicence = () => useContext(LicenceContext);

const LicenceProvider = ({ children }: ProviderProps) => {
  const company = useCompany();
  const { addToast } = useToasts();
  const {theme} = useThemeUI();
  // const [companyId] = useLocalStorage<string>("companyId");
  // @ts-ignore
  const [licenseId, setStorageLicenseId] = useLocalStorage<string>("licenseId");
  const [oldLicenseId, setOldLicenseId] = useState('');
  const history = useHistory();
  const { authorised, companyId } = useAuthentication();

  const customCode = nanoidShort();


  useEffect(() => {
    if (constants.LICENSE_ID) {
      setStorageLicenseId(constants.LICENSE_ID);
    }
  }, []);

  const licenseTypes = useQuery("licenseTypes", () =>
    api.getLicenseTypes({
      params: {
        CountryId: "SE",
        ExtraLicenseOption: true,
        IncludeLicenseItems: false,
        IncludeLicensePrices: true,
      },
    }), {
      refetchOnWindowFocus: false,
    }
  );

  const licenses = useQuery("licenses", () => api.getLicenses(
    {
      // @ts-ignore
      CompanyId: company?.Id,
      OnlyActiveLicenses: true,
      TypeId: 15
    }), {
    refetchOnWindowFocus: false,
    enabled: !!company?.Id
  });
  
  const billingInformation = useQuery("billingInformation", () => api.getBillingInformation(), {
    refetchOnWindowFocus: false,
    enabled: authorised
  });

  const newLicense = useMutation(
    "createLicense",
    (data: any) => api.createLicense({}, data),
    {
      onSuccess: () => {
        licenses.refetch();
      },
      onError: (err) => {
        addToast(getErrorResponse(err), {
          appearance: "error",
          autoDismiss: true,
        });
      },
    }
  );

  const cancelLicense = useMutation(
    "cancelLicense",
    (data: BokaMera.CancelLicense) => api.cancelLicense({}, data),
    {
      onSuccess: () => {
        licenses.refetch();
      },
      onError: (err) => {
        addToast(getErrorResponse(err), {
          appearance: "error",
          autoDismiss: true,
        });
      },
    }
  );

  const updateLicense = useCallback(() => {
    if (
      constants.MODE !== "demo" &&
      licenses.data &&
      licenseId &&
      Array.isArray(licenses.data) &&
      licenses.data?.filter(
        (l: any) =>
          l.TypeId === CORRECT_LICENSE_TYPE_ID && l.MetaData === licenseId
      ).length === 0
    ) {
      showLicenseActModal();
    } else {
      hideLicenseActModal();
    }
  }, [licenses, licenseId, oldLicenseId]);

  useEffect(() => {
    if (company?.Id && !licenseId) {
      history.push(`/${company.Id}`);
    }
  }, [history, company, licenseId]);

  // Effectively when component mounts with necessary dependencies
  useEffect(() => {
    if (company?.Id && shouldLoad(licenses)) {
      licenses.refetch()
      // getLicenses(
      //   { url: "/licenses/company", method: "get" },
      //   { CompanyId: companyId, OnlyActiveLicenses: true, TypeId: 15 }
      // ).catch(err => {
      //   showLicenseActModal();
      // });
    }
  }, [company, licenses, licenseId]);

  // find if there are licenses on the server
  useEffect(() => {
    if (licenses.data) {
      updateLicense();
    }
  }, [licenses, updateLicense]);

  // Refresh license every 24 hours
  useInterval(() => {
    if (company?.Id && licenses.data && !licenses.isLoading) {
      licenses.refetch();
    }
    updateLicense();
  }, UPDATE_INTERVAL);

  // act -> sucess modals effect
  useEffect(() => {
    if(!oldLicenseId && newLicense.data?.MetaData && licenseId !== newLicense.data.MetaData) {
      setOldLicenseId(licenseId as string);
      setStorageLicenseId(newLicense.data.MetaData);
    }

    if(oldLicenseId && licenseId && oldLicenseId !== licenseId) {
      showLicenseSucessModal();
    }

    // Delete old license
    if(newLicense.data && oldLicenseId && oldLicenseId !== licenseId) {
      const oldLicense = licenses.data?.find((license: BokaMera.Licence) => license.MetaData === oldLicenseId);
      if(oldLicense?.Id) {
        cancelLicense.mutate({ Id:  oldLicense.Id });
      }
    }
  }, [oldLicenseId, licenseId, newLicense, licenses]);

  const handleCreateLicense = () => {
    newLicense.mutate({
      CompanyId: company?.Id as string,
      MetaData: customCode,
      TypeId: "15",
      BillingInformation: billingInformation.data,
    });
  };

  const [showLicenseActModal, hideLicenseActModal] = useModal(() => (
    <Dialog //
      // @ts-ignore
      style={{ paddingBottom: "3", minWidth: theme?.sizes["3/4"] }}
      onClose={() => {}}
      
    >
      <Box sx={{ textAlign: "center" }}>
        <Text as="h3" sx={{ mb: "4" }}>
          <Trans id="license.problemWithYourLicense.title" />
        </Text>
        <Text>
          <Trans id="license.problemWithYourLicense.description" />
        </Text>
      </Box>
      <Flex sx={{ justifyContent: "center", mb: "4" }}>
        <Trans id="license.licenseId" /> {licenseId}
      </Flex>

      <Flex sx={{ justifyContent: "center" }}>
        <Flex>
          <Button
            variant="outline"
            onClick={() => window.location.reload()}
            style={{ minWidth: "240px", ml: "2", mr: "2" }}
          >
            <Trans id="license.tryAgain" />
          </Button>
          <Button
            disabled={newLicense.isLoading}
            style={{ minWidth: "240px", ml: "2", mr: "2" }}
            onClick={() => {
              newLicense.mutate(
                {
                  CompanyId: company?.Id as string,
                  MetaData: nanoidShort() as string,
                  TypeId: "15",
                  BillingInformation: billingInformation.data,
                }
              )
            }}
          >
            <Trans id="license.addNewLicense" />
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "240px", ml: "2", mr: "2" }}
            onClick={() => {
              deleteFromStorage("token");
              deleteFromStorage("refreshToken");
              deleteFromStorage("companyId");
              deleteFromStorage("appSettings");
              if (history.location.pathname !== "/login") {
                history.push("/login");
              }
              hideLicenseActModal();
              window.location.reload();
            }}
          >
            <Trans id="UserButtons.disconnectDisplay" />
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  ), [licenseId]);

  const [showLicenseSucessModal, hideLicenseSucessModal] = useModal(() => {
    return <Dialog //
      // @ts-ignore
      style={{ paddingBottom: "3", minWidth: theme?.sizes["3/4"] }}
      onClose={() => {}}
    >
      <Box sx={{ textAlign: "center", mb: "3" }}>
        <Text as="h3" >
          <Trans id="license.problemWithYourLicense.sucess.title" />
        </Text>
      </Box>

      <Flex sx={{ justifyContent: "center" }}>
        <Trans id="license.newLicenseId" /> {licenseId}
      </Flex>

      <Box sx={{ textAlign: "center", mt: "4" }}>
        <Button
          style={{ minWidth: "240px", ml: "2", mr: "2" }}
          onClick={() => {
            updateLicense();
            hideLicenseSucessModal();
            setOldLicenseId(licenseId as string);
            window.location.reload();
          }}
        >
          <Trans id="license.problemWithYourLicense.continueUsingTheApp" />
        </Button>
      </Box>
    </Dialog>
  }, [licenseId]);

  return (
    <LicenceContext.Provider
      value={{
        licenceId: licenseId || "",
        license: newLicense,
        licenses: licenses,
        licenseTypes: licenseTypes,
        billingInformation,
        handleCreateLicense,
        customCode,
      }}
    >
      {children}
    </LicenceContext.Provider>
  );
};

export default LicenceProvider;
