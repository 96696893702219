/** @jsx jsx */
import { jsx, useThemeUI, Flex, Text } from "theme-ui";
import React, { useState, useEffect } from "react";
import { format, parseISO } from "utils/datetime";
import Button from "components/Button/Button";
import { Trans } from "@lingui/react";
import Loading from "components/Loading/Loading";
import { getBWColorFromContext } from "theme";
import { UseMutationResult } from "react-query";

interface BookingProps {
  bookingMutation: UseMutationResult<BokaMera.Booking, any, any, unknown>;
  booking: BokaMera.Booking;
  onClick: (booking: BokaMera.Booking) => void;
  current?: boolean;
}

const Booking: React.FC<BookingProps> = ({
  bookingMutation,
  booking,
  onClick,
  current = false,
}) => {
  const context = useThemeUI();
  const { theme } = context;
  
  const loading =  (bookingMutation.isLoading || bookingMutation.data) &&
    (
      bookingMutation.variables && bookingMutation.variables[1]?.Id == booking?.Id ||
      bookingMutation.variables && bookingMutation.variables[0]?.params?.Id == booking?.Id
    );

  return (
    <Flex
      sx={{
        background: `${theme.colors?.background}`,
        flexDirection: "row",
        padding: "20px 30px",
        border: `1px solid ${theme.colors?.primary}`,
        borderRadius: "6px",
        margin: "20px 0px",
        minWidth: "40vw",
        alignItems: "center",
        paddingTop: 0,
        pointerEvents: loading ? "none" : undefined,
      }}
    >
      <Flex sx={{ flexDirection: "column", width: "100%" }}>
        <Text
          sx={{
            marginTop: "10px",
            fontSize: "0.75rem",
            color: "#8d8d8d",
            textTransform: "uppercase",
          }}
        >
          {current ? (
            <Trans id="booking.current" />
          ) : (
            <Trans id="booking.next" />
          )}
        </Text>
        <Text
          sx={{
            paddingRight: 10,
          }}
        >
          {format(parseISO(booking.From), "PPP")}
        </Text>
        <Text
          sx={{
            display: "flex",
            fontSize: theme?.fontSizes ? theme?.fontSizes[2] : undefined,
            fontWeight: "bolder",
            whiteSpace: "nowrap",
          }}
        >
          {format(parseISO(booking.From), "EEEE")}{" "}
          {format(parseISO(booking.From), "p")}-
          {format(parseISO(booking.To), "p")}
        </Text>
        <Button
          disabled={loading}
          style={{
            maxHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // @ts-ignore
            marginTop: theme?.sizes["2"],
          }}
          onClick={() => {
            onClick(booking);
          }}
        >
          <Text
            sx={{
              whiteSpace: "nowrap",
              "@media screen and (min-width: 1025px)": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            {loading ? (
              <Loading
                color={getBWColorFromContext(theme?.colors?.primary)}
                style={{ marginTop: 0 }}
                size={20}
              />
            ) : current ? (
              <Trans id="ongoingBooking.endWashing" />
            ) : (
              <Trans id="cancelBooking" />
            )}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Booking;
