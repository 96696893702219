/** @jsx jsx */
import React, { useState, useEffect, useCallback } from "react";
import { jsx, Text, Flex, useThemeUI, Card, Button, Box } from "theme-ui";
import styled from "@emotion/styled";
import { Trans } from "@lingui/react";
import { getBWColorFromContext, addOpacityToColor } from "../../theme";
import { useLicence } from "providers/LicenseProvider/LicenseProvider";
import Loading from "components/Loading/Loading";
import AnimatedCheck from "components/AnimatedCheck/AnimatedCheck";

export const ANIMATION_DURATION = 1500;
export const WAIT_DURATION = 2000;
const CORRECT_LICENSE_TYPE_ID = 15;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Licence = () => {
  const [licenceId, setLicenceId] = useState("");
  const [, setSelectedLicenceId] = useState(0);
  const context = useThemeUI();
  const { theme } = context;

  const {
    licenses,
    license,
    handleCreateLicense,
    licenseTypes,
    customCode,
  } = useLicence();

  return (
    <StyledFlex
      data-testid="license"
      sx={{
        bg: theme?.colors?.background,
        color: theme?.colors?.primary,
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          mb: theme.space ? theme?.space[4] : undefined,
        }}
      >
        <Text as="h1">
          <Trans id="license.h1" />
        </Text>
        <Text>
          <Trans id="license.upgradeAccount" />
        </Text>
        <Text>
          <Trans id="license.tryLicense" />
        </Text>
      </Flex>

      {licenses.isLoading ? <Loading color={theme?.colors?.primary} /> : null}

      {licenseTypes?.data && !license?.isLoading && !license?.data ? (
        <Flex
          sx={{
            alignItems: "normal",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {!licenceId
            ? licenseTypes.data?.Results.filter(
                (l: any) => l.Id === CORRECT_LICENSE_TYPE_ID
              ).map((licenseType: BokaMera.LicenseType) => (
                <LicenceCard
                  key={licenseType.Id}
                  onClick={(ev: any) => setSelectedLicenceId(licenseType.Id)}
                  title={licenseType.Name}
                  description={licenseType.Description}
                  price={licenseType.Prices[0]}
                  noticeText="test"
                >
                  <Button onClick={handleCreateLicense}>
                    <Trans id="license.addLicense" />
                  </Button>
                </LicenceCard>
              ))
            : null}
        </Flex>
      ) : null}
      <Flex as="h2">
        {!licenceId && licenses?.isLoading ? (
          <Text>Activating license...</Text>
        ) : license?.data ? (
          <AnimatedCheck
            color={theme.colors?.primary}
            duration={ANIMATION_DURATION}
          />
        ) : null}
      </Flex>
      <Flex as="h3" sx={{ margin: 20 }}>
        <Text
          sx={{
            animation: !licenceId
              ? ""
              : `fade-in ${ANIMATION_DURATION}ms ease-out`,
          }}
        >
          {!licenceId && licenses && licenses.data?.length === 0 ? (
            <Text>{customCode}</Text>
          ) : licenceId ? (
            <Text>
              <Trans id="license.success" />
            </Text>
          ) : license?.error && typeof license?.error === 'string' ? (
            <React.Fragment>
              <Text>{license?.error}</Text>
              {license?.error.includes("Billing Information") ? (
                <Text variant="info">
                  <Trans id="license.billingInformationMissingErrorInfo" />
                </Text>
              ) : null}
            </React.Fragment>
          ) : null}
        </Text>
      </Flex>
    </StyledFlex>
  );
};

interface LicenceCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  price: BokaMera.Price;
  noticeText: string;
  description: string;
}
const LicenceCard: React.FunctionComponent<LicenceCardProps> = ({
  title,
  description,
  price,
  noticeText,
  children,
  ...restProps
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        // @ts-ignore
        maxWidth: theme.sizes
          ? //
            // @ts-ignore
            `calc(${theme?.sizes["1/2"]} - ${theme?.space[4]})`
          : undefined,
        // @ts-ignore
        // border: `1px solid ${theme.colors?.gray[1]}`,
        // @ts-ignore
        margin: theme?.space[2],
        width: "100%",
        borderRadius: theme?.space ? theme?.space[2] : undefined,
        padding: theme?.space ? theme?.space[4] : undefined,
        background: "#ffffff",
        boxShadow: `0px 4px 4px ${addOpacityToColor(
          getBWColorFromContext(theme?.colors?.background),
          25
        )}`,
        transition: "300ms ease-in-out",
        ":hover": {
          transform: "scale(1.02)",
        },
        ":hover #active": {
          background: theme?.colors?.primary,
        },

        ...restProps.sx,
      }}
      {...restProps}
    >
      <Flex
        id="active"
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "10px",
          background: "transparent",
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
          transition: "300ms ease-in-out",
        }}
      ></Flex>
      <Flex
        sx={{
          minHeight: "60px",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Text as="h3">{title}</Text>
      </Flex>
      <Box
        sx={{
          borderTop: `1px solid ${addOpacityToColor(
            theme?.colors?.primary,
            20
          )}`,
          margin: "20px 0px",
        }}
      />

      <Text as="p">
        {"- "}
        {description}
      </Text>

      <Text
        as="h2"
        sx={{
          marginTop: "auto",
          display: "flex",
          alignSelf: "center",
          marginBottom: "16px",
        }}
      >
        {price.Price}
        {price.Country.CurrencyInfo.CurrencySign}/
        <Trans id="common.month" />
      </Text>
      {children}
    </Card>
  );
};

export default Licence;
