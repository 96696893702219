/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import { getBWColorFromContext, addOpacityToColor } from "theme";
import styled from "@emotion/styled";

export const DIVIDER_OPACITY = 20;

export const Modal = styled(Flex)`
  width: 600px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Comment = styled(Flex)`
  margin: 0em 2em;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const ModalButtons = styled(Flex)`
  margin: 1em 2em;
  max-width: 600px;
  width: 100%;
  justify-content: space-between;
`;

export const MessageHolder = styled(Flex)`
  position: absolute;
  z-index: 100;
  /* top: ${(props: any) =>
    props.theme?.space ? props.theme?.space[2] : "15px"}; */
  width: 100%;
  justify-content: center;
  padding: 40px;
`;

export const OngoingEventStyled = styled(Flex)`
  flex-direction: column;
  position: relative;
  text-decoration: "";
  background: white;
  width: 100%;
  margin-top: 10px;
  border-radius: 15px;
  padding: ${(props: any) => {
    return props.theme.sizes["5"];
  }};
  color: ${(props: any) => {
    return props.theme?.colors?.danger ? props.theme?.colors.danger : undefined;
  }};
`;

export const EventFromTo = styled(Flex)`
  white-space: nowrap;
  margin-right: ${(props: any) =>
    props.theme.space ? props.theme.space[3] : 0};
  min-width: 160px;
`;

export const EventTimes = styled(Flex)`
  align-items: center;
`;

export const EventTimesText = styled(Text)`
  text-transform: uppercase;
  color: ${(props: any) =>
    props.theme?.colors?.primary ? props.theme?.colors?.primary : "#000"};
  font-weight: bolder;
`;

export const DirectBookingStyled = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  opacity: ${(props: any) => (props.appSettings?.ShowDirectBooking ? "" : 0)};
  pointer-events: ${(props: any) =>
    props.appSettings?.ShowDirectBooking ? "all" : "none"};

  Button {
    margin: 5px;
    padding: 15px;
    border-radius: 15px;
  }
`;

export const DirectBookingText = styled(Text)`
  text-transform: uppercase;
  margin: 5px;
`;

export const BookingQR = styled(Flex)`
  width: 50%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  opacity: ${(props: any) => (props.appSettings?.ShowBookingWithQR ? "" : 0)};
  pointer-events: ${(props: any) =>
    props.appSettings?.ShowBookingWithQR ? "all" : "none"};
`;

export const BookingQRText = styled(Text)`
  padding: 20px 0px 20px 20px;
  text-transform: uppercase;
  text-align: right;
`;

export const QRCodeWrapper = styled(Flex)`
  padding: 10px;
  background: #fff;
  border-radius: 6px;
`;

export const SettingsIconHolder = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50%;
  align-self: flex-end;
`;

export const Booking = styled(Flex)`
  flex-direction: column;
  position: relative;
`;

export const BookingMoreInfo = styled(Flex)`
  flex-direction: row;
  width: 100%;
`;

export const Divider = styled.div`
  border-top: ${(props: any) =>
    `1px solid ${addOpacityToColor(
      getBWColorFromContext(props.theme?.colors?.primary),
      DIVIDER_OPACITY
    )}`};
  margin: 20px 0px;
`;

export const BookingFromTo = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-width: 115px;
  margin-right: ${(props: any) =>
    props.theme.space ? props.theme.space[2] : 0};
`;

export const SortByDays = styled(Text)`
  padding: 20px 0px 10px 0px;
`;

export const Day = styled(Text)`
  font-size: ${(props: any) =>
    props.theme.fontSizes ? props.theme.fontSizes[4] : undefined};
  text-transform: uppercase;
  font-weight: bolder;
`;

export const From = styled(Text)`
  font-size: ${(props: any) =>
    props.theme.fontSizes ? props.theme.fontSizes[2] : undefined};
`;
export const To = styled(Text)`
  font-size: ${(props: any) =>
    props.theme.fontSizes ? props.theme.fontSizes[1] : undefined};
`;

export const PredefinedCommentsSection = styled(Flex)``;

export const PredefinedComment = styled(Flex)`
  background: ${(props: any) => props.theme?.colors?.primary};
  color: ${(props: any) => getBWColorFromContext(props.theme?.colors?.primary)};
  font-size: ${(props: any) =>
    props.theme.fontSizes ? props.theme.fontSizes[0] : undefined};
  margin-left: ${(props: any) => props.theme?.sizes[2]};
  margin-top: ${(props: any) => props.theme?.sizes[2]};
  margin-right: ${(props: any) =>
    props.index === props.quickButtons.length - 1 ? props.theme?.sizes[2] : 0};
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
`;

export const BookedComment = styled(To)``;
export const Customer = styled(From)``;

export const NextAvailableTime = styled.span``;