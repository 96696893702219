/** @jsx jsx */
import React from "react";
import { Slide } from "react-awesome-reveal";
import { jsx, useThemeUI, Box } from "theme-ui";

interface DialogProps {
  children: any;
  onClose: () => void;
  style?: object;
}

const Dialog: React.FC<DialogProps> = ({ onClose, children, style }) => {
  const context = useThemeUI();
  const { theme } = context;
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        zIndex: 3003,
        margin: "10%",
      }}
    >
      <Box
        key="mask"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          zIndex: "1000!important",
          webkitBackdropFilter: "blur(5px)",
          backdropFilter: "blur(5px)",
        }}
        // @ts-ignore
        onClick={onClose}
      />
      <Slide direction="up" duration={200} triggerOnce={true}>
        <Box
          key="container"
          sx={{
            backgroundColor: `${theme?.colors?.background}`,
            color: `${theme?.colors?.text}`,
            borderRadius: "8px",
            padding: "3",
            paddingBottom: 0,
            position: "relative",
            zIndex: 1001,
            minWidth: "320px",
            ...style,
          }}
        >
          {children}
        </Box>
      </Slide>
    </Box>
  );
};

export default Dialog;
