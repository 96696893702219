/** @jsx jsx */
import { jsx, Textarea as ThemedTextArea, useThemeUI } from "theme-ui";
import React, { forwardRef } from "react";
import { getBWColorFromContext } from "../../theme";
// import styles from "./InputTextArea.module.scss";
// import classnames from "classnames";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const context = useThemeUI();
  const { theme } = context;
  return (
    <ThemedTextArea
      sx={{
        padding: "15px",
        resize: "none",
        border: `1px solid ${getBWColorFromContext(theme.colors?.background)}`,
        // @ts-ignore
        fontFamily: `${theme?.fonts?.body}`,
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      }}
      rows={8}
      {...props}
      ref={ref}
    />
  );
});

export default TextArea;
