/** @jsx jsx */
import {
  jsx,
  useThemeUI,
  ThemeUICSSProperties,
  Button as StyledButton,
  Box,
} from "theme-ui";
import React from "react";
import styled from "@emotion/styled";
import Loading from "components/Loading/Loading";

interface ButtonProps {
  children: any;
  loading?: boolean;
  htmlType?: "button" | "submit" | "reset" | undefined;
  style?: ThemeUICSSProperties;
  variant?: string;
  "data-testid"?: string;
}

const CustomButton = styled(StyledButton)`
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  @keyframes whileTap {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(0.98);
    }
    30% {
      transform: scale(0.96);
    }
    40% {
      transform: scale(0.94);
    }
    50% {
      transform: scale(0.94);
    }
    60% {
      transform: scale(0.94);
    }
    70% {
      transform: scale(0.94);
    }
    80% {
      transform: scale(0.96);
    }
    90% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  onClick,
  onTouchEnd,
  loading = false,
  style,
  variant = "simple",
  "data-testid": dataTestId,
  disabled,
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <CustomButton
      // @ts-ignore
      onClick={onClick}
      // @ts-ignore
      onTouchEnd={onTouchEnd}
      variant={variant}
      disabled={disabled}
      sx={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        transform: "scale(1)",
        animation: "none",
        img: {
          maxWidth: "32px",
          maxHeight: "32px",
        },
        ":active": {
          animation: "whileTap 300ms both",
        },
        ...style,
      }}
      data-testid={dataTestId}
    >
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loading size={30} color={`${theme?.colors?.background}`} />
        </Box>
      )}
      {!loading && children}
    </CustomButton>
  );
};

export default Button;
