import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  I18nProvider as LinguiProvider,
  I18n as LinguiContextConsumer,
} from "@lingui/react";
import { I18n } from "@lingui/core";
import catalogSv from "locales/sv/messages.js";
import catalogEn from "locales/en/messages.js";
import catalogNo from "locales/no/messages.js";
import catalogFi from "locales/fi/messages.js";

export const LANGAUGE_STORAGE_KEY = "LANGAUGE_STORAGE_KEY";
interface I18nProviderProps {
  children: React.ReactNode;
  defaultLanguage?: string;
}

const catalogs = {
  sv: catalogSv,
  en: catalogEn,
  no: catalogNo,
  fi: catalogFi,
};

export interface I18nContextProps extends I18n {
  setLanguage: (language: string) => void;
  language: string;
}

export const I18nContext = React.createContext<I18nContextProps | undefined>(
  undefined
);

export const useI18n = () => useContext(I18nContext);

const I18nProvider: React.FC<I18nProviderProps> = ({
  children,
  defaultLanguage = "sv",
}) => {
  const [language, setLanguage] = useState(
    (window.localStorage?.getItem(LANGAUGE_STORAGE_KEY) as string) ||
      defaultLanguage
  );

  const handleSetLanguage = useCallback((language: string) => {
    setLanguage(language);
  }, []);

  useEffect(() => {
    if (!window.localStorage?.getItem(LANGAUGE_STORAGE_KEY)) {
      window.localStorage?.setItem(LANGAUGE_STORAGE_KEY, defaultLanguage);
    } else {
      window.localStorage?.setItem(LANGAUGE_STORAGE_KEY, language);
    }
  }, [defaultLanguage, language]);

  return (
    <LinguiProvider language={language} catalogs={catalogs}>
      <LinguiContextConsumer>
        {({ i18n }: { i18n: I18n }) => (
          <I18nContext.Provider
            // @ts-ignore
            value={{ ...i18n, language, setLanguage: handleSetLanguage }}
          >
            {children}
          </I18nContext.Provider>
        )}
      </LinguiContextConsumer>
    </LinguiProvider>
  );
};

export default I18nProvider;
