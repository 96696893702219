/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import React from "react";
import { format } from "utils/datetime";
import { Slide } from "react-awesome-reveal";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useTime } from "providers/TimeProvider/TimeProvider";

interface TimeProps {
  style?: object;
}

const Time: React.FC<TimeProps> = (props) => {
  const { style } = props;
  const { currentDate, currentTime, currentDay } = useTime();
  let today = format(currentDate, "PP");

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: "bold",
        mr: "3"
      }}
    >
      <Slide duration={300} direction="left" triggerOnce={true} style={style}>
        <Flex sx={{ zIndex: 999 }}>
          <Text sx={{ width: "100%", fontSize: 55, lineHeight: 1 }}>
            {currentTime}{" "}
          </Text>
          <LanguageSwitcher />
        </Flex>
        <Text>
          {currentDay} {today}
        </Text>
      </Slide>
    </Flex>
  );
};

export default Time;
