/** @jsx jsx */
import { jsx, Flex, useThemeUI } from "theme-ui";
import { differenceInMinutes, isAfter, addMinutes, endOfDay } from "date-fns";
import { format } from 'utils/datetime'
import { Trans } from "@lingui/macro";
import {
  DirectBookingStyled,
  EventTimes,
  EventTimesText,
  NextAvailableTime,
  DirectBookingText,
} from "./styled";
import BookingButtons from "./BookingButtons";
import { AppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import { minBy } from 'lodash';

interface Props {
  appSettings: AppSettings;
  localNextTime: {
    From: string;
    To: string;
    Duration: number;
  };
  currentDate: Date;
  setModalWithProps: (props: any) => void;
  ongoingEvent?: any;
}

const DirectBooking: React.FC<Props> = (props) => {
  const { theme } = useThemeUI();

  const minButtonDuration = minBy(props.appSettings.Buttons, (button) => button.value)?.value || 30;

  const diffNextEvt = differenceInMinutes(new Date(props.localNextTime.From), props.currentDate);
  const diffEndOfDay = differenceInMinutes(endOfDay(props.currentDate), props.currentDate);
  const isMinDurationInRangeEndOfDay = diffEndOfDay > 0 && diffEndOfDay < minButtonDuration;
  const isMinDurationInRangeNextEvt = diffNextEvt > 0 && diffNextEvt < minButtonDuration;

  return (
    <DirectBookingStyled appSettings={props.appSettings}>
      <EventTimes>
        <EventTimesText theme={theme}>
          {props.ongoingEvent && props.localNextTime ? (
            <NextAvailableTime>
              <Trans id="conference.nextAvailableTime" /> :{" "}
              {format(new Date(props.localNextTime.From), "p")} -{" "}
              {format(new Date(props.localNextTime.To), "p")}
            </NextAvailableTime>
          ) : null}
        </EventTimesText>
      </EventTimes>
      {(isMinDurationInRangeEndOfDay || isMinDurationInRangeNextEvt) &&
      props.appSettings?.Buttons.length > 0 ? (
        <DirectBookingText>
          {isAfter(
            new Date(props.localNextTime.From),
            new Date(addMinutes(props.currentDate, 1))
          ) ? ( // ovde
            <Trans id="conference.bookNextAvailableTime" />
          ) : (
            <Trans id="conference.directBooking" />
          )}
        </DirectBookingText>
      ) : null}
      <Flex
        sx={{
          width: "100%",
          justifyContent: "center",
          overflow: "hidden",
          flexWrap:
            props.appSettings?.Buttons.length + (props.localNextTime ? 1 : 0) >=
            3
              ? "wrap"
              : "initial",
        }}
      >
        <BookingButtons
          buttons={props.appSettings?.Buttons}
          currentDate={props.currentDate} // @ts-ignore
          localNextTime={props.localNextTime}
          onClick={(buttonValue, buttonTime) => {
            props.setModalWithProps({
              shown: true,
              // @ts-ignore
              values: {
                value: buttonValue,
                time: buttonTime,
              },
            });
          }}
          direct={
            !isAfter(
              new Date(props.localNextTime.From),
              new Date(addMinutes(props.currentDate, 1))
            )
          }
        />
        {props.localNextTime ? (
          <BookingButtons
            buttons={[
              {
                id: "now-till-end",
                label: "Now until end",
                value: differenceInMinutes(
                  // @ts-ignore
                  new Date(props.localNextTime.To), // @ts-ignore
                  new Date(props.localNextTime.From)
                ),
              },
            ]}
            direct={
              !isAfter(
                new Date(props.localNextTime.From),
                new Date(addMinutes(props.currentDate, 1))
              )
            } // @ts-ignore
            localNextTime={props.localNextTime}
            currentDate={props.currentDate}
            untilEndOfDay={
              isMinDurationInRangeEndOfDay || isMinDurationInRangeNextEvt
            }
            onClick={() => {
              let time = {
                // @ts-ignore
                From: getRoundedDate(
                  5, // @ts-ignore
                  new Date(props.localNextTime?.From)
                ),
                To: props.localNextTime?.To,
              };
              // @ts-ignore
              time.Duration = differenceInMinutes(
                // @ts-ignore
                new Date(time.To), // @ts-ignore
                new Date(time.From)
              );
              props.setModalWithProps({
                shown: true,
                values: {
                  // @ts-ignore
                  value: time.Duration,
                  // @ts-ignore
                  time,
                },
              });
            }}
          />
        ) : null}
      </Flex>
    </DirectBookingStyled>
  );
};

export default DirectBooking;
