export const getErrorResponse = (err: any) => {
  const errorMessage = err?.response?.data?.ResponseStatus?.Message
    ? err?.response?.data?.ResponseStatus?.Message
    : err.message;
  return errorMessage;
};


export async function clearSession(clientId: string, refreshToken: string) {
  var myHeaders = new Headers();
  var urlencoded = new URLSearchParams();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  urlencoded.append('refresh_token', refreshToken);
  urlencoded.append("client_id", clientId);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  return fetch(
    `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
    requestOptions
  );
}