/** @jsx jsx */
import { jsx, Text, Flex, useThemeUI, Textarea, Message, Box } from "theme-ui";
import React, { useEffect, useState, useCallback } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import styled from "@emotion/styled";
import Dialog from "components/Dialog/Dialog";
import { I18n } from "@lingui/core";
import { withI18n } from "@lingui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedCheck from "components/AnimatedCheck/AnimatedCheck";
import { format, isAfterPredicate, getRoundedDate } from "utils/datetime";
import { toDateISOString, formatDistanceStrict } from "utils/datetime";
import Logo from "svg/logo.png";
import { useToasts } from "react-toast-notifications";
import { AxiosError, AxiosRequestConfig } from "axios";
import { useQuery, useMutation } from "react-query";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import * as api from "api/api";

import {
  isWithinInterval,
  isToday,
  isTomorrow,
  addDays,
  toDate,
  startOfDay,
  endOfDay,
  differenceInMinutes,
  isAfter,
  addMinutes,
  isBefore,
  formatISO,
} from "date-fns";
import { Trans } from "@lingui/macro";
import Scrollbar from "react-scrollbars-custom";
import { ReactComponent as SettingsIcon } from "svg/appSettings.svg";
import QRCode from "react-qr-code";
import { useCompany } from "providers/CompanyProvider/CompanyProvider";
import { useTime, UPDATE_INTERVAL } from "providers/TimeProvider/TimeProvider";
import Content, { ContentLeft, ContentRight } from "components/Content/Content";
import Time from "components/Time/Time";
import Settings, { IMAGE_STORAGE_KEY } from "components/SettingsMenu/SettingsMenu";
import Button from "components/Button/Button";
import {
  addOpacityToColor,
  getBWColorFromContext,
  transformColorToHex,
} from "../../theme";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import { groupBy } from "lodash";
import UserButtons from "UserButtons/UserButtons";
import Loading from "components/Loading/Loading";
import { useModal } from "react-modal-hook";
import BookingButtons from "./BookingButtons";
import { getErrorResponse } from "utils/common";
import { calculateNextFreeTime } from "./calculate";
import {
  From,
  To,
  Modal,
  Booking,
  BookingFromTo,
  BookingMoreInfo,
  BookingQR,
  BookingQRText,
  Comment,
  Day,
  DirectBookingStyled,
  DirectBookingText,
  EventTimes,
  EventTimesText,
  MessageHolder,
  ModalButtons,
  PredefinedComment,
  PredefinedCommentsSection,
  QRCodeWrapper,
  SettingsIconHolder,
  SortByDays,
  Divider,
  BookedComment,
  Customer,
} from "./styled";
import LiveBooking from "./LiveBooking";
import { Live } from "./Live";
import OngoingEvent from "./OngoingEvent";
import DirectBooking from "./DirectBooking";

const MODAL_DURATION = 2000;
const EQUAL_BUTTONS_WIDTH = true;

enum LoadingStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

interface modalProps {
  shown: boolean;
  values:
    | {
        value: number;
        /**
         * Time for booking
         */
        time: { From: string; To: string };
        comment: string;
      }
    | undefined;
}

const EventsGroupedByDays = styled.div``;

const getQRCodeLink = (company: any) => {
  let bookSuffix = "/book";

  // Notify users that they should host bokamera embeded on homepageurl/book
  let sitepath = company?.SitePath;
  let domain = ".bokamera.se";

  return `${sitepath}${domain}${bookSuffix}`.toLowerCase();
};

interface ConferenceProps {
  i18n: I18n;
}

const Conference: React.FC<ConferenceProps> = ({ i18n }) => {
  const { currentDate } = useTime();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const { authorised, companyId } = useAuthentication();

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  // TODO: handle this by finding a way how to reset createException when createException is finished successfully
  const [timeExceptionState, setTimeExceptionState] = useState<LoadingStatus>();
  const [modalWithProps, setModalWithProps] = useState<modalProps>({
    shown: false,
    values: undefined,
  });
  const context = useThemeUI();
  const company = useCompany();
  const { theme } = context;
  const appSettings = useAppSettings();

  const resourceId = appSettings?.Resource?.Id;

  let backgroundImage =
    localStorage.getItem(IMAGE_STORAGE_KEY) || appSettings?.ConferenceBackgroundUrl || "https://picsum.photos/800/400";

  const cacheImages = async (srcArray: string[]) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        // @ts-ignore
        img.onload = resolve();
        // @ts-ignore
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
  };

  const LEFT_CONTENT_OPACITY = appSettings?.LeftContentOpacity || 80;
  const RIGHT_CONTENT_OPACITY = appSettings?.RightContentOpacity || 90;

  const services = useQuery(
    ["services", companyId],
    ({ queryKey }) => api.getServices({ params: { Active: true, CompanyId: queryKey[1] } }),
    {
      enabled: authorised,
    }
  );

  const bookings = useQuery(
    ["bookings", companyId, resourceId],
    ({ queryKey }) => {
      if (queryKey[1] && queryKey[2]) {
        const date = toDate(currentDate as Date);
        const bookingStart = startOfDay(date);
        const bookingEnd = endOfDay(addDays(date, 1));

        return api.getBookings({
          params: {
            CompanyBookings: true,
            IncludeCustomFieldValues: true,
            IncludeBookedResourceTypes: true,
            IncludeServiceInformation: true,
            IncludeCustomerInformation: true,
            IncludeStatusInformation: true,
            BookingResourceRelationResourceIds: queryKey[2],

            IncludeCustomFields: true,
            CompanyId: queryKey[1],
            BookingStart: bookingStart,
            BookingEnd: bookingEnd,

            StatusIds: "1,3",
          },
        });
      }
    },
    {
      enabled: authorised && !!appSettings?.Resource,
      refetchInterval: UPDATE_INTERVAL,
    }
  );

  const timeExceptions = useQuery(
    ["timeExceptions", companyId, resourceId],
    ({ queryKey }) => {
      if (queryKey[2]) {
        const date = toDate(currentDate as Date);
        const bookingStart = startOfDay(date);
        const bookingEnd = endOfDay(addDays(date, 1));

        return api.getTimeExceptions({
          params: {
            CompanyId: queryKey[1],
            TimeExceptionStart: bookingStart,
            TimeExceptionEnd: bookingEnd,
            ResourceIds: queryKey[2],
          },
        });
      }
    },
    {
      enabled: authorised && !!appSettings?.Resource,
      refetchInterval: UPDATE_INTERVAL,
    }
  );

  const timeException = useMutation(
    "timeExceptions",
    (data: any) => api.createTimeException({}, data),
    {
      onSuccess: () => {
        setTimeExceptionState(LoadingStatus.SUCCESS);

        bookings.refetch();
        timeExceptions.refetch();
      },
      onError: (err) => {
        setTimeExceptionState(LoadingStatus.ERROR);
        // @ts-ignore
        setModalWithProps({ shown: false, values: undefined });
        addToast(getErrorResponse(err), {
          appearance: "error",
          autoDismiss: true,
        });
      },
    }
  );

  const unbookException = useMutation<any, any, any, any>(
    "unbookException",
    ({ config, data }: { config: AxiosRequestConfig; data: any }) =>
      api.unbookException(config, data),
    {
      onSuccess: () => {
        bookings.refetch();
        timeExceptions.refetch();
      },
    }
  );

  // Effects
  useEffect(() => {
    cacheImages([backgroundImage]);
  }, [backgroundImage]);

  useEffect(() => {
    if (unbookException.isLoading) {
      setTimeExceptionState(LoadingStatus.LOADING);
    }

    if (unbookException.data && unbookException.data.toString() !== "False") {
      setTimeExceptionState(LoadingStatus.SUCCESS);
      setTimeout(() => {
        hideModal();
        setLoading(false);
        setTimeExceptionState(LoadingStatus.IDLE);
      }, MODAL_DURATION);
    } else if (
      unbookException.data &&
      unbookException.data.toString() === "False"
    ) {
      setTimeExceptionState(LoadingStatus.ERROR);
      setTimeout(() => {
        hideModal();
        setLoading(false);
        setTimeExceptionState(LoadingStatus.IDLE);
      }, MODAL_DURATION);
    }
  }, [unbookException.data, unbookException.isLoading]);

  useEffect(() => {
    if (unbookException.isLoading) {
      setTimeExceptionState(LoadingStatus.LOADING);
    }

    if (timeException.data && timeException.data.toString() !== "False") {
      setModalWithProps({ shown: false, values: undefined });
      setTimeExceptionState(LoadingStatus.IDLE);
    } else if (
      timeException.data &&
      timeException.data.toString() === "False"
    ) {
    }
  }, [timeException.data, timeException.isLoading]);

  timeExceptions.data?.Results?.forEach((timeException) => {
    if (
      isWithinInterval(currentDate, {
        start: new Date(
          `${new Date(timeException.From).toISOString().split("T")[0]}T${
            timeException.FromTime
          }`
        ),
        end: new Date(
          `${new Date(timeException.From).toISOString().split("T")[0]}T${
            timeException.ToTime
          }`
        ),
      })
    ) {
      ongoingEvent = timeExceptions;
    }
  });

  const handleDirectBook = (
    val?: number,
    nextEvent?: { From: string; To: string }
  ) => {
    setTimeExceptionState(LoadingStatus.LOADING);
    if (val && nextEvent) {
      // TODO: if current, send +1 minute, for next send normal
      const fromArr = formatISO(new Date(nextEvent.From)).split("T");
      const toArr = formatISO(addMinutes(new Date(nextEvent.From), val)).split(
        "T"
      );

      timeException.mutate({
        From: fromArr[0],
        FromTime: fromArr[1].split(".")[0].split("+")[0],
        To: toArr[0],
        ToTime: toArr[1].split(".")[0].split("+")[0],
        ResourceIds: String(appSettings?.Resource?.Id),
        ReasonText: "Direct booking from Booking Display app",
        ReasonTextPublic: modalWithProps.values?.comment || "",
        BlockTime: true,
      });
    } else {
      setTimeExceptionState(LoadingStatus.ERROR);
    }
  };

  const onUnbookCb = useCallback(
    (booking: BokaMera.Booking, current = false): void => {
      if (current) {
        unbookException.mutate({
          config: {
            url: `/timeexceptions/${booking.Id}`,
            method: "put",
          },
          data: {
            CompanyId: companyId,
            Id: String(booking.Id),
            From: booking.From,
            // to avoid unbooking in the past
            To: isBefore(new Date(booking.From), currentDate)
              ? booking.From
              : toDateISOString(currentDate),
            AllowBookingOutsideSchedules: true,
          },
        });
      } else {
        unbookException.mutate({
          config: {
            url: `/timeexceptions/${booking.Id}`,
            method: "delete",
          },
          data: {
            params: {
              Id: String(booking.Id),
              SendEmailConfirmation: false,
              SendSmsConfirmation: false,
            },
          },
        });
      }
    },
    [unbookException, currentDate, companyId]
  );

  // other logic

  const bookingsAndDirectBookings = [
    ...(bookings.data?.Results || []),
    ...(timeExceptions.data?.Results?.map((timeException) => {
      const dateFrom = `${timeException.From.split("T")[0]}T${
        timeException.FromTime
      }`;
      const dateTo = `${timeException.To.split("T")[0]}T${
        timeException.ToTime
      }`;

      return {
        ...timeException,
        From: dateFrom,
        To: dateTo,
      };
    }) || []),
  ]
    .filter((event: any) => !event.IsRecurring)
    .sort(
      (
        a: BokaMera.Booking | BokaMera.TimeException,
        b: BokaMera.Booking | BokaMera.TimeException
      ) => {
        if (isAfter(new Date(a.From), new Date(b.From))) {
          return 1;
        } else {
          return -1;
        }
      }
    );

  const eventsGroupedByDays = groupBy(
    bookingsAndDirectBookings.filter((evt) =>
      isAfter(new Date(evt.From), startOfDay(currentDate))
    ),
    (event) => {
      if (isToday(new Date(event.From))) {
        return "today";
      } else if (isTomorrow(new Date(event.From))) {
        return "tomorrow";
      }
    }
  );

  let ongoingEvent: any;

  bookingsAndDirectBookings.forEach((booking) => {
    if (
      isWithinInterval(currentDate, {
        start: new Date(booking.From),
        end: new Date(booking.To),
      })
    ) {
      ongoingEvent = booking;
    }
  });

  let localNextTime = calculateNextFreeTime(bookingsAndDirectBookings);

  const requiredResourcesLoaded =
    appSettings?.Resource &&
    timeExceptions.isSuccess &&
    bookings.isSuccess &&
    services.isSuccess;

  const requiredResourcesError =
    timeExceptions.error || bookings.error || services.error;

  // @ts-ignore
  const buttonWidth = EQUAL_BUTTONS_WIDTH ? theme?.sizes["48"] : "auto";

  useEffect(() => {
    // if (unbookException.isLoading) {
    //   setLoading(true);
    // }

    // if (unbookException.isSuccess) {
    //   setLoading(false);
    // }

    if (unbookException.isError) {
      setLoading(false);
      addToast(unbookException.error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [unbookException]);

  // dependent subcomponents

  const [showModal, hideModal] = useModal(() => {
    return (
      <Dialog //
        // @ts-ignore
        style={{ paddingBottom: "3", minWidth: theme?.sizes["1/2"] }}
        onClose={hideModal}
      >
        <FontAwesomeIcon
          size="1x"
          icon={["fas", "times"]}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            marginRight: "1",
            marginTop: "1",
            cursor: "pointer",
            zIndex: 999,
          }}
          onClick={hideModal}
        />
        <React.Fragment>
          <Flex
            sx={{
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "3",
                  paddingBottom: "3",
                }}
              >
                <Trans id="conference.endCurrentEvent.text" />
              </Text>
            </Flex>
          </Flex>
        </React.Fragment>
        <Flex
          sx={{
            justifyContent:
              timeExceptionState === LoadingStatus.SUCCESS ||
              timeExceptionState === LoadingStatus.ERROR
                ? "center"
                : "space-between",
          }}
        >
          {timeExceptionState === LoadingStatus.SUCCESS ? (
            <Text variant="success" color={`${theme?.colors?.success}`}>
              <AnimatedCheck
                color={theme.colors?.primary}
                style={{ width: "66px", height: "66px" }}
                duration={MODAL_DURATION}
              />
            </Text>
          ) : timeExceptionState === LoadingStatus.ERROR ? (
            <Fade>
              <Flex sx={{ color: theme?.colors?.danger }}>
                <Trans id="universal.error" />
              </Flex>
            </Fade>
          ) : (
            <React.Fragment>
              <Button
                style={{ width: "150px" }}
                variant="outline"
                disabled={unbookException.isLoading}
                onClick={hideModal}
              >
                <Trans id="common.goBack" />
              </Button>

              <Button
                style={{ width: "150px" }}
                loading={loading}
                disabled={unbookException.isLoading}
                onClick={() => onUnbookCb(ongoingEvent)}
              >
                {loading ? (
                  <Loading size={30} />
                ) : (
                  <Trans id="conference.endCurrentEvent.confirm" />
                )}
              </Button>
            </React.Fragment>
          )}
        </Flex>
      </Dialog>
    );
  }, [loading, timeExceptionState, ongoingEvent, unbookException.isLoading]);

  return appSettings && appSettings.Buttons ? (
    <React.Fragment>
      {modalWithProps?.shown ? (
        <Dialog
          // @ts-ignore
          onClose={() => {
            setModalWithProps({ shown: false, values: undefined });
            setTimeExceptionState(LoadingStatus.IDLE);
          }}
        >
          <FontAwesomeIcon
            size="1x"
            icon={["fas", "times"]}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              margin: 20,
              cursor: "pointer",
            }}
            // @ts-ignore
            onClick={() => {
              setModalWithProps({ shown: false, values: undefined });
              setTimeExceptionState(LoadingStatus.IDLE);
            }}
          />
          <Modal>
            <Comment>
              <label htmlFor="messageBody" sx={{ fontSize: "1.5rem" }}>
                <Trans id="conference.commentTitle" />
              </label>
              <Textarea
                rows={4}
                sx={{ maxWidth: "600px" }}
                name="MessageBody"
                id="messageBody"
                placeholder={i18n._("conference.provideComment")}
                value={modalWithProps?.values?.comment || ""}
                onChange={(evt) =>
                  setModalWithProps({
                    shown: modalWithProps.shown,
                    // @ts-ignore
                    values: {
                      ...modalWithProps.values,
                      comment: evt.target.value,
                    },
                  })
                }
              />
            </Comment>

            <PredefinedCommentsSection>
              {appSettings.QuickButtons.map((button, index) => {
                return (
                  <PredefinedComment
                    onClick={() => {
                      setModalWithProps({
                        shown: modalWithProps.shown,
                        // @ts-ignore
                        values: {
                          ...modalWithProps.values,
                          comment: button.value,
                        },
                      });
                    }}
                    theme={theme}
                    index={index}
                    quickButtons={appSettings.QuickButtons}
                    key={index}
                  >
                    {button.value}
                  </PredefinedComment>
                );
              })}
            </PredefinedCommentsSection>

            <ModalButtons
              sx={{
                justifyContent:
                  timeExceptionState === LoadingStatus.SUCCESS
                    ? "center"
                    : "space-between",
              }}
            >
              {timeExceptionState === LoadingStatus.SUCCESS ? (
                <Text variant="success" color={`${theme?.colors?.success}`}>
                  <AnimatedCheck
                    color={theme.colors?.primary}
                    style={{ width: "66px", height: "66px" }}
                    duration={MODAL_DURATION}
                  />
                </Text>
              ) : (
                <React.Fragment>
                  <Button
                    variant="outline"
                    onClick={() =>
                      setModalWithProps({ shown: false, values: undefined })
                    }
                    style={{ width: "150px" }}
                  >
                    <Trans id="common.goBack" />
                  </Button>
                  <Button
                    loading={timeExceptionState === LoadingStatus.LOADING}
                    disabled={timeExceptionState === LoadingStatus.LOADING}
                    onClick={() => {
                      handleDirectBook(
                        modalWithProps?.values?.value,
                        modalWithProps?.values?.time
                      );
                    }}
                    style={{ width: "150px" }}
                  >
                    <Trans id="common.bookTime" />
                  </Button>
                </React.Fragment>
              )}
            </ModalButtons>
          </Modal>
        </Dialog>
      ) : null}
      {!appSettings?.Resource ? (
        <MessageHolder theme={theme}>
          <Message backgroundColor={theme?.colors?.background}>
            <Trans id="conference.pickResource" />
          </Message>
          <SettingsIconHolder
            data-testid="conferenceSettings"
            sx={{
              display: "inline",
              zIndex: 100,
              width: "auto",
            }}
            onClick={() => setSettingsVisible(true)}
          >
            <SettingsIcon
              sx={{
                path: {
                  fill: theme?.colors?.primary,
                },
              }}
              width="40"
              height="40"
            />
          </SettingsIconHolder>
        </MessageHolder>
      ) : null}
      {requiredResourcesLoaded ? (
        <Content
          style={{
            background: `no-repeat url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          <ContentLeft
            data-testid="settings"
            style={{
              backgroundColor: `${addOpacityToColor(
                transformColorToHex(theme?.colors?.background),
                LEFT_CONTENT_OPACITY
              )}`,
            }}
          >
            <Settings
              visible={settingsVisible}
              setSettingsVisible={setSettingsVisible}
            />
            <Flex sx={{ flexDirection: "column" }}>
              <Flex>
                <img
                  sx={{ marginBottom: "0.25rem" }}
                  src={Logo}
                  alt="bokamera"
                />
              </Flex>
              <Time />
            </Flex>

            <Slide
              cascade
              duration={300}
              direction="left"
              triggerOnce={true}
              damping={0.5}
              delay={200}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {appSettings?.Resource ? (
                  <Text as="h2">{appSettings?.Resource.Name}</Text>
                ) : null}
                {ongoingEvent ? (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <OngoingEvent
                      showName={appSettings.ShowName}
                      currentDate={currentDate}
                      ongoingEvent={ongoingEvent}
                      showModal={showModal}
                    />
                  </Box>
                ) : null}
              </Box>
            </Slide>

            <Slide
              cascade
              duration={300}
              direction="left"
              triggerOnce={true}
              damping={0.5}
              delay={200}
              sx={{ width: "100%" }}
            >
              {localNextTime ? (
                <DirectBooking
                  currentDate={currentDate}
                  setModalWithProps={setModalWithProps}
                  appSettings={appSettings}
                  ongoingEvent={ongoingEvent}
                  localNextTime={localNextTime}
                />
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Flex sx={{ alignItems: "center" }}>
                <SettingsIconHolder>
                  <UserButtons
                    buttons={["settings", "refresh"]}
                    setSettingsVisible={setSettingsVisible}
                  />
                </SettingsIconHolder>

                <BookingQR appSettings={appSettings}>
                  <BookingQRText>
                    <Trans id="conference.toCalendar" />
                  </BookingQRText>
                  <QRCodeWrapper>
                    <QRCode value={`${getQRCodeLink(company)}`} size={70} />
                  </QRCodeWrapper>
                </BookingQR>
              </Flex>
            </Slide>
          </ContentLeft>
          <ContentRight
            customWidth={appSettings?.CustomWidth}
            style={{
              justifyContent: "flex-start",
              backgroundColor: `${addOpacityToColor(
                transformColorToHex(
                  ongoingEvent ? theme?.colors?.danger : theme?.colors?.primary
                ),
                RIGHT_CONTENT_OPACITY
              )}`,
            }}
          >
            <Fade style={{ width: "100%" }} triggerOnce={true} duration={1000}>
              <Slide
                cascade
                duration={300}
                direction="up"
                triggerOnce={true}
                damping={0.25}
              >
                {!ongoingEvent ? (
                  <Text as="h2" variant="thin-heading">
                    <Trans id="conference.available" />
                  </Text>
                ) : (
                  <Text as="h2" variant="thin-heading">
                    <Trans id="conference.occupied" />
                  </Text>
                )}
                <Scrollbar
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "800px",
                    "@media screen and (max-width: 1024px)": {
                      minHeight: "550px",
                    },
                  }}
                >
                  {Object.keys(eventsGroupedByDays).length > 0
                    ? Object.keys(eventsGroupedByDays).map((day, index) => {
                        return (
                          <EventsGroupedByDays key={index} theme={theme}>
                            <SortByDays theme={theme}>
                              {day === "today" ? (
                                <React.Fragment>
                                  <Day>
                                    <Trans id="conference.today" />
                                  </Day>
                                  {!ongoingEvent &&
                                  localNextTime &&
                                  ((eventsGroupedByDays["today"] &&
                                    eventsGroupedByDays[
                                      "today"
                                    ].filter((booking) =>
                                      isAfterPredicate(
                                        booking,
                                        ongoingEvent,
                                        currentDate
                                      )
                                    ).length === 0) ||
                                    !eventsGroupedByDays["today"]) ? (
                                    <Text>
                                      <em>
                                        <Trans id="conference.noEvents" />
                                      </em>
                                    </Text>
                                  ) : null}
                                </React.Fragment>
                              ) : null}
                              {day === "tomorrow" ? (
                                <Day>
                                  <Trans id="conference.tomorrow" />
                                </Day>
                              ) : null}
                            </SortByDays>
                            {eventsGroupedByDays[day]
                              .filter((booking) =>
                                isAfterPredicate(
                                  booking,
                                  ongoingEvent,
                                  currentDate
                                )
                              )
                              ?.map((booking: any, index) => {
                                return booking.Customer ? (
                                  <LiveBooking
                                    key={`${day}-${booking.Id}`}
                                    currentDate={currentDate}
                                    ShowName={appSettings.ShowName}
                                    booking={booking}
                                  ></LiveBooking>
                                ) : booking.FromTime ? (
                                  <Booking key={index}>
                                    {day === "today" &&
                                    isBefore(
                                      currentDate,
                                      new Date(booking.From)
                                    ) ? (
                                      <Text>
                                        <em>
                                          <Trans id="conference.startingIn" />{" "}
                                          {formatDistanceStrict(
                                            currentDate,
                                            new Date(booking.From),
                                            { roundingMethod: "ceil" }
                                          )}
                                        </em>
                                      </Text>
                                    ) : null}
                                    <Flex>
                                      <BookingFromTo theme={theme}>
                                        <From sx={{ fontSize: "3" }}>
                                          {format(new Date(booking.From), "p")}
                                        </From>
                                        <To>
                                          {format(new Date(booking.To), "p")}
                                        </To>
                                      </BookingFromTo>
                                      <BookingMoreInfo>
                                        <Flex sx={{ flexDirection: "column" }}>
                                          <Customer sx={{ fontSize: "3" }}>
                                            {booking.ReasonTextPublic}
                                          </Customer>
                                          <BookedComment>
                                            <Trans id="appSettings.directBooking" />
                                          </BookedComment>
                                        </Flex>
                                        <Flex
                                          sx={{
                                            justifySelf: "flex-end",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          <Live
                                            booking={booking}
                                            date={new Date()}
                                          >
                                            <span
                                              sx={{ padding: "3px" }}
                                              role="img"
                                              aria-label="Current event"
                                            >
                                              ⚪
                                            </span>
                                          </Live>
                                        </Flex>
                                      </BookingMoreInfo>
                                    </Flex>

                                    <Divider theme={theme} />
                                  </Booking>
                                ) : null;
                              })}
                          </EventsGroupedByDays>
                        );
                      })
                    : null}
                </Scrollbar>
              </Slide>
            </Fade>
          </ContentRight>
        </Content>
      ) : !appSettings?.Resource ||
        (timeExceptions.isSuccess && bookings.isSuccess) ? (
        <Content>
          <ContentLeft>
            <Settings
              visible={settingsVisible}
              setSettingsVisible={setSettingsVisible}
            />
          </ContentLeft>
        </Content>
      ) : (
        <Flex
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!requiredResourcesError ? (
            <React.Fragment>
              <Loading size={60} color={`${theme?.colors?.primary}`} />
            </React.Fragment>
          ) : (
            <Flex sx={{ flexDirection: "column" }}>
              <Text>
                <Trans id="conference.pleaseReload" />
              </Text>
              <UserButtons
                style={{ alignSelf: "center", mt: "3" }}
                buttons={["refresh"]}
              />
              <UserButtons
                style={{ alignSelf: "center", mt: "3" }}
                buttons={["logout"]}
              />
            </Flex>
          )}
        </Flex>
      )}
    </React.Fragment>
  ) : (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading size={60} color={`${theme?.colors?.primary}`} />
    </Flex>
  );
};

export default withI18n()(Conference);
