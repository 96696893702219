import React, { useState } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
// import { useParams, useHistory } from "react-router-dom";
// import { RouteParams } from "routes/Routes";
import { useLocation } from "react-router-dom";
// import { Trans, Plural } from "@lingui/macro";
import Content, { ContentFull } from "components/Content/Content";
import SelectedTime from "./SelectedTime";

interface ConfirmationState {
  selectedTimes?: BokaMera.Time[];
}

const Confirmation: React.FC = () => {
  // const customer = useCustomer();
  // const params = useParams<RouteParams>();
  // const history = useHistory();
  const location = useLocation<ConfirmationState>();
  const [selectedTimes] = useState(location.state.selectedTimes);

  return (
    <>
      <Content>
        <ContentFull>
          <Grid>
            <Row>
              {selectedTimes?.map((time) => (
                <Col xs={6}>
                  <SelectedTime time={time} />
                </Col>
              ))}
            </Row>
          </Grid>
          $rbrace;
        </ContentFull>
      </Content>
    </>
  );
};

export default Confirmation;
