/** @jsx jsx */
import { jsx, useThemeUI, Box } from "theme-ui";
import React, { useState, useEffect } from "react";
import { Slide, Fade } from "react-awesome-reveal";
//@ts-ignore
import { AlphaJumper } from "react-alpha-jump";
import Scrollbar from "react-scrollbars-custom";
import { useCompany } from "providers/CompanyProvider/CompanyProvider";
import Error from "components/Error/Error";
import Content, { ContentLeft, ContentRight } from "components/Content/Content";
import { Trans } from "@lingui/macro";
import Customer from "./Customer";
import ReactMarkdown from "react-markdown";
import Dialog from "components/Dialog/Dialog";
import { useModal } from "react-modal-hook";
import Time from "components/Time/Time";
import WasherIcon from "svg/washer.gif";
import { Text, Flex } from "theme-ui";
import Settings from "components/SettingsMenu/SettingsMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserButtons from "UserButtons/UserButtons";
import { getBWColorFromContext } from "theme";
import { format } from "utils/datetime";
import { isWithinInterval, addYears, formatISO } from "date-fns";
import Loading from "components/Loading/Loading";
import { useQuery } from "react-query";
import * as api from "api/api";
import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import JumperWithTimer from "components/JumperWithTimer/JumperWithTimer";
import {sortBy} from 'lodash';

const JUMP_DIRECTORY_APPEAR_TRESHOLD = 20;
const REFETCH_INTERVAL = 1000 * 60; // in ms;

const CurrentlyWashing: React.FC<{ booking: BokaMera.BookingWithCustomer }> = ({
  booking,
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <React.Fragment>
      <Flex
        sx={{
          bg: theme?.colors?.primary,
          borderTopRightRadius: "6px",
          borderTopLeftRadius: "6px",
          // @ts-ignore
          padding: theme?.sizes["6"],
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            sx={{
              color: getBWColorFromContext(theme?.colors?.primary),
              fontSize: "2rem",
            }}
          >
            {booking.Customer.Firstname?.charAt(0)}. {booking.Customer.Lastname}
          </Text>
          <Flex
            sx={{
              color: getBWColorFromContext(theme?.colors?.primary),
            }}
          >
            <Text>{format(new Date(booking.From), "p")} </Text>
            <span sx={{ padding: `0px 4px` }}>-</span>
            <Text> {format(new Date(booking.To), "p")}</Text>
          </Flex>
        </Flex>
        <img
          sx={{
            marginRight: "-70px",
            marginLeft: "50px",
            width: "40px",
            opacity: 0.8,
          }}
          alt="washing"
          src={WasherIcon}
        />
      </Flex>
      <Flex
        sx={{
          background: "#fff",
          boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.2)`,
          color: "#000",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
          justifyContent: "center",
          alignItems: "center",
          // @ts-ignore
          padding: theme?.sizes["2"],
          textTransform: "uppercase",
        }}
      >
        <Trans id="currentlyWashing" />
      </Flex>
    </React.Fragment>
  );
};

const Start: React.FC = () => {
  const company = useCompany();
  const { accessToken, authorised } = useAuthentication();
  const customer = useCustomer();
  const context = useThemeUI();
  const { theme } = context;
  const [error, setError] = useState<Error | null>();
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const [selectedLetter, setSelectedLetter] = useState("");
  const companyId = company?.Id;
  const customerId = customer?.Id;

  const customers = useQuery(
    "customers",
    () =>
      api.getCustomers({
        params: {
          CompanyId: companyId,
          IncludeCustomFieldValues: true,
          IncludeAccessKeys: true
        },
      }),
    {
      enabled: authorised,
      refetchInterval: REFETCH_INTERVAL,
    }
  );

  const bookings = useQuery(
    ["bookings", companyId],
    ({ queryKey }) =>
      api.getBookings({
        params: {
          CompanyId: queryKey[1],
          BookingStart: format(new Date(), "yyyy-MM-dd"),
          BookingEnd: format(addYears(new Date(), 1), "yyyy-MM-dd"),
          CompanyBookings: true,
          StatusIds: "1,5",
          IncludeCustomerInformation: true,
        },
      }),
    {
      enabled: authorised,
      refetchInterval: REFETCH_INTERVAL,
    }
  );

  const currentDate = new Date();

  const currentBooking = bookings.data?.Results?.filter((booking) => {
    return isWithinInterval(currentDate, {
      start: new Date(booking.From),
      end: new Date(booking.To),
    });
  })[0] as BokaMera.BookingWithCustomer;

  const [showModal, hideModal] = useModal(
    () => (
      <Dialog onClose={hideModal}>
        <Box>
          <FontAwesomeIcon
            size="1x"
            icon={["fas", "times"]}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              margin: 20,
              cursor: "pointer",
            }}
            onClick={hideModal}
          />
          <ReactMarkdown
            source={company?.BookingAgreements}
            transformLinkUri={() => "#"}
          />
        </Box>
      </Dialog>
    ),
    [company]
  );

  const alphabet = new Set(
    sortBy(customers.data?.Results, (c) => c.Lastname?.toLowerCase())
    .map((customer) => customer.Lastname?.toUpperCase().substr(0, 1))
  );

  return (
    <Flex data-testid="start" style={{ flex: 1 }}>
      {error && (
        <Error message={error.message} onClose={() => setError(null)} />
      )}
      <Settings
        visible={settingsVisible}
        setSettingsVisible={setSettingsVisible}
      />
      <Content>
        <ContentLeft style={{ width: "100%" }}>
          <Slide duration={1000} direction="left" triggerOnce={true}>
            <Time />
          </Slide>
          <Slide duration={1000} direction="left" triggerOnce={true}>
            <Box>
              {company?.Name ? (
                <Text as="h1">{company?.Name}</Text>
              ) : (
                <Box sx={{ minHeight: 60 }} />
              )}
            </Box>
          </Slide>
          {currentBooking ? (
            <Slide
              duration={1000}
              direction="left"
              triggerOnce={true}
              style={{ width: "100%" }}
            >
              {currentBooking?.Customer ? (
                <CurrentlyWashing booking={currentBooking} />
              ) : null}
            </Slide>
          ) : null}
          <UserButtons
            buttons={["settings"]}
            setSettingsVisible={setSettingsVisible}
          />
        </ContentLeft>
        <ContentRight>
          {customers.isSuccess && customers.data ? (
            <React.Fragment>
              <Flex
                sx={{
                  flexDirection: "column",
                  padding: "20px 10px",
                  width: "100%",
                }}
              >
                <Text as="h3">
                  <Flex>
                    <Trans id="start.welcome">Var god välj din lägenhet</Trans>
                  </Flex>
                </Text>
                <Box
                  sx={{
                    fontSize: 14,
                    fontStyle: "italic",
                    "@media screen and (max-width: 1024px)": {
                      fontSize: 12,
                    },
                  }}
                >
                  <Text as="span">
                    *{" "}
                    <Trans id="youAreAgreeWith">
                      By continuing you are agree with
                    </Trans>
                  </Text>
                  <Text
                  as="span"
                    sx={{
                      ml: "1",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={showModal}
                  >
                    <Trans id="bookingAgreements">booking agreements</Trans>
                  </Text>
                </Box>
              </Flex>
              {customers.data.Results.length >
              JUMP_DIRECTORY_APPEAR_TRESHOLD ? (
                <Flex
                  sx={{
                    top: 0,
                    bottom: 0,
                    position: "fixed",
                    right: "2",
                  }}
                >
                  <JumperWithTimer
                    alphabet={Array.from(alphabet)}
                    onChange={setSelectedLetter}
                  />
                </Flex>
              ) : null}
              <Scrollbar
                style={{
                  width: `calc(100% - ${theme?.space ? theme?.space[2] : 0})`,
                  minWidth: "388px",
                  height: "100%",
                }}
              >
                {customers.data.Results.filter((customer) =>
                  Boolean(customer.Id)
                )
                  .filter((customer) => {
                    if (selectedLetter) {
                      return customer.Lastname?.substr(0, 1) === selectedLetter;
                    }
                    return true;
                  })
                  .map((customer, index) => (
                    <Fade triggerOnce={true} duration={1000} key={index}>
                      <Slide
                        duration={300}
                        direction="up"
                        triggerOnce={true}
                        damping={0.25}
                        delay={index * 50}
                      >
                        <Customer key={customer.Id} customer={customer} />
                      </Slide>
                    </Fade>
                  ))}
              </Scrollbar>
            </React.Fragment>
          ) : (
            <Loading size={30} color={`${theme?.colors?.background}`} />
          )}
        </ContentRight>
      </Content>
    </Flex>
  );
};

export default Start;
