import axios from "axios";

interface IConstants {
  BACKEND_URL: string;
  API_KEY: string;
  INACTIVITY_LOGOUT_TIME: number;
  INACTIVITY_WARNING_TIME: number;
  MODE: string;
  LICENSE_ID: string;
}

export const API_BASE_URL_FALLBACK = "http://localhost:4000";

const constants: IConstants = {
  BACKEND_URL:
    process.env.REACT_APP_API_BASE_URL ||
    API_BASE_URL_FALLBACK,
  API_KEY: process.env.REACT_APP_API_KEY || "",
  INACTIVITY_LOGOUT_TIME: 45,
  INACTIVITY_WARNING_TIME: 30,
  MODE: process.env.REACT_APP_MODE as string,
  LICENSE_ID: process.env.REACT_APP_LICENSE_ID as string,
};

export const UPDATE_TOKEN_INTERVAL = 290; // seconds
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
export const TOKEN_STORAGE_KEY = "token";
export const COMPANY_ID_STORAGE_KEY = "companyId";
export const APP_SETTINGS_STORAGE_KEY = "appSettings";
export const IS_AUTH_IN_PROGRESS_STORAGE_KEY = "isAuthInProgress";
export const AUTH_STATE_STORAGE_KEY = "authState";

export const getCompanyAxios = () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      
      config.headers["x-api-key"] = constants.API_KEY;
      config.headers["Content-Type"] = "application/json";
      config.headers.Authorization = token
        ? `Bearer ${token}`
        : undefined;
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export const getUserAxios = () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      config.headers["x-api-key"] = constants.API_KEY;
      config.headers["Content-Type"] = "application/json";

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export const getVersionAxios = () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export default constants;
