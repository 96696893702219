import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import registerIcons from "./utils/registerIcons";
import * as serviceWorker from "./serviceWorker";

registerIcons();

ReactDOM.render(<App />, document.getElementById("root"));

// Refresh app if an update is available and 1 minute
// of user inactivity has been past
serviceWorker.register({
  onUpdate: (registration) => {
    console.log("New version available!");

    let time = new Date().getTime();
    let timer: NodeJS.Timeout;

    resetTimer();

    const events = ["mousedown", "keypress", "scroll", "touchstart"];

    events.forEach((name) => {
      document.body.addEventListener(name, function () {
        time = new Date().getTime();
        resetTimer();
      });
    });

    function resetTimer() {
      clearInterval(timer);

      timer = setInterval(function () {
        const inactivityTime = 60000;
        const msPast = new Date().getTime() - time;
        const secToReload = Math.round((inactivityTime - msPast) / 1000);

        if (secToReload < 0) {
          clearInterval(timer);
          if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
          }
          // window.location.reload(true);
        } else {
          console.log(`The app will be refreshed in ${secToReload}s`);
        }
      }, 1000);
    }
  },
});
