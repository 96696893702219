import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import InactivityTimer from "components/InactivityTimer/InactivityTimer";
import CustomerProvider from "providers/CustomerProvider/CustomerProvider";
import Start from "routes/Start/Start";
import Authenticate from "routes/Authenticate/Authenticate";
import Home from "routes/Home/Home";
import Service from "routes/Service/Service";
import Confirmation from "routes/Confirmation/Confirmation";
import Login from "./Login/Login";
import Offline from "./Offline/Offline";
// @ts-ignore
import useOnlineStatus from "@rehooks/online-status";
import Conference from "./Conference/Conference";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import License from "./License/License";
import { useCompany } from "providers/CompanyProvider/CompanyProvider";
import { useLicence } from "providers/LicenseProvider/LicenseProvider";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import UserButtons from "UserButtons/UserButtons";
import { Trans } from "@lingui/react";
import { Box, Text } from "theme-ui";

const CustomerRoutes: React.FC = () => {
  return (
    <CustomerProvider>
      <Route path="/:companyId/:cutomerId" component={InactivityTimer} />
      <Route exact path="/:companyId/:customerId" component={Authenticate} />
      <Route exact path="/:companyId/:customerId/home" component={Home} />
      <Route
        exact
        path="/:companyId/:customerId/services/:serviceId"
        component={Service}
      />
      <Route
        exact
        path="/:companyId/:customerId/services/:serviceId/confirm"
        component={Confirmation}
      />
    </CustomerProvider>
  );
};

const CompanyRoutes: React.FC = () => {
  const appSettings = useAppSettings();
  return (
    <React.Fragment>
      {appSettings?.UseCase === "washing" || !appSettings?.UseCase ? (
        <Route exact path="/:companyId" component={Start} />
      ) : null}
      {appSettings?.UseCase === "conference" ? (
        <Route exact path="/:companyId" component={Conference} />
      ) : null}
    </React.Fragment>
  );
};

export interface RouteParams {
  companyId: string;
  customerId: string;
  serviceId: string;
  from: string;
  to: string;
}

const Routes = () => {
  const history = useHistory();
  const company = useCompany();
  const licenseProps = useLicence();
  const isOnline = useOnlineStatus();
  const { authorised } = useAuthentication();

  useEffect(() => {
    if (
      company?.Id &&
      !history.location.pathname.includes(company.Id) &&
      !history.location.pathname.includes("state")
    ) {
      history.push(company.Id);
    }
  }, [company, history.location.pathname]);

  if (!isOnline) {
    return <Offline />;
  }

  return licenseProps?.licenceId ? (
    <Switch>
      <Route
        exact
        path="/login"
        render={() => {
          // @ts-ignore
          return !company ? <Login /> : null;
        }}
      />
      <Route exact path="/:companyId/conference" component={Conference} />
      <Route
        exact
        path="/:companyId"
        render={({ match }) => {
          // keycloak will push state to history
          //  so prevent the company routes to render during auth
          return <CompanyRoutes />;
        }}
      />
      <Route path="/:companyId/:customerId" component={CustomerRoutes} />
      <Route
        path="*"
        render={() => (
          <Box
            padding="2"
            sx={{ textAlign: "left", margin: "0 auto", maxWidth: "32em" }}
          >
            <Text mb="3">
              <Trans id="routes.notFoundMessage" />
            </Text>
            <Box sx={{ margin: "0 auto" }}>
              <UserButtons style={{ maxWidth: "13em" }} buttons={["logout"]} />
            </Box>
          </Box>
        )}
      />
    </Switch>
  ) : (
    <Switch>
      {authorised ? (
        <Route exact path="/:companyId" component={License} />
      ) : null}

      {!company ? <Route path="/login" component={Login} /> : null}
    </Switch>
  );
};

export default Routes;
