import React, { useEffect } from "react";
import { ThemeProvider } from "theme-ui";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import { toTheme } from "@theme-ui/typography";
import { getTheme } from "./theme";
import Typography from "typography";
import * as WebFont from "webfontloader";

interface CustomTheme {
  value: string;
  colors: object;
}
export const themes: CustomTheme[] = [
  {
    value: "poppy",
    colors: {
      text: "#000",
      background: "#F0F4FF",
      primary: "#4663E5",
      secondary: "#7B91F2",
      gray: "#b3bce3",
      muted: "#8797e0",
    },
  },
  {
    value: "oldy",
    colors: {
      background: "#F8FAFC",
      primary: "#2841B0",
      secondary: "#1f3bb2",
      muted: "#f6f6f6",
      text: "#000",
      gray: "#595A5B",
    },
  },
  {
    value: "system",
    colors: {
      text: "#000",
      background: "#fff",
      primary: "#33e",
      secondary: "#119",
      muted: "#f6f6f6",
    },
  },
  {
    value: "bootstrap",
    colors: {
      primary: "#007bff",
      background: "#ffffff",
      text: "#212529",
      muted: "#dee2e6",
      secondary: "#6c757d",
    },
  },
  {
    value: "roboto",
    colors: {
      text: "#202124",
      background: "#fff",
      primary: "#1a73e8",
      secondary: "#9c27b0",
      muted: "#f1f3f4",
    },
  },
  {
    value: "swiss",
    colors: {
      text: "hsl(10, 20%, 20%)",
      background: "hsl(10, 10%, 98%)",
      primary: "hsl(10, 80%, 50%)",
      secondary: "hsl(10, 60%, 50%)",
      muted: "hsl(10, 20%, 94%)",
    },
  },
  {
    value: "future",
    colors: {
      text: "#000",
      background: "#fff",
      primary: "#11e",
      secondary: "#c0c",
      muted: "#f6f6ff",
    },
  },
  {
    value: "funk",
    colors: {
      text: "#000",
      background: "#fff",
      muted: "#f6f6f6",
      primary: "#609",
      secondary: "#306",
    },
  },
  {
    value: "tosh",
    colors: {
      text: "#000",
      background: "#fff",
      primary: "#000",
      secondary: "#3f3f3f",
      muted: "#e0e0e0",
    },
  },
  {
    value: "deep",
    colors: {
      text: "#ffffff",
      background: "hsl(230, 25%, 18%)",
      primary: "hsl(260, 100%, 80%)",
      secondary: "hsl(290, 100%, 80%)",
      muted: "hsla(230, 20%, 0%, 20%)",
    },
  },
  {
    value: "dark",
    colors: {
      text: "#fff",
      background: "#060606",
      primary: "#3cf",
      secondary: "#e0f",
      muted: "#191919",
    },
  },
  {
    value: "eco",
    colors: {
      text: "hsla(95, 50%, 96%, 1)",
      background: "hsl(230, 25%, 18%)",
      primary: "#5ECC6F",
      secondary: "#45A154",
      highlight: "#77F68A",
      gray: "#858a88",
      muted: "#66a993",
    },
  },
  {
    value: "jam",
    colors: {
      text: "hsla(95, 50%, 96%, 1)",
      background: "hsl(230, 25%, 18%)",
      primary: "#CF3425",
      secondary: "#a14d45",
      highlight: "#EE3636",
      gray: "#8a8585",
      muted: "#a96666",
    },
  },
];

const universalColors = {
  transparent: "transparent",
  black: "#000000",
  white: "#ffffff",
  success: "#5ECC6F",
  info: "#4663E5",
  warning: "#EFB78E",
  danger: "#CF3425",
};

const bokameraTypography = new Typography({
  baseFontSize: "20px",
  bodyFontFamily: ["Open Sans"],
  headerFontFamily: ["Open Sans"],
});

const themeExtensions = {
  space: [
    0,
    "0.25rem",
    "0.5rem",
    "1rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
    "32rem",
  ],
};

export const typographyThemes = [
  {
    theme: new Typography({}).options,
    value: "poppy",
  },
  { theme: bokameraTypography.options, value: "bokamera" },
];

// TODO: Required Fonts / Already Added Fonts
const alreadyAddedFonts: string[] = [];

export const Provider = (props: any) => {
  const appSettings = useAppSettings();

  useEffect(() => {
    if (
      !alreadyAddedFonts.includes("Open Sans") &&
      !alreadyAddedFonts.includes("Poppins")
    ) {
      WebFont.load({
        google: {
          families: ["Open Sans", "Poppins"],
        },
      });
      alreadyAddedFonts.push("Open Sans");
      alreadyAddedFonts.push("Poppins");
    }
  }, [appSettings?.Typography]);

  const matchingTypography = typographyThemes.filter(
    (theme) =>
      theme &&
      theme.value &&
      appSettings &&
      appSettings.Typography &&
      theme.value.toString().toLowerCase() ===
        appSettings.Typography?.toString().toLowerCase()
  );
  const matchingTheme = themes.filter(
    (theme) =>
      theme &&
      theme.value &&
      appSettings &&
      appSettings.Theme &&
      theme.value.toString().toLowerCase() ===
        appSettings?.Theme.toString().toLowerCase()
  );
  const typographyTheme =
    matchingTypography[0]?.value !== "poppy"
      ? toTheme(matchingTypography[0]?.theme)
      : {};

  let themeColors = {
    ...universalColors,
    text: "#000",
    background: "#F0F4FF",
    primary: "#4663E5",
    secondary: "#7B91F2",
    muted: "#b3bce3",
  };

  if (matchingTheme[0]) {
    // @ts-ignore
    themeColors = { ...matchingTheme[0].colors, ...universalColors };
  }

  let mergedTheme = getTheme({ baseColors: themeColors });

  const theme = {
    ...mergedTheme,
    ...typographyTheme,
    ...themeExtensions,
  };

  // @ts-ignore
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
