import React, { useContext } from "react";
/** @jsx jsx */
import { jsx, useThemeUI, Flex, ThemeUICSSProperties, Box } from "theme-ui";
import { Slide } from "react-awesome-reveal";
import { RouteParams } from "routes/Routes";
import { useParams, useHistory } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { ReactComponent as HomepageIcon } from "svg/homepage.svg";
import { ReactComponent as SettingsIcon } from "svg/appSettings.svg";
import { ReactComponent as BackIcon } from "svg/back.svg";
import { ReactComponent as RefreshIcon } from "svg/refresh.svg";
import { ReactComponent as BugReportIcon } from "svg/toolbox-solid.svg";
import { addOpacityToColor, getBWColorFromContext } from "theme";
import Dialog from "components/Dialog/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "react-modal-hook";
import ErrorReport from "routes/ErrorReport/ErrorReport";
import { AuthenticationContext } from "providers/AuthenticationProvider/AuthenticationProvider";
import { AppSettingsContext } from "providers/AppSettingsProvider/AppSettingsProvider";
import Button from "components/Button/Button";
import { queryClient } from "api/queryClient";

type ButtonType =
  | "logout"
  | "settings"
  | "home"
  | "back"
  | "refresh"
  | "bugReport";

interface UserButtonsProps {
  buttons: ButtonType[];
  setSettingsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  style?: ThemeUICSSProperties;
}

const Divider = () => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      sx={{
        width: "2px",
        margin: "0px 14px",
        background: addOpacityToColor(theme?.colors?.white, 20),
      }}
    />
  );
};

const Home = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      onClick={() => history.push(`/${params.companyId}/`)}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <HomepageIcon
        sx={{
          path: {
            fill: getBWColorFromContext(theme?.colors?.primary),
          },
        }}
        width="32"
        height="32"
      />
    </Box>
  );
};

interface SettingsProps {
  setSettingsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Settings: React.FC<SettingsProps> = ({ setSettingsVisible }) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      data-testid="settingsButton"
      onClick={() => setSettingsVisible(true)}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <SettingsIcon
        sx={{
          path: {
            fill: getBWColorFromContext(theme?.colors?.primary),
          },
        }}
        width="32"
        height="32"
      />
    </Box>
  );
};

const ErrorReportButton: React.FC = () => {
  const params = useParams<RouteParams>();
  const context = useThemeUI();
  const { theme } = context;

  const [showModal, hideModal] = useModal(() => {
    return (
      <Dialog onClose={hideModal}>
        <FontAwesomeIcon
          size="1x"
          icon={["fas", "times"]}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            margin: 20,
            cursor: "pointer",
            zIndex: 999,
          }}
          onClick={hideModal}
        />
        <ErrorReport params={params} hideModal={hideModal} />
      </Dialog>
    );
  }, []);

  return (
    <Box
      onClick={() => showModal()}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <BugReportIcon
        sx={{
          path: {
            // @ts-ignore
            fill: getBWColorFromContext(theme?.colors?.primary),
          },
        }}
        width="32"
        height="32"
      />
    </Box>
  );
};

const Logout: React.FC = () => {
  const { disconnect } = useContext(AuthenticationContext);
  const appSettingsContext = useContext(AppSettingsContext);

  return (
    <Box
      data-testid="disconnectDisplay"
      onClick={() => {
        disconnect();
        appSettingsContext?.resetSettings();
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Button>
        <Trans id="UserButtons.disconnectDisplay" />
      </Button>
    </Box>
  );
};

const GoBack: React.FC = () => {
  const history = useHistory();
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      onClick={() => history.goBack()}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <BackIcon
        sx={{
          path: {
            fill: getBWColorFromContext(theme?.colors?.primary),
          },
        }}
        width="32"
        height="32"
      />
    </Box>
  );
};

const Refresh: React.FC = () => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      onClick={() => {
        queryClient.resetQueries();
        window.location.reload();
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <RefreshIcon
        sx={{
          path: {
            fill: getBWColorFromContext(theme?.colors?.primary),
          },
        }}
        width="32"
        height="32"
      />
    </Box>
  );
};

const UserButtons: React.FC<UserButtonsProps> = ({
  buttons,
  setSettingsVisible,
  style,
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Slide
      direction="left"
      triggerOnce={true}
      sx={{
        ...style,
      }}
    >
      <Flex
        data-testid="userButtons"
        sx={{
          borderRadius: "6px",
          background: theme?.colors?.primary,
          padding: "4px 6px",
        }}
      >
        {buttons.map((buttonType, index) => {
          return buttonType === "home" ? (
            <React.Fragment key={index}>
              <Home />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : buttonType === "settings" && setSettingsVisible ? (
            <React.Fragment key={index}>
              <Settings setSettingsVisible={setSettingsVisible} />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : buttonType === "logout" ? (
            <React.Fragment key={index}>
              <Logout />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : buttonType === "back" ? (
            <React.Fragment key={index}>
              <GoBack />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : buttonType === "refresh" ? (
            <React.Fragment key={index}>
              <Refresh />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : buttonType === "bugReport" ? (
            <React.Fragment key={index}>
              <ErrorReportButton />
              {buttons.length > 1 && index < buttons.length - 1 ? (
                <Divider />
              ) : null}
            </React.Fragment>
          ) : null;
        })}
      </Flex>
    </Slide>
  );
};

export default UserButtons;
