/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React, { useCallback } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Flex } from "theme-ui";
import Button from "components/Button/Button";

type onChangeFn = (number: string) => void;
type onClickFn = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

interface NumProps {
  children?: any;
  onClick?: onClickFn;
  style?: object;
}

const Num: React.FC<NumProps> = ({ children, onClick, style }) => {
  return (
    <Button
      // @ts-ignore
      onClick={onClick}
      variant="elevated"
      style={{
        margin: "10px",
        fontSize: "30px",
        width: "100px",
        height: "100px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 1px 3px 0 #0000001a, 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

interface NumPadProps {
  number: string;
  onChange: onChangeFn;
  buttonStyle?: object;
}

const NumPad: React.FC<NumPadProps> = ({ number, onChange, buttonStyle }) => {
  const onNumChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      onChange(number + event.currentTarget.textContent);
    },
    [onChange, number]
  );

  const onDelete = useCallback(() => {
    if (number.length > 0) {
      onChange(number.substring(0, number.length - 1));
    }
  }, [number, onChange]);

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Fade triggerOnce={true} duration={1000}>
        <Zoom cascade duration={300} triggerOnce={true} damping={0.25}>
          <Flex>
            <Num style={buttonStyle} onClick={onNumChange}>
              1
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              2
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              3
            </Num>
          </Flex>
          <Flex>
            <Num style={buttonStyle} onClick={onNumChange}>
              4
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              5
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              6
            </Num>
          </Flex>
          <Flex>
            <Num style={buttonStyle} onClick={onNumChange}>
              7
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              8
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              9
            </Num>
          </Flex>
          <Flex>
            <Num style={buttonStyle} onClick={onDelete}>
              Del
            </Num>
            <Num style={buttonStyle} onClick={onNumChange}>
              0
            </Num>
          </Flex>
        </Zoom>
      </Fade>
    </Box>
  );
};

export { NumPad as default };
