/** @jsx jsx */
import { jsx, Text, Alert, Box } from "theme-ui";
import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slide, Fade } from "react-awesome-reveal";
import { Trans } from "@lingui/macro";
import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
import { useParams, useHistory } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import Content, { ContentLeft, ContentRight } from "components/Content/Content";
import NumPad from "components/NumPad/NumPad";
import { withI18n } from "@lingui/react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Time from "components/Time/Time";
import Button from "components/Button/Button";
import UserButtons from "UserButtons/UserButtons";

interface Props {
  i18n: I18n;
}

interface NumProps {
  children?: any;
  style?: object;
}

const Num: React.FC<NumProps> = ({ children, style }) => {
  return (
    <Button
      style={{
        height: 82,
        width: 82,
        margin: 20,
        cursor: "default",
        fontSize: "60px",
        pointerEvents: "none",
        paddingTop: "4px",
        ...style,
      }}
      variant="elevated"
    >
      {children}
    </Button>
  );
};

const Authenticate: React.FC<Props> = ({ i18n }) => {
  const customer = useCustomer();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [error, setError] = useState<string>("");
  const [number, setNumber] = useState("");
  const name = `${customer?.Firstname} ${customer?.Lastname}`;
  const pinCode = customer?.AccessKeys?.find(key => key.AccessKeyTypeId === 1)?.Value;

  useEffect(() => {
    if (number.length === 4) {
      if (pinCode && pinCode === number) {
        history.replace(`/${params.companyId}/${params.customerId}/home`);
      } else {
        setNumber("");
        setError(i18n._(t("invalidPIN")`Felaktig PIN. Var god försök igen.`));
      }
    }
  }, [customer, history, number, params.companyId, params.customerId, i18n]);

  const setPin = useCallback(
    (pin) => {
      setError("");
      setNumber(pin);
    },
    [setError, setNumber]
  );

  return (
    <React.Fragment>
      <Content>
        <ContentLeft>
          <Time />
          <Fade
            triggerOnce={true}
            duration={1000}
            style={{ minHeight: 500, maxWidth: 500 }}
          >
            <Slide
              cascade
              direction="up"
              duration={300}
              triggerOnce={true}
              damping={0.15}
            >
              <Text>
                <Trans id="home.welcome">Välkommen</Trans>,{" "}
                {name.split(" ")[0].charAt(0)}. {name.split(" ")[1]}
              </Text>
              <Text as="h1">
                <Trans id="auth.enterYourPIN">Var god ange din PIN</Trans>
              </Text>
              <Box
                sx={{
                  height: 150,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Num style={{ marginLeft: 0 }}>
                  <Box>{number[0] ? "*" : ""}</Box>
                </Num>
                <Num>
                  <Box>{number[1] ? "*" : ""}</Box>
                </Num>
                <Num>
                  <Box>{number[2] ? "*" : ""}</Box>
                </Num>
                <Num>
                  <Box>{number[3] ? "*" : ""}</Box>
                </Num>
              </Box>
              {error ? (
                <Slide
                  direction="left"
                  duration={300}
                  triggerOnce={false}
                  damping={0.15}
                >
                  <Alert
                    sx={{
                      minWidth: 300,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Text>
                      <FontAwesomeIcon
                        size="1x"
                        icon={["fas", "info-circle"]}
                        sx={{ marginRight: 10 }}
                      />
                      {error}.
                    </Text>
                  </Alert>
                </Slide>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Slide>
          </Fade>
          <UserButtons buttons={["back"]} />
        </ContentLeft>
        <ContentRight>
          <NumPad number={number} onChange={setPin} />
        </ContentRight>
      </Content>
    </React.Fragment>
  );
};

export default withI18n()(Authenticate);
