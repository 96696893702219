import { LANGAUGE_STORAGE_KEY } from "providers/I18nProvider/I18nProvider";

export type LanguageCode = "sv" | "en" | "no" | "fi";
export type CountryCode = "SE" | "NO" | "FI";
export type FullLanguageCode = "sv-SE" | "en-GB" | "nb-NO" | "fi-FI";

export const getCurrentLanguageCode = (): LanguageCode =>
  (window.localStorage.getItem(LANGAUGE_STORAGE_KEY) as LanguageCode) || "en";

export const getCurrentCountryCode = (): CountryCode => {
  const languageCode = getCurrentLanguageCode();

  switch (languageCode) {
    case "sv":
      return "SE";
    case "no":
      return "NO";
    case "fi":
      return "FI";
    default:
      return "SE";
  }
};

export const getFullLanguageCode = (languageCode: string): FullLanguageCode => {
  switch (languageCode) {
    case "en":
      return "en-GB";
    case "sv":
      return "sv-SE";
    case "no":
      return "nb-NO";
    case "fi":
      return "fi-FI";
    default:
      return "sv-SE";
  }
};
