/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React from "react";
import { useThemeUI, Flex, ThemeUICSSProperties } from "theme-ui";
import { getBWColorFromContext } from "../../theme";

interface ContentProps {
  children: any;
  style?: ThemeUICSSProperties;
}

interface ContentLeftProps {
  children?: any;
  style?: ThemeUICSSProperties;
  customWidth?: number;
}

interface ContentRightProps {
  children?: any;
  style?: ThemeUICSSProperties;
  customWidth?: number;
}

interface ContentFullProps {
  children?: any;
  style?: ThemeUICSSProperties;
}

export const ContentLeft: React.FC<ContentLeftProps> = ({
  children,
  style,
  customWidth,
  ...restProps
}) => {
  const context = useThemeUI();
  const { theme } = context;
  return (
    <Box
      sx={{
        width: customWidth ? `${customWidth}%` : "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.space ? theme.space[5] : "80px",
        "@media screen and (max-width: 1024px)": {
          margin: "0px",
          padding: theme.space ? theme.space[4] : "40px",
        },
        justifyContent: "space-between",
        background: `${theme.colors?.background}`,
        ...style,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const ContentRight: React.FC<ContentRightProps> = ({
  children,
  style,
  customWidth,
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Flex
      sx={{
        width: customWidth ? `${customWidth}%` : "85%",
        display: customWidth === 1 ? "none" : "",
        marginLeft: "auto",
        background: `${theme.colors?.primary}`,
        color: `${getBWColorFromContext(theme?.colors?.primary)}`,
        padding: theme.space ? theme.space[5] : "80px",
        "@media screen and (max-width: 1024px)": {
          margin: "0px",
          padding: theme.space ? theme.space[4] : "40px",
        },
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {children}
    </Flex>
  );
};

export const ContentFull: React.FC<ContentFullProps> = ({
  children,
  style,
}) => {
  return (
    <Flex
      sx={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "40px",
        "@media screen and (max-width: 1024px)": {
          margin: "0px",
        },
        ...style,
      }}
    >
      {children}
    </Flex>
  );
};

const Content: React.FC<ContentProps> = ({ children, style }) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        display: "flex",
        height: "100%",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default Content;
