/** @jsx jsx */
import { jsx, Text, Alert, useThemeUI, Box, Flex } from "theme-ui";
import React, { useCallback, useEffect, useState } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addYears, format, isWithinInterval, addMinutes } from "date-fns";
import { Trans } from "@lingui/macro";
import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
import { RouteParams } from "routes/Routes";
import Content, { ContentLeft, ContentRight } from "components/Content/Content";
import Booking from "./Booking";
import Service from "./Service";
import { groupBy, orderBy } from "lodash";
import Time from "components/Time/Time";
import Settings from "components/SettingsMenu/SettingsMenu";
import UserButtons from "UserButtons/UserButtons";
import { isAfter } from "date-fns";
import Scrollbar from "react-scrollbars-custom";
import { AnimatePresence, motion } from "framer-motion";
import { toDateISOString } from "utils/datetime";
import { useTime } from "providers/TimeProvider/TimeProvider";
import { isBefore } from "date-fns";
import { useQuery, useMutation } from "react-query";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import * as api from "api/api";
import { useToasts } from "react-toast-notifications";
import { getErrorResponse } from "utils/common";
import { queryClient } from "api/queryClient";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";

import Loading from "components/Loading/Loading";

const Home: React.FC = () => {
  const params = useParams<RouteParams>();
  const customer = useCustomer();
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const { currentDate } = useTime();
  const { authorised, companyId } = useAuthentication();
  const { addToast } = useToasts();
  const appSettings = useAppSettings();

  const services = useQuery(
    ["services", companyId],
    ({ queryKey }) => api.getServices({ params: { Active: true, CompanyId: queryKey[1],  } }),
    {
      enabled: !!(authorised && companyId),
    }
  );

  const bookings = useQuery(
    ["bookings-customer", companyId, customer?.Id],
    ({ queryKey }) =>
      api.getBookings({
        params: {
          CompanyId: queryKey[1],
          CustomerId: queryKey[2],
          StatusIds: "1,5",
          CompanyBookings: true,
          IncludeCustomerInformation: true,
          BookingStart: format(new Date(), "yyyy-MM-dd"),
          BookingEnd: format(addYears(new Date(), 1), "yyyy-MM-dd"),
        },
      }),
    {
      enabled: !!(authorised && customer?.Id),
    }
  );

  const unbook = useMutation(["bookings-customer", companyId, customer?.Id], (data: any) => api.unbook(data[0], data[1]), {
    onSettled: () => {
      queryClient.refetchQueries(["bookings", params.companyId]);
      queryClient.invalidateQueries(["bookings-customer", companyId, customer?.Id]);
    },
    onError: (err) => {
      addToast(getErrorResponse(err), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  });

  const onUnbookCb = (booking: BokaMera.Booking, current = false) => {
    current
      ? unbook.mutate([
          {
            url: `/bookings/${booking.Id}`,
            method: "put",
          },
          {
            CompanyId: params.companyId,
            Id: String(booking.Id),
            From: booking.From,
            // to avoid unbooking in the past
            To: isBefore(new Date(booking.From), currentDate)
              ? toDateISOString(addMinutes(new Date(booking.From), 1))
              : toDateISOString(addMinutes(currentDate, 1)),
            AllowBookingOutsideSchedules: true,
          },
        ])
      : unbook.mutate([
          {
            url: `/bookings/${booking.Id}`,
            method: "delete",
            params: {
              Id: String(booking.Id),
              SendEmailConfirmation: false,
              SendSmsConfirmation: false,
            },
          },
        ]);
  };

  const context = useThemeUI();
  const { theme } = context;

  const name = `${customer?.Firstname} ${customer?.Lastname}`;

  const groupedServices = appSettings?.GroupServices
    ? groupBy(
        orderBy(
          services.data?.Results.filter((s) =>
            appSettings?.selectedServicesToShow
              ? appSettings.selectedServicesToShow.includes(s.Id.toString())
              : true
          ),
          [(service: any) => service?.Group?.toLowerCase() || "other"]
        ),
        (service) => service.Group ?? "Other"
      )
    : groupBy(
        services.data?.Results.filter((s) =>
          appSettings?.selectedServicesToShow
            ? appSettings.selectedServicesToShow.includes(s.Id.toString())
            : true
        ),
        (service) => service.Group
      );

  const currentFutureBookings =
    bookings?.data?.Results?.filter(
      (booking) =>
        isAfter(new Date(booking?.To), currentDate) ||
        isWithinInterval(currentDate, {
          start: new Date(booking.From),
          end: new Date(booking.To),
        })
    ) || null;

  return (
    customer ? <Flex style={{ flex: 1 }} data-testid="home">
      <Content>
        <ContentLeft>
          <Settings
            visible={settingsVisible}
            setSettingsVisible={setSettingsVisible}
          />
          <Time />
          <Box sx={{ minHeight: 500, width: "100%" }}>
            <Fade
              sx={{
                width: "100%",
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
              triggerOnce={true}
              duration={1000}
            >
              <Slide
                duration={300}
                direction="up"
                triggerOnce={true}
                damping={0.25}
              >
                <Slide
                  cascade
                  duration={300}
                  direction="left"
                  triggerOnce={true}
                  damping={0.5}
                  delay={200}
                  sx={{ width: "100%" }}
                >
                  <Text>
                    <Trans id="home.welcome">Välkommen</Trans>,{" "}
                    {name.split(" ")[0].charAt(0)}. {name.split(" ")[1]}
                  </Text>

                  {currentFutureBookings?.length === 0 ? (
                    <Alert sx={{ marginTop: 20 }}>
                      <Text>
                        <FontAwesomeIcon
                          size="1x"
                          icon={["fas", "info-circle"]}
                          sx={{ marginRight: 10 }}
                        />
                        <Trans id="home.yourHaveNoBookings">
                          Du har inga bokningar just nu
                        </Trans>
                        .
                      </Text>
                    </Alert>
                  ) : (
                    <Text as={"h3"} variant="heading">
                      <Trans id="home.yourBookedTimes">Dina bokade tider</Trans>
                    </Text>
                  )}
                  <Scrollbar
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: 400,
                    }}
                  >
                    <AnimatePresence>
                      {currentFutureBookings?.map((booking) => (
                        <motion.div
                          exit={{
                            transform: "translate(-100%)",
                            opacity: 0,
                            pointerEvents: "none",
                          }}
                          initial={{ pointerEvents: "all" }}
                          transition={{ duration: 0.3 }}
                          key={booking.Id}
                          // @ts-ignore
                          sx={{ paddingRight: theme?.sizes[2] }}
                        >
                          {isWithinInterval(currentDate, {
                            start: new Date(booking.From),
                            end: new Date(booking.To),
                          }) ? (
                            <Booking
                              current={true}
                              booking={booking}
                              bookingMutation={unbook}
                              onClick={() => onUnbookCb(booking, true)}
                            />
                          ) : isAfter(new Date(booking.From), currentDate) ? (
                            <Booking
                              booking={booking}
                              bookingMutation={unbook}
                              onClick={onUnbookCb}
                            />
                          ) : null}
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </Scrollbar>
                </Slide>
              </Slide>
            </Fade>
          </Box>
          <UserButtons
            buttons={["back", "settings", "bugReport"]}
            setSettingsVisible={setSettingsVisible}
          />
        </ContentLeft>
        <ContentRight>
        <Scrollbar
            style={{
              width: `calc(100% - ${theme?.space ? theme?.space[2] : 0})`,
              minWidth: "388px",
            }}
          >

            <Fade style={{ width: "100%" }} triggerOnce={true} duration={1000}>
              <Slide
                cascade
                duration={300}
                direction="up"
                triggerOnce={true}
                damping={0.25}
              >
                <Scrollbar
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "90vh",
                  }}
                >
                  <Box
                    style={{
                      maxHeight: 800,
                      width: "100%",
                    }}
                  >
                    {Object.keys(groupedServices).map((group) => (
                      <Box
                        sx={{
                          display: "block",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        key={group}
                      >
                        {appSettings?.GroupServices ? <Text as="h3">{group}</Text> : null}
                        {groupedServices[group].map((service) => (
                          <Service service={service} key={service.Id} />
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Scrollbar>
              </Slide>
            </Fade>
          </Scrollbar>
        </ContentRight>
      </Content>
     </Flex> : <Loading size={30} color={`${theme?.colors?.background}`} />
  )
};

export default Home;
