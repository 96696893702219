import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { format, parseISO } from "utils/datetime";
import styles from "./SelectedTime.module.scss";

interface TimeProps {
  time: BokaMera.Time;
}

const SelectedTime: React.FC<TimeProps> = ({ time }) => {
  /*  useEffect(() => {
    if (confirmed && booking.initial) {
      book({
        From: time.From,
        To: time.To,
        CompanyId: params.companyId,
        CustomerId: params.customerId,
        ServiceId: params.customerId,
        PinCode: customer?.PinCode
      });
    }
  });
*/
  return (
    <div className={styles.time}>
      <Row middle="xs">
        <Col xs={10}>
          <h4>{format(parseISO(time.From), "PPP")}</h4>
          <h2>
            {format(parseISO(time.From), "p")}-{format(parseISO(time.To), "p")}
          </h2>
        </Col>
        <Col xs={2}></Col>
      </Row>
      <Row>
        <Col xs={12}></Col>
      </Row>
    </div>
  );
};

export default SelectedTime;
