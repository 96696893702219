/** @jsx jsx */
import { jsx, Text, Box, useThemeUI } from "theme-ui";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import Content from "components/Content/Content";
import Button from "components/Button/Button";
import { Trans, t } from "@lingui/macro";
import TextArea from "components/Form/TextArea";
import Select from "components/Form/Select";
import { withI18n } from "@lingui/react";
import { I18n } from "@lingui/core";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import * as api from "api/api";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import Loading from "components/Loading/Loading";

export interface Props {
  hideModal: Function;
  i18n: I18n;
  params: {
    companyId: string;
    customerId: string;
    [index: string]: string;
  };
}

const ErrorReport: React.FC<Props> = ({ i18n, params, hideModal }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const context = useThemeUI();
  const { theme } = context;
  const { companyId, customerId } = params;
  const { authorised } = useAuthentication();

  const placeholderText = i18n._(
    t(
      "errorReport.defaultMessage"
    )`Jag har problem med att tvättmaskinen inte fungerar.`
  );

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      MessageBody: "",
      resourceId: null,
    },
  });

  const resources = useQuery("resources", () => api.getResources(), {
    enabled: authorised,
  });

  const sendEmail = useMutation(
    (data: any) =>
      api.sendEmail(
        {},
        {
          MessageBody:
            data.MessageBody +
            "\n\n" +
            resources?.data?.Results?.find(
              ({ Id }) => String(Id) === data.resourceId
            )?.Name,
          CompanyId: companyId,
          MessageTitle: "Felanmälan",
        }
      ),
    {
      onSuccess: () => {
        addToast(
          i18n._(
            t("errorReport.successMessage")`Error report successfully sent!`
          ),
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        hideModal();
        goBack();
      },
    }
  );

  const onSubmit = (data: any) => {
    sendEmail.mutate({
      MessageBody:
        data.MessageBody +
        "\n\n" +
        resources?.data?.Results?.find(
          ({ Id }) => String(Id) === data.resourceId
        )?.Name,
      CompanyId: companyId,
      MessageTitle: "Felanmälan",
    });
  };

  const goBack = () => {
    history.push(`/${companyId}/${customerId}/home`);
  };

  return (
    <React.Fragment>
      <Content>
        <form
          sx={{ width: "620px", margin: "0 auto", padding: 20 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box>
            <label htmlFor="resourceId">
              <Trans id="errorReport.resource">Välj objekt att felanmäla</Trans>
            </label>
            <Controller
              as={Select}
              name="resourceId"
              id="resourceId"
              control={control}
              items={resources.data?.Results.map((resource) => ({
                text: resource.Name,
                value: String(resource.Id),
              }))}
              placeholder={i18n._(t("errorReport.selectDefault")`Choose here`)}
            />
          </Box>
          <Box>
            <label htmlFor="messageBody">
              <Trans id="errorReport.messageBody">Felanmälan</Trans>
            </label>
            <TextArea
              name="MessageBody"
              id="messageBody"
              ref={register({ required: true })}
              placeholder={placeholderText}
            />
          </Box>
          <Button
            disabled={sendEmail.isLoading}
            htmlType="submit"
            style={{ width: "12rem", margin: "1rem 0rem" }}
          >
            {!sendEmail.isLoading ? (
              <Trans id="errorReport.submit">Skicka</Trans>
            ) : (
              <Loading size={30} color={theme?.colors?.background} />
            )}
          </Button>

          {sendEmail.isError ? (
            <Text>
              {" "}
              <Trans id="errorReport.errorMessage">
                Något gick fel, försök igen!
              </Trans>
            </Text>
          ) : (
            <Text></Text>
          )}
        </form>
      </Content>
    </React.Fragment>
  );
};
// @ts-ignore
export default withI18n()(ErrorReport);
