import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faComment,
  faExclamationTriangle,
  faTint,
  faCheck,
  faCalendar,
  faKey,
  faUnlock,
  faLock,
  faSpinner,
  faInfoCircle,
  faPlug,
  faSignOutAlt,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faTimes,
  faAngleDoubleRight,
  faPlus,
  faClose
} from "@fortawesome/free-solid-svg-icons";


export default function registerIcons() {
  library.add(
    faClose,
    faArrowLeft,
    faArrowRight,
    faBuilding,
    faComment,
    faExclamationTriangle,
    faTint,
    faCheck,
    faCalendar,
    faKey,
    faUnlock,
    faLock,
    faSpinner,
    faInfoCircle,
    faPlug,
    faSignOutAlt,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faTimes,
    faAngleDoubleRight,
    faPlus
  );
}
