/** @jsx jsx */
import { jsx, Flex, Text, useThemeUI } from "theme-ui";
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "utils/datetime";
import { formatDistanceStrict } from "utils/datetime";
import { Trans } from "@lingui/macro";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import {
  EventFromTo,
  OngoingEventStyled,
  BookedComment,
  Customer,
} from "./styled";

interface Props {
  currentDate: Date;
  ongoingEvent: any;
  showName: boolean;
  showModal?: () => void;
}
const OngoingEvent: React.FC<Props> = ({
  currentDate,
  ongoingEvent,
  showName,
  showModal = () => {},
}) => {
  const { theme } = useThemeUI();

  return (
    <OngoingEventStyled mb={theme.space ? theme.space[3] : 0} theme={theme}>
      <Text
        sx={{
          fontSize: theme.fontSizes ? theme.fontSizes[2] : undefined,
        }}
      >
        <Trans id="conference.ongoingEvent" /> -{" "}
        <Trans id="conference.occupiedFor" />{" "}
        {formatDistanceStrict(currentDate, new Date(ongoingEvent.To))}
      </Text>
      <Flex sx={{ alignItems: "flex-start" }}>
        <EventFromTo theme={theme}>
          {format(new Date(ongoingEvent.From), "p")} -{" "}
          {format(new Date(ongoingEvent.To), "p")}
        </EventFromTo>
        {ongoingEvent.ReasonTextPublic ? (
          <BookedComment as="ul" theme={theme}>
            <li>{ongoingEvent.ReasonTextPublic}</li>
          </BookedComment>
        ) : ongoingEvent.Customer ? (
          <Customer theme={theme}>
            {showName
              ? (
                <React.Fragment>
                  <Trans id="conference.bookedBy" />
                  <Text>{ongoingEvent.Customer.Firstname} {ongoingEvent.Customer.Lastname}</Text>
                </React.Fragment>
              )
              : <Trans id="conference.occupied" />}{" "}
            {`${
              ongoingEvent.BookedComments ? ongoingEvent.BookedComments : ""
            }`}{" "}
          </Customer>
        ) : null}
      </Flex>
      <FontAwesomeIcon
        size="1x"
        icon={["fas", "times"]}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          marginRight: "3",
          marginTop: "3",
          cursor: "pointer",
          zIndex: 999,
        }}
        onClick={() => showModal()}
      />
    </OngoingEventStyled>
  );
};

export default OngoingEvent;