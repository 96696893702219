/** @jsx jsx */
import { jsx, useThemeUI, Box, Flex } from "theme-ui";
import { useState, useCallback } from "react";
//@ts-ignore
import { AlphaJumper } from "react-alpha-jump";
import { getBWColorFromContext } from "theme";
import SelectedLetter from "./SelectedLetter";

const JUMP_INACTIVITY_TIMEOUT = 10 * 1000; // in ms

interface Props {
  alphabet: any[];
  onChange: (val: string) => void;

  /**
   * timeout in ms after which onChange is called
   */
  timeout?: number;
}

const JumperWithTimer: React.FC<Props> = ({
  alphabet,
  onChange,
  timeout = JUMP_INACTIVITY_TIMEOUT,
}) => {
  const context = useThemeUI();
  const { theme } = context;
  const [selectedLetter, setSelectedLetter] = useState("");

  const handleChange = useCallback((char: string) => {
    setSelectedLetter(char);
    onChange(char);
  }, []);

  if (!alphabet) return null;

  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlphaJumper
        alphabet={Array.from(alphabet)}
        color={getBWColorFromContext(theme?.colors?.primary)}
        onTouch={handleChange}
        style={{
          display: "block",
          height: "auto",
          width: theme?.sizes ? theme?.sizes[5] : "auto",
          textAlign: "center",
        }}
      />
      <Box
        as="span"
        sx={{ width: "5", textAlign: "center" }}
        onClick={() => handleChange("")}
      >
        *
      </Box>
      <SelectedLetter
        timeout={timeout}
        letter={selectedLetter}
        onTimeout={() => handleChange("")}
      />
    </Flex>
  );
};

export default JumperWithTimer;
