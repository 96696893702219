/** @jsx jsx */
import { jsx, useThemeUI, Text, Box } from "theme-ui";
import React, { useEffect, useState, useCallback } from "react";

import { useModal } from "react-modal-hook";
import { useParams, useHistory } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import constants from "config/constants";
import { Trans } from "@lingui/react";
import { addOpacityToColor, transformColorToHex } from "../../theme";

interface InactivityTimerProps {
  logoutTime: number;
  warningTime: number;
}

const events = ["keyup", "click", "touchcancel", "scroll"];

const InactivityTimer: React.FC<InactivityTimerProps> = ({
  warningTime = constants.INACTIVITY_WARNING_TIME,
  logoutTime = constants.INACTIVITY_LOGOUT_TIME,
}) => {
  const [idleTime, setIdleTime] = useState<number>(0);
  const [shouldWarn, setShouldWarn] = useState<boolean>(false);
  const [shouldLogout, setShouldLogout] = useState<boolean>(false);
  const context = useThemeUI();
  const { theme } = context;
  const BACKGROUND_OPACITY = 80;
  const [showModal, hideModal] = useModal(
    () => (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2000,
        }}
      >
        <Box
          key="mask"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: `${addOpacityToColor(
              transformColorToHex(theme?.colors?.primary),
              BACKGROUND_OPACITY
            )}`,
            zIndex: "2000 !important",
            webkitBackdropFilter: "blur(10px)",
            backdropFilter: "blur(10px)" /* Supported in Chrome 76 */,
          }}
        />
        <Box
          key="container"
          sx={{
            position: "relative",
            zIndex: 2001,
            minWidth: " 320px",
            textAlign: "center",
          }}
        >
          <Text sx={{ color: "#ffffff" }} as="h1">
            <Trans id="inactivityTimer.title">Du verkar inaktiv</Trans>
          </Text>
          <Text sx={{ color: "#ffffff" }}>
            <Trans id="inactivityTimer.youWillLoggedOut">
              Du kommer att loggas ut automatiskt om
            </Trans>
          </Text>
          <Text sx={{ color: "#ffffff" }} as="h1">
            {logoutTime - idleTime} s
          </Text>
          <Text sx={{ color: "#ffffff" }}>
            <Trans id="inactivityTimer.pressAnywhere">
              Tryck var som helst för att avbryta
            </Trans>
          </Text>
        </Box>
      </Box>
    ),
    [idleTime, logoutTime]
  );
  const history = useHistory();
  const params = useParams<RouteParams>();

  const updateTimer = useCallback(
    (seconds: number) => {
      setShouldWarn(seconds + 1 >= warningTime);
      setShouldLogout(seconds + 1 >= logoutTime);
      setIdleTime(seconds + 1);
    },
    [warningTime, logoutTime, setShouldWarn, setShouldLogout]
  );

  // Timer effect
  useEffect(() => {
    const increaseTime = setTimeout(() => updateTimer(idleTime), 1000);

    return () => clearTimeout(increaseTime);
  }, [updateTimer, idleTime]);

  // Warning effect
  useEffect(() => {
    shouldWarn ? showModal() : hideModal();
  }, [shouldWarn, showModal, hideModal]);

  // Logout effect
  useEffect(() => {
    if (shouldLogout) {
      history.replace(`/${params.companyId}`);
    }
  }, [shouldLogout, history, params.companyId]);

  // Interaction effects
  useEffect(() => {
    const resetTimer = () => updateTimer(0);

    events.forEach((event) => {
      window.addEventListener(event, resetTimer, event === "scroll");
    });

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [updateTimer]);

  return null;
};

export default InactivityTimer;
