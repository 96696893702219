// Based on https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
// and https://tailwindcss.com/components

// HSL TO HEX
function HSLToHex(hsl: any) {
  let sep = hsl.indexOf(",") > -1 ? "," : " ";
  hsl = hsl.substr(4).split(")")[0].split(sep);

  let h = hsl[0],
    s = hsl[1].substr(0, hsl[1].length - 1) / 100,
    l = hsl[2].substr(0, hsl[2].length - 1) / 100;

  // Strip label and convert to degrees (if necessary)
  if (h.indexOf("deg") > -1) h = h.substr(0, h.length - 3);
  else if (h.indexOf("rad") > -1)
    h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
  else if (h.indexOf("turn") > -1)
    h = Math.round(h.substr(0, h.length - 4) * 360);
  if (h >= 360) h %= 360;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r: any = 0,
    g: any = 0,
    b: any = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}
// HSLA TO HEXA
function HSLAToHexA(hsla: any) {
  let sep = hsla.indexOf(",") > -1 ? "," : " ";
  hsla = hsla.substr(5).split(")")[0].split(sep);

  // Strip the slash
  if (hsla.indexOf("/") > -1) hsla.splice(3, 1);

  let h = hsla[0],
    s = hsla[1].substr(0, hsla[1].length - 1) / 100,
    l = hsla[2].substr(0, hsla[2].length - 1) / 100,
    a = hsla[3];

  let c: any = (1 - Math.abs(2 * l - 1)) * s,
    x: any = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    // m = l - c / 2,
    r: any = 0,
    g: any = 0,
    b: any = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  a = Math.round(a * 255).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;
  if (a.length === 1) a = "0" + a;

  return "#" + r + g + b + a;
}

// RGB TO HEX
function RGBToHex(rgb: any) {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);

  // Convert %s to 0–255
  for (let R in rgb) {
    let r = rgb[R];
    if (r.indexOf("%") > -1)
      rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
    /* Example:
      75% -> 191
      75/100 = 0.75, * 255 = 191.25 -> 191
      */
  }

  let r: any = (+rgb[0]).toString(16),
    g: any = (+rgb[1]).toString(16),
    b: any = (+rgb[2]).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}

// RGBA TO HEXA
function RGBAToHexA(rgba: any) {
  let sep = rgba.indexOf(",") > -1 ? "," : " ";
  rgba = rgba.substr(5).split(")")[0].split(sep);

  // Strip the slash if using space-separated syntax
  if (rgba.indexOf("/") > -1) rgba.splice(3, 1);

  for (let R in rgba) {
    let r = rgba[R];
    if (r.indexOf("%") > -1) {
      let p = r.substr(0, r.length - 1) / 100;

      // TODO: Check for R type
      if ((R as any) < 3) {
        rgba[R] = Math.round(p * 255);
      } else {
        rgba[R] = p;
      }
    }
  }
}

const convertHexToRGBA = (hexCode: any, opacity: any) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const transformColorToHex = (color: any) => {
  const anyToHex = (color: any) => {
    if (
      color?.match(
        /^hsl[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*[)]$/
      )
    ) {
      return HSLToHex(color);
    } else if (
      color?.match(
        /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/
      )
    ) {
      return RGBToHex(color);
    } else if (
      color?.match(
        /^^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/
      )
    ) {
      return RGBAToHexA(color);
    } else if (
      color?.match(
        /^hsla[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*,\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/
      )
    ) {
      return HSLAToHexA(color);
    } else return color;
  };

  color = anyToHex(color);
  // check if three character hex, transform to six character
  if (color?.length === 4) {
    color = color?.toString().slice(1, color?.length);
    color = color
      .split("")
      .map(function (hex: any) {
        return hex + hex;
      })
      .join("");
    return `#${color}`;
  } else {
    return color;
  }
};

export const addOpacityToColor = (color: any, opacity: any) => {
  color = transformColorToHex(color);
  color = convertHexToRGBA(color, opacity);
  return color;
};

export const getBWColorFromContext = (color: any) => {
  if (!color) return "#FFFFFF";

  // Variables for red, green, blue values
  color = transformColorToHex(color);

  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color?.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color?.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +(
      "0x" + color?.slice(1).replace(color?.length < 5 && /./g, "$&$&")
    );

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 180.65) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};

export function LightenDarkenColor(colorCode: string, amount: any) {
  colorCode = transformColorToHex(colorCode);
  var usePound = false;

  if (colorCode[0] === "#") {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  var num = parseInt(colorCode, 16);

  var r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const getTheme = (props: any) => {
  const { baseColors } = props;

  const colors = {
    ...baseColors,
  };

  const borderWidths = {
    px: "1px",
    "0": "0",
    "2": "2px",
    "4": "4px",
    "8": "8px",
  };

  const breakpoints = ["640px", "768px", "1024px", "1280px"];

  const primaryHover = LightenDarkenColor(
    props.baseColors?.primary || "#000000",
    10
  );

  const commonButtonStyles = {
    padding: "20px 15px",
    cursor: `pointer`,
    fontSize: `100%`,
    lineHeight: `inherit`,
    fontFamily: "heading",
  };

  const buttons = {
    simple: {
      ...commonButtonStyles,
      backgroundColor: `primary`,
      border: `none`,
      color: `white`,
      fontWeight: `bold`,
      borderRadius: `default`,
      "&:hover": {
        backgroundColor: `${primaryHover}`,
      },
    },
    pill: {
      ...commonButtonStyles,
      backgroundColor: `primary`,
      border: `none`,
      color: `white`,
      fontWeight: `bold`,
      borderRadius: `full`,
      "&:hover": {
        backgroundColor: `${primaryHover}`,
      },
    },
    outline: {
      ...commonButtonStyles,
      backgroundColor: `transparent`,
      borderWidth: `2px`,
      borderStyle: `solid`,
      borderColor: `primary`,
      color: `primary`,
      fontWeight: `semibold`,
      borderRadius: `default`,
      "&:hover": {
        backgroundColor: `primary`,
        color: `white`,
        borderColor: `transparent`,
      },
    },
    bordered: {
      ...commonButtonStyles,
      backgroundColor: `primary`,
      borderWidth: `1px`,
      borderStyle: `solid`,
      borderColor: `${primaryHover}`,
      color: `white`,
      fontWeight: `bold`,
      borderRadius: `default`,
      "&:hover": {
        backgroundColor: `${primaryHover}`,
      },
    },
    disabled: {
      ...commonButtonStyles,
      backgroundColor: `primary`,
      border: `none`,
      opacity: 0.5,
      cursor: `not-allowed`,
      color: `white`,
      fontWeight: `bold`,
      borderRadius: `default`,
    },
    "3D": {
      ...commonButtonStyles,
      backgroundColor: `primary`,
      border: `none`,
      borderBottomWidth: `4px`,
      borderBottomStyle: `solid`,
      borderBottomColor: `${primaryHover}`,
      color: `white`,
      fontWeight: `bold`,
      borderRadius: `default`,
      transition: `transform 0.3s ease-in-out`,
      "&:hover": {
        transform: `translateY(-1px)`,
      },
    },
    elevated: {
      ...commonButtonStyles,
      backgroundColor: `white`,
      borderWidth: `1px`,
      borderStyle: `solid`,
      borderColor: `#cbd5e0`,
      color: `black`,
      fontWeight: `bold`,
      borderRadius: `default`,
      boxShadow: `default`,
      "&:hover": {
        backgroundColor: `#f7fafc`,
      },
    },
    "outline-small-danger": {
      ...commonButtonStyles,
      backgroundColor: `transparent`,
      borderWidth: `2px`,
      borderStyle: `solid`,
      borderColor: `danger`,
      color: `danger`,
      fontWeight: `semibold`,
      borderRadius: `default`,
      alignSelf: "flex-start",
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 1,
      paddingBottom: 1,
    },
    danger: {
      ...commonButtonStyles,
      backgroundColor: `transparent`,
      borderWidth: `2px`,
      borderStyle: `solid`,
      borderColor: `danger`,
      color: `danger`,
      fontWeight: `semibold`,
      borderRadius: `default`,
    }
  };

  const baseFonts = {
    mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    body:
      'Poppins, Montserrat, Georgia, Cambria, "Times New Roman", Times, serif',
    heading:
      'Poppins, Montserrat, Georgia, Cambria, "Times New Roman", Times, serif',
  };

  const fonts = {
    ...baseFonts,
    body: baseFonts.body,
    heading: baseFonts.heading,
    monospace: baseFonts.mono,
  };

  const fontSizes = [
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.875rem",
    "2.25rem",
    "3rem",
    "4rem",
    "4.5rem",
  ];

  const baseFontWeights = {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  };

  const fontWeights = {
    ...baseFontWeights,
    body: baseFontWeights.normal,
    heading: baseFontWeights.bold,
  };

  const commonInputStyles = {
    padding: "20px 15px",
    fontSize: `100%`,
    borderRadius: `default`,
    appearance: `none`,
    lineHeight: `tight`,
    fontFamily: "body",
  };

  const inputs = {
    shadow: {
      ...commonInputStyles,
      border: `none`,
      color: `#4a5568`,
      boxShadow: `default`,
      "&:focus": {
        outline: `none`,
        boxShadow: `outline`,
      },
    },
    inline: {
      ...commonInputStyles,
      backgroundColor: `#edf2f7`,
      borderWidth: `2px`,
      borderStyle: `solid`,
      borderColor: `#edf2f7`,
      color: `#4a5568`,
      "&:focus": {
        outline: `none`,
        borderColor: `primary`,
        backgroundColor: `white`,
      },
    },
    underline: {
      ...commonInputStyles,
      backgroundColor: `transparent`,
      border: `none`,
      borderBottomWidth: `2px`,
      borderBottomStyle: `solid`,
      borderBottomColor: `primary`,
      borderRadius: `0px`,
      color: `#4a5568`,
      "&:focus": {
        outline: `none`,
        borderColor: `primary`,
        backgroundColor: `white`,
      },
    },
  };

  const letterSpacings = {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  };

  const baseLineHeights = {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  };

  const lineHeights = {
    ...baseLineHeights,
    body: baseLineHeights.relaxed,
    heading: baseLineHeights.tight,
  };

  const radii = {
    none: "0",
    sm: "0.125rem",
    default: "6px",
    lg: "0.5rem",
    full: "9999px",
  };

  const text = {
    danger: {
      color: "danger",
    },
    success: {
      color: "success",
    },
    "thin-heading": {
      fontFamily: "Open Sans",
      fontWeight: "thin",
      textTransform: "uppercase",
      fontSize: fontSizes[5],
      lineHeight: "1",
    },
  };

  const sizes = {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screenHeight: "100vh",
    screenWidth: "100vw",
  };

  const shadows = {
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  };

  const space = [
    0,
    "0.25rem",
    "0.5rem",
    "1rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
    "32rem",
  ];

  const zIndices = {
    auto: "auto",
    "0": "0",
    "10": "10",
    "20": "20",
    "30": "30",
    "40": "40",
    "50": "50",
  };

  const heading = {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
    m: 0,
    mb: 1,
  };

  const styles = {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
    h1: {
      ...heading,
      fontSize: 6,
      mt: 2,
    },
    h2: {
      ...heading,
      fontSize: 5,
      mt: 2,
    },
    h3: {
      ...heading,
      fontSize: 4,
      mt: 3,
    },
    h4: {
      ...heading,
      fontSize: 3,
    },
    h5: {
      ...heading,
      fontSize: 2,
    },
    h6: {
      ...heading,
      fontSize: 1,
      mb: 2,
    },
    code: {},
    pre: {},
    hr: {
      bg: "muted",
      border: 0,
      height: "1px",
      m: 3,
    },
  };

  const badges = {
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
      padding: '1',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
  }

  const base = {
    borderWidths,
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    sizes,
    shadows,
    space,
    radii,
    zIndices,
    styles,
    buttons,
    inputs,
    text,
    badges
  };

  return base;
};
