import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import { useQuery } from "react-query";
import * as api from "api/api";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";

interface CustomerProviderProps {
  children: any;
}

export const CustomerContext = React.createContext<
  BokaMera.Customer | undefined
>(undefined);
export const useCustomer = () => useContext(CustomerContext);

const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const { companyId, customerId } = useParams<RouteParams>();
  const { authorised } = useAuthentication();

  const customers = useQuery(
    "customers",
    () =>
      api.getCustomers({
        params: {
          CompanyId: companyId,
          CustomerId: customerId,
          IncludeCustomFieldValues: true,
          IncludeAccessKeys: true
        },
      }),
    {
      enabled: !!companyId && !!customerId && authorised,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <CustomerContext.Provider value={customers.data?.Results?.[0]}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
