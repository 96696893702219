import React, { useContext, useEffect, useState } from "react";
import useInterval from "hooks/useInterval";
import { format } from "utils/datetime";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import { useI18n } from "providers/I18nProvider/I18nProvider";

interface TimeProviderProps {
  children: any;
}

export const UPDATE_INTERVAL = 1000 * 60;

interface TimeProviderValues {
  currentDate: Date;
  currentTime: string;
  currentDay: string;
  refresh: () => void;
}

export const TimeContext = React.createContext<TimeProviderValues>({
  currentDate: new Date(),
  currentTime: format(new Date(), "p"),
  currentDay: format(new Date(), "EEEE"),
  refresh: () => {},
});

export const useTime = () => useContext(TimeContext);

const TimeProvider: React.FC<TimeProviderProps> = ({ children }) => {
  const i18n = useI18n();

  const { authorised } = useAuthentication();
  const [date, setDate] = useState({
    currentDate: new Date(),
    currentTime: format(new Date(), "p"),
    currentDay: format(new Date(), "EEEE"),
  });

  useInterval(() => {
    if (authorised) {
      setDate({
        currentDate: new Date(),
        currentTime: format(new Date(), "p"),
        currentDay: format(new Date(), "EEEE"),
      });
    }
  }, UPDATE_INTERVAL);

  useEffect(() => {
    if (authorised) {
      setDate({
        currentDate: new Date(),
        currentTime: format(new Date(), "p"),
        currentDay: format(new Date(), "EEEE"),
      });
    }
  }, [authorised]);

  const refresh = () => {
    setDate({
      currentDate: new Date(),
      currentTime: format(new Date(), "p"),
      currentDay: format(new Date(), "EEEE"),
    });
  };

  useEffect(() => {
    refresh();
  }, [i18n, i18n?.language]);

  return (
    <TimeContext.Provider value={{ ...date, refresh }}>
      {children}
    </TimeContext.Provider>
  );
};

export default TimeProvider;
