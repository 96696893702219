import React from "react";
import { ModalProvider } from "react-modal-hook";
import { Router, Route } from "react-router-dom";
import I18nProvider from "providers/I18nProvider/I18nProvider";
import AuthenticationProvider from "providers/AuthenticationProvider/AuthenticationProvider";
import AppSettingsProvider from "providers/AppSettingsProvider/AppSettingsProvider";
import LicenseProvider from "providers/LicenseProvider/LicenseProvider";
import { ToastProvider, DefaultToastContainer, DefaultToast } from "react-toast-notifications";
import Routes from "routes/Routes";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import history from "utils/history";
import { Provider as ThemeProvider } from "./ThemeProvider";
import TimeProvider from "providers/TimeProvider/TimeProvider";
import CompanyProvider from "providers/CompanyProvider/CompanyProvider";
import { AppInsightsContextProvider } from "providers/AppinsightsProvider/AppInsightsProvider";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./api/queryClient";
import { ReactQueryDevtools } from "react-query/devtools";

import "App.scss";

// See https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
// See https://www.npmjs.com/package/@react-keycloak/web

const App: React.FC = () => {
  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <AppInsightsContextProvider>
            <I18nProvider>
              <AppSettingsProvider>
                <ThemeProvider>
                  <ToastProvider
                    placement="top-center"
                    components={{
                      ToastContainer: (props) => (
                        <DefaultToastContainer
                            {...props}
                            data-testid="toast-messages"
                            // @ts-ignore
                            style={{ zIndex: 3100 }}
                          />
                      )
                    }}
                  >
                    <ModalProvider>
                      <AuthenticationProvider>
                        <TimeProvider>
                          <CompanyProvider>
                            <LicenseProvider>
                              <ErrorBoundary>
                                <Routes />
                              </ErrorBoundary>
                            </LicenseProvider>
                          </CompanyProvider>
                        </TimeProvider>
                      </AuthenticationProvider>
                    </ModalProvider>
                  </ToastProvider>
                </ThemeProvider>
              </AppSettingsProvider>
            </I18nProvider>
          </AppInsightsContextProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </QueryClientProvider>
    </div>
  );
};

export default App;
