import React, { createContext, useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import { useHistory } from "react-router-dom";

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

const reactPlugin = new ReactPlugin();

const AppInsightsContext = createContext(reactPlugin);

const AppInsightsContextProvider = ({ children }: { children: any }) => {
    const history = useHistory();

    useEffect(() => {
        if (process && process.env?.REACT_APP_ENV !== "development") {
            console.log("AI initialized with key " + instrumentationKey);
            const ai = new ApplicationInsights({
              config: {
                instrumentationKey: instrumentationKey,
                extensions: [reactPlugin],
                extensionConfig: {
                  [reactPlugin.identifier]: { history: history },
                },
              },
            });
            ai.loadAppInsights();
          }
    }, [])
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

export { AppInsightsContext, AppInsightsContextProvider };