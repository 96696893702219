/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import React, { useContext, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { KeycloakInitOptions } from "keycloak-js";
import { useHistory, useLocation } from "react-router-dom";
import keycloak from "keycloak";
import { useQuery } from "react-query";
import * as api from "api/api";
import * as actions from "./actions";
import {
  authMachine,
  AuthMachineContext,
  DEVTOOLS_ENABLED,
} from "./authMachine";
import axiosInstance from "api/axiosConfigs";
import { useToasts } from "react-toast-notifications";
import { queryClient } from "api/queryClient";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { I18n } from "@lingui/core";
import UserButtons from "UserButtons/UserButtons";
import { AppInsightsContext } from "providers/AppinsightsProvider/AppInsightsProvider";

import {
  TOKEN_STORAGE_KEY,
  UPDATE_TOKEN_INTERVAL,
  AUTH_STATE_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "config/constants";

export interface AuthStateContext {
  companyId: string;
  token: string;
  offlineToken: string;
}

interface AuthenticationProviderProps {
  children: any;
  i18n: I18n;
}

interface AuthenticationContextProps {
  accessToken: string;
  companyId?: string;
  licenseId?: string;
  isAuthInProgress: boolean;
  authenticated: boolean;
  authorised: boolean;
  isError: boolean;
  error?: string;
  login: () => void;
  disconnect: () => void;
}

export const AuthenticationContext = React.createContext<
  AuthenticationContextProps
>({
  authenticated: false,
  authorised: false,
  isError: false,
  accessToken: "",
  licenseId: "",
  companyId: undefined,
  isAuthInProgress: false,
  error: '',
  login: () => {
    /* intentional */
  },
  disconnect: () => {
    /* intentional */
  },
});

export const useAuthentication = () => useContext(AuthenticationContext);

const UNAUTHORISED_ENDPOINTS = [""];

export const keyCloakInitOptions: KeycloakInitOptions = {
  redirectUri: `${window.location.origin}/#/auth`,
  checkLoginIframe: false
};

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  i18n,
}) => {
  const history = useHistory();
  const { addToast, toastStack, removeAllToasts } = useToasts();
  const appInsights = useContext(AppInsightsContext);

  const restoredState =
    localStorage.getItem(AUTH_STATE_STORAGE_KEY) &&
    localStorage.getItem(AUTH_STATE_STORAGE_KEY) !== "{}"
      ? {
          ...JSON.parse(localStorage.getItem(AUTH_STATE_STORAGE_KEY) || "null"),
          devTools: DEVTOOLS_ENABLED,
        }
      : authMachine.initialState;

  const [current, send] = useMachine<AuthMachineContext, any, any>(
    authMachine,
    { state: restoredState, devTools: DEVTOOLS_ENABLED }
  );
  
  const isAuthInProgress =
    (typeof current.value === "string" && current.value.includes("ing")) ||
    current.matches({ manual: { authorised: "expired" } }) ||
    current.matches({ keycloak: { authorised: "expired" } })

  const isAuthorised = isObject(current.value)
    ? !!(
      current.matches({ keycloak: { authorised: "live" } }) ||
      current.matches({ manual: { authorised: "live" } })
    )
    : false;

  const isAuthenticated = (current.matches({ keycloak: 'authenticated'}) || current.matches({ manual: 'authenticated'})) || isAuthorised;
    
  const companyId = current.context.companyId;
  const token = current.context.token;
  const location = useLocation();

  const login = () => {
    send({ type: actions.KEYCLOAK_AUTHENTICATE_REQUEST });
  };

  const user = useQuery("user", () => api.getUser(), {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    onError: () => {
      send({ type: 'LOGOUT' })
    }
  });

  const isError = isObject(current.value)
  ? !!(
    current.matches({ keycloak: { authorised: "authoriseError" } }) ||
    current.matches({ manual: { authorised: "error" } }) ||
    user.isError

    )
  : false;

  useEffect(() => {
    keycloak.onTokenExpired= () => {
      keycloak.updateToken(UPDATE_TOKEN_INTERVAL);
    }
    keycloak.onAuthSuccess = () => {
      send({ type: actions.KEYCLOAK_AUTHENTICATE_SUCCESS });
    };

    keycloak.onAuthRefreshSuccess = () => {
      send({ type: actions.KEYCLOAK_TOKEN_REFRESH_SUCCESSS });
    };

    keycloak.onAuthError = (err) => {
      appInsights.trackException({
        exception: new Error('keycloak.onAuthError'),
        properties: {
          authState: current.value,
          authAction: current._event
        }
      });
      renderMessageToUser();
    };

    keycloak.onAuthRefreshError = () => {
      keycloak.refreshToken = current.context.offlineToken;
      appInsights.trackException({
        exception: new Error('keycloak.onAuthRefreshError'),
        properties: {
          authState: current.value,
          authAction: current._event
        }
      });
    };

    keycloak.onAuthLogout = () => {
      /* this is intentional */
    };

    window.onfocus = () => {
      send(actions.FIX_APP);
    };

    queryClient.setDefaultOptions({
      queries: {
        retry: 5,
        onError: (err: any) => {
          if(err?.response?.statusText === 'TokenException' || err?.response?.status === 401) {
            send(actions.SWITCH_TO_MANUAL_AUTHENTICATION);
          } else if(err?.response?.status === 403) {
            addToast(err?.response?.statusText, { appearance: 'error', autoDismiss: true })
          }
        }
      }
    })

    return () => {
      keycloak.onAuthSuccess = () => {
        /* this is intentional */
      };
      keycloak.onAuthError = () => {
        /* this is intentional */
      };
      keycloak.onAuthLogout = () => {
        /* this is intentional */
      };
      keycloak.onAuthRefreshSuccess = () => {
        /* this is intentional */
      };
      keycloak.onAuthLogout = () => {
        /* this is intentional */
      };
      window.onfocus = () => {
        /* this is intentional */
      };
    };
  }, []);

  useEffect(() => {
    if (user.isSuccess) {
      send({ type: actions.AUTHORISE_SUCCESS, payload: user.data });
    }
  }, [user.dataUpdatedAt]);

  const disconnect = () => {
    send(actions.DISCONNECT);
  };

  const renderMessageToUser = () => {
    const isRetriesExceeded = current.context.retryCount >= 3;
    const textContent = i18n._(t("info.thisIsTakingTime")``);
    const buttonTextWarning = i18n._(t("info.retryNow")``);
    const buttonTextError = i18n._(t("info.reset")``);

    const message = (
      <div>
        <p>{textContent}</p>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              queryClient.refetchQueries();
              if (isRetriesExceeded) {
                keycloak.clearToken();
              }
              removeAllToasts();
            }}
          >
            {!isRetriesExceeded ? buttonTextWarning : buttonTextError}
          </Button>
        </div>
      </div>
    );

    if (toastStack.length === 0) {
      addToast(message, {
        appearance: "info",
      });
    }
  };

  useEffect(() => {
    if (current.value === "unauthenticated") {
      history.replace("/login");
    }

    if (isError) {
      appInsights.trackException({
        exception: new Error('useEffect/isError'),
        properties: {
          authState: current.value,
          authAction: current._event
        }
      });
      renderMessageToUser();
    }
  }, [current.value, toastStack.length]);

  useEffect(() => {
    send({ type: "LOCATION_CHANGE", payload: location });
  }, [location]);
  return (
    <AuthenticationContext.Provider
      value={{
        authenticated: isAuthenticated,
        authorised: isAuthorised,
        accessToken: token,
        companyId,
        isAuthInProgress,
        disconnect,
        login,
        isError,
        // @ts-ignore
        // error: user?.error?.response?.statusText as string
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const isObject = (a: any) => typeof a === "object" && a !== null;

export default withI18n()(AuthenticationProvider);
