export const KEYCLOAK_AUTHENTICATE_REQUEST = "KEYCLOAK_AUTHENTICATE_REQUEST";
export const KEYCLOAK_AUTHENTICATE_SUCCESS = "KEYCLOAK_AUTHENTICATE_SUCCESS";
export const KEYCLOAK_AUTHENTICATE_FAILURE = "KEYCLOAK_AUTHENTICATE_FAILURE";
export const KEYCLOAK_UPDATE_TOKENS = "KEYCLOAK_UPDATE_TOKENS";
export const KEYCLOAK_TOKEN_EXPIRED = "KEYCLOAK_TOKEN_EXPIRED";
export const KEYCLOAK_TOKEN_REFRESH_SUCCESSS = "KEYCLOAK_TOKEN_REFRESH_SUCCESSS";

export const MANUALLY_AUTHENTICATED = "MANUALLY_AUTHENTICATED";

export const AUTHORISE_REQUEST = "AUTHORISE_REQUEST";
export const AUTHORISE_SUCCESS = "AUTHORISE_SUCCESS";
export const AUTHORISE_FAILURE = "AUTHORISE_FAILURE";

export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const DISCONNECT = "DISCONNECT";

export const FIX_APP = "FIX_APP";
export const AXIOS_AUTHORISE_ERROR = "AXIOS_AUTHORISE_ERROR";
export const AXIOS_AUTHORISE_SUCCESS = "AXIOS_AUTHORISE_SUCCESS";
export const AXIOS_SQL_ERROR = "AXIOS_SQL_ERROR";

export const SWITCH_TO_MANUAL_AUTHENTICATION = "SWITCH_TO_MANUAL_AUTHENTICATION";
