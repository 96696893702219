import axiosInstance from "./axiosConfigs";
import { AxiosRequestConfig } from "axios";
import constants from "config/constants";

interface KeycloakResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  session_state: string;
  scope: string;
}

export const getUser = async (
  config: AxiosRequestConfig = {
    params: { IncludeFavorites: true },
  },
  data = {}
): Promise<BokaMera.User> => {
  return axiosInstance
    .request({
      url: "/users",
      method: "GET",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getCompanies = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Companies> => {
  return axiosInstance
    .request({
      url: `/companies`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getCustomers = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Customers> => {
  return axiosInstance
    .request({
      url: `/customers`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getResources = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Resources> => {
  const requestOptions: any = {
    url: `/resource`,
    method: "get",
    baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
  };

  if (data && JSON.stringify(data) !== "{}") {
    requestOptions.data = data;
  }

  if (
    config.method?.toLowerCase() === "get" &&
    data &&
    JSON.stringify(data) !== "{}"
  ) {
    requestOptions.params = data;
  }

  if (config?.params && JSON.stringify(config.params) !== "{}") {
    requestOptions.params = config.params;
  }

  return axiosInstance
    .request(requestOptions)
    .then((res) => res.data)
    .catch((err) => {
      // TODO: handle test failing
      // might be this a problem in the app too?
    });
};

export const getLicenses = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Licences> => {
  return axiosInstance
    .request({
      url: "/licenses/company",
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getBookings = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Bookings | BokaMera.BookingsWithCustomer> => {
  return axiosInstance
    .request({
      url: `/bookings`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getLicenseTypes = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.LicenseTypes> => {
  return axiosInstance
    .request({
      url: `/licenses/types`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getVersion = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<any> => {
  return axiosInstance
    .request({
      baseURL: `${window.location.origin}`,
      url: "/version.json",
      method: "GET",
    })
    .then((res) => res.data);
};

export const getAccessToken = async (
  offlineToken: string
): Promise<KeycloakResponse> => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("client_id", process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string);
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append("refresh_token", offlineToken);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    requestOptions as any
  ).then((response: any) => response.json());
};

export const createLicense = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Licence> => {
  return axiosInstance
    .request({
      url: `/licenses/company`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const cancelLicense = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Licence> => {
  return axiosInstance
    .request({
      url: `/licenses/company`,
      method: "delete",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const createBooking = (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Booking> => {
  return axiosInstance
    .request({
      url: `/bookings`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const createTimeException = (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.TimeException> => {
  return axiosInstance
    .request({
      url: `/timeexceptions`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const unbookException = (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<any> => {
  return axiosInstance
    .request({
      url: `/timeexceptions`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const createDirectBook = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Booking> => {
  return axiosInstance
    .request({
      url: `/bookings/repeat`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const unbook = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Booking> => {
  return axiosInstance
    .request({
      url: `/bookings/repeat`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getBillingInformation = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.BillingInformation> => {
  return axiosInstance
    .request({
      url: `/billing/company`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getAvailableTimes = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.AvailableTimes> => {
  return axiosInstance
    .request({
      url: `/billing/company`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getNextFreeTime = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.FreeTime> => {
  return axiosInstance
    .request({
      url: `/services`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getServices = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.Services> => {
  return axiosInstance
    .request({
      url: `/services`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const getTimeExceptions = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<BokaMera.TimeExceptions> => {
  return axiosInstance
    .request({
      url: `/timeexceptions`,
      method: "get",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};

export const sendEmail = async (
  config: AxiosRequestConfig = {},
  data = {}
): Promise<any> => {
  return axiosInstance
    .request({
      url: `/messages/sendemailtocompany`,
      method: "post",
      ...config,
      baseURL: config.baseURL ? config.baseURL : constants.BACKEND_URL,
      data,
      params:
        config.method?.toLowerCase() === "get"
          ? data
          : config.params
          ? config.params
          : {},
    })
    .then((res) => res.data);
};
