import React from "react";
import { ReactComponent as Warning } from "svg/illustrations/warning.svg";
import Button from "components/Button/Button";
import classnames from "classnames/bind";
import styles from "./ErrorBoundary.module.scss";
import { DEVTOOLS_ENABLED } from "providers/AuthenticationProvider/authMachine";

const cx = classnames.bind(styles);

type ErrorInfo = {
  componentStack: string;
};

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if(DEVTOOLS_ENABLED) {
      console.log(error);
    }
  }

  reload = () => {
    document.location.reload(true);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={cx("container")}>
          <Warning className={cx("image")} />
          <h1 className={cx("title")}>Oops, something went wrong.</h1>
          <Button className={cx("button")} onClick={this.reload}>
            Reload
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
