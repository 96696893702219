/** @jsx jsx */
import { jsx, useThemeUI, Flex, Text, Box, Alert } from "theme-ui";
import React, { useState, useEffect } from "react";
import { withI18n } from "@lingui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { Trans, t } from "@lingui/macro";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import Select from "components/Form/Select";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import Loading from "components/Loading/Loading";
import { getBWColorFromContext } from "theme";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { I18n } from "@lingui/core";
export interface LoginProps {
  onAuthentication: (username: string, password: string) => void;
  error: string | null;
  i18n: I18n;
}

const Login: React.FC<LoginProps> = ({ onAuthentication, error, i18n }) => {
  const { login, isError, error: authError } = useAuthentication();

  const appSettings = useAppSettings();
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = (data: any) => {};

  const context = useThemeUI();
  const { theme } = context;

  useEffect(() => {
    if(isError) {
      setLoading(false);
    }
  }, [isError])

  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex sx={{ maxWidth: "960px", width: "100%" }}>
        <Flex
          sx={{
            flexDirection: "column",
            margin: 20,
            width: "100%",
          }}
        >
          <FontAwesomeIcon
            size="5x"
            icon={["fas", "plug"]}
            style={{ color: `${theme.colors?.primary}` }}
          />
          <h4>
            <Trans id="login.loginWithYourAccount">
              Var god logga in med ditt kioskkonto
            </Trans>
          </h4>
          <h1>
            <Trans id="login.heading">Anslut kiosk</Trans>
          </h1>
        </Flex>
        <Flex
          sx={{
            flexDirection: "column",
            margin: 20,
            width: "100%",
          }}
        >
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              login();
            }}
          >
            <Box>
              <Flex sx={{ justifyContent: "flex-end" }}>
                <LanguageSwitcher show />
              </Flex>
            </Box>
            <Select
              style={{ margin: "15px 0px" }}
              value={appSettings?.UseCase}
              onChange={(val) => {
                appSettings?.setAppSettings({
                  ...appSettings,
                  UseCase: val.toLocaleLowerCase(),
                  Theme: appSettings?.OverrideTheme
                    ? appSettings?.Theme.toLocaleLowerCase()
                    : val === "washing"
                    ? "poppy"
                    : "eco",
                  Typography: appSettings?.OverrideTheme
                    ? appSettings?.Theme.toLocaleLowerCase()
                    : val === "washing"
                    ? "poppy"
                    : "bokamera",
                });
              }}
              items={[
                {
                  value: "conference",
                  text: i18n._(t("appSettings.useCase.conference")``),
                },
                {
                  value: "washing",
                  text: i18n._(t("appSettings.useCase.washing")``),
                },
              ]}
            />
            <Button
              style={{ minWidth: "100%" }}
              htmlType="button"
              onTouchEnd={() => {
                setLoading(true);

                handleSubmit(onSubmit);
              }}
              onClick={() => {
                setLoading(true);
                handleSubmit(onSubmit);
              }}
            >
              {loading ? (
                <Loading
                  color={getBWColorFromContext(theme?.colors?.primary)}
                  style={{ marginTop: 0 }}
                  size={20}
                />
              ) : (
                <Trans id="login.action" />
              )}
            </Button>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default withI18n()(Login);
