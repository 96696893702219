/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import React, { forwardRef } from "react";
import { Input as ThemedInput } from "theme-ui";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: object;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ style, ...restProps }, ref?) => {
    const context = useThemeUI();
    const { theme } = context;
    return (
      <ThemedInput
        sx={{
          width: "100%",
          boxSizing: "border-box",
          fontWeight: 600,
          letterSpacing: "2px",
          padding: "12px 26px",
          textDecoration: "none",
          transition: "all 0.2s ease-in-out",
          boxShadow:
            "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          // @ts-ignore
          fontFamily: `${theme?.fonts?.body}`,
          ...style,
        }}
        {...restProps}
        ref={ref}
      />
    );
  }
);

export default Input;
