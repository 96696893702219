/** @jsx jsx */
import React, { useEffect, ReactNode, useState, useCallback } from "react";
import { jsx, useThemeUI, Flex, Text, Slider, Alert, Box, } from "theme-ui";
import { BroadcastChannel } from "broadcast-channel";
import { Slide } from "react-awesome-reveal";
import Button from "components/Button/Button";
import Switch from "components/Switch/Switch";
import Content, { ContentLeft } from "components/Content/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, t } from "@lingui/macro";
import { useAppSettings } from "providers/AppSettingsProvider/AppSettingsProvider";
import { useParams, useHistory } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import { useModal } from "react-modal-hook";
import Dialog from "components/Dialog/Dialog";
import ErrorReport from "routes/ErrorReport/ErrorReport";
import { themes, typographyThemes } from "ThemeProvider";
import Select from "components/Form/Select";
import MultiSelect, { Item } from "components/Form/MultiSelect";
import styled from "@emotion/styled";
import Input from "components/Form/Input";
import { withI18n } from "@lingui/react";
import { I18n } from "@lingui/core";
import NumPad from "components/NumPad/NumPad";
import UserButtons from "UserButtons/UserButtons";
import { useCompany } from "providers/CompanyProvider/CompanyProvider";
import { useCustomer } from "providers/CustomerProvider/CustomerProvider";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import { useQuery } from "react-query";
import * as api from "api/api";
import { useLicence } from "providers/LicenseProvider/LicenseProvider";
import Loading from "components/Loading/Loading";

export const IMAGE_STORAGE_KEY = 'bm-kdui-bg-image';

const broadcast = new BroadcastChannel("app-actions");
interface SettingsProps {
  visible: boolean;
  setSettingsVisible: Function;
  i18n: I18n;
}

const SettingLabel = styled.label`
  flex-basis: 35%;
  text-overflow: ellipsis;
  overflow: hidden;
  ${Text.name} {
    min-width: 150px;
    justify-self: flex-start;
    margin-right: 4px;
  }
`;

const SettingField = styled.div`
  text-align: center;
  flex-basis: 65%;
  max-width: 65%;
  button {
    white-space: normal;
    min-height: 56px;
    height: auto;
  }
`;

const FieldRow = ({
  children,
  // @ts-ignore
  ...restProps
}: React.PropsWithChildren<ReactNode>) => (
  <Flex
    {...restProps}
    sx={{
      alignItems: "center",
      margin: "20px 0px",
      width: "100%",
    }}
  >
    {children}
  </Flex>
);

const PIN_CODE_LENGTH = 4;
const DEFAULT_ADMIN_PIN = 5656;

const Settings: React.FC<SettingsProps> = ({
  visible = false,
  setSettingsVisible = () => {},
  i18n,
}) => {
  const company = useCompany();
  const customer = useCustomer();
  const context = useThemeUI();
  const { theme } = context;
  const appSettings = useAppSettings();
  const params = useParams<RouteParams>();
  const [number, setNumber] = useState("");
  const [error, setError] = useState<string>("");
  const [settingsAvailable, setSettingsAvailable] = useState<boolean>(false);
  const [appSettingsPinCode, setAppSettingsPinCode] = useState(
    appSettings?.AppSettingsPinCode || undefined
  );
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const { authorised } = useAuthentication();
  const licenceContext = useLicence();
  
  const services = useQuery(
    ["services", company?.Id],
    ({ queryKey }) => api.getServices({ params: { Active: true, CompanyId: queryKey[1] } }),
    {
      enabled: authorised,
    }
  );

  const showSettings = (show: boolean) => {
    setNumber("");
    setError("");
    setSettingsVisible(show);
    setSettingsAvailable(
      appSettings?.AppSettingsPinCode?.toString().length === PIN_CODE_LENGTH
        ? false
        : true
    );
  };

  const appVersion = useQuery("version", () => api.getVersion());

  useEffect(() => {
    setSettingsAvailable(
      appSettings?.AppSettingsPinCode?.toString().length === PIN_CODE_LENGTH
        ? false
        : true
    );
    setAppSettingsPinCode(
      appSettings?.AppSettingsPinCode?.toString().length === PIN_CODE_LENGTH
        ? appSettings?.AppSettingsPinCode
        : ""
    );
  }, []);

  useEffect(() => {
    if (!appSettings?.OverrideTheme) {
      if (appSettings?.UseCase === "washing") {
        appSettings.setAppSettings({
          ...appSettings,
          Typography: "poppy",
        });
      } else if (appSettings?.UseCase === "conference") {
        appSettings.setAppSettings({
          ...appSettings,
          Typography: "bokamera",
        });
      }
    }
  }, [appSettings?.UseCase]);

  useEffect(() => {
    appSettings?.setAppSettings({
      ...appSettings,
      AppSettingsPinCode: appSettingsPinCode,
    });
  }, [appSettingsPinCode]);

  useEffect(() => {
    if (
      number.length === 4 &&
      Number(number) !== Number(appSettingsPinCode) &&
      Number(number) !== DEFAULT_ADMIN_PIN
    ) {
      setError(i18n._(t("invalidPIN")`Felaktig PIN. Var god försök igen.`));
    }
  }, [number, appSettingsPinCode]);

  useEffect(() => {
    if (number.length === 4) {
      if (appSettingsPinCode === number) {
        setSettingsAvailable(true);
      } else if (Number(number) === DEFAULT_ADMIN_PIN) {
        setSettingsAvailable(true);
      } else {
        setNumber("");
      }
    }
  }, [number, company?.Id, customer?.Id, appSettingsPinCode]);

  useEffect(() => {
    broadcast.addEventListener("message", (evt) => {
      if (evt.type === "NEW_VERSION_AVAILABLE") {
        setIsNewVersionAvailable(true);
      }
    });
  }, []);

  const setPin = useCallback(
    (pin) => {
      setError("");
      setNumber(pin);
    },
    [setError, setNumber]
  );

  const resources = useQuery("resources", () => api.getResources(), {
    enabled: authorised,
    refetchOnWindowFocus: false,
  });

  const [, hideModal] = useModal(
    () => (
      <Dialog onClose={hideModal}>
        <FontAwesomeIcon
          size="1x"
          icon={["fas", "times"]}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            margin: 20,
            cursor: "pointer",
          }}
          onClick={hideModal}
        />
        <ErrorReport params={params} hideModal={hideModal} />
      </Dialog>
    ),
    []
  );

  interface NumProps {
    children?: any;
    style?: object;
  }

  const Num: React.FC<NumProps> = ({ children, style }) => {
    return (
      <Button
        style={{
          height: 62,
          width: 62,
          margin: "20px 10px",
          cursor: "default",
          fontSize: "40px",
          pointerEvents: "none",
          paddingTop: "4px",
          ...style,
        }}
        variant="elevated"
      >
        {children}
      </Button>
    );
  };

  const updateToNewVersion = () => {
    if ("serviceWorker" in navigator) {
      broadcast.postMessage({ type: "UPDATE_TO_NEW_VERSION" });
      setIsNewVersionAvailable(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  if (!appSettings) return null;

  const options = [
    i18n._(t("appSettings.Yes")``),
    i18n._(t("appSettings.No")``),
  ];

  return (
    <React.Fragment>
      {visible ? (
        <Box
          aria-label="Settings box"
          onClick={() => {
            showSettings(false);
          }}
          sx={{
            background: "#121212",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.5,
            zIndex: 1001,
          }}
        />
      ) : null}
      <Box
        data-testid="settings-menu"
        style={{ width: !visible ? "0%" : "50%", zIndex: 1002 }}
        sx={{
          height: "100%",
          width: 0,
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: `${theme?.colors?.background}`,
          overflowX: "hidden",
          transition: "0.3s",
          button: {
            fontSize: "0.85em",
          },
          li: {
            fontSize: "0.85em",
          },
          input: {
            fontSize: "0.85em",
          },
        }}
      >
        <Content
          style={{
            display: !visible ? "none" : "",
            overflow: settingsAvailable ? "all" : "hidden",
          }}
        >
          <Flex
            sx={{
              display: settingsAvailable ? "none" : "",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,

              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              flexDirection: "column",
            }}
          >
            <Trans id="auth.enterYourPIN" />
            <Flex>
              <Num style={{ marginLeft: 0 }}>
                <Box>{number[0] ? "*" : ""}</Box>
              </Num>
              <Num>
                <Box>{number[1] ? "*" : ""}</Box>
              </Num>
              <Num>
                <Box>{number[2] ? "*" : ""}</Box>
              </Num>
              <Num>
                <Box>{number[3] ? "*" : ""}</Box>
              </Num>
            </Flex>

            {error ? (
              <Slide
                direction="left"
                duration={300}
                triggerOnce={false}
                damping={0.15}
              >
                <Alert
                  sx={{
                    minWidth: 300,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Text>
                    <FontAwesomeIcon
                      size="1x"
                      icon={["fas", "info-circle"]}
                      sx={{ marginRight: 10 }}
                    />
                    {error}.
                  </Text>
                </Alert>
              </Slide>
            ) : (
              <Box sx={{ minHeight: "44px" }} />
            )}

            <NumPad
              buttonStyle={{ width: "90px", height: "90px" }}
              number={number}
              onChange={setPin}
            />
          </Flex>
          <ContentLeft
            style={{
              // @ts-ignore
              "@media screen and (max-width: 1024px)": {
                margin: "0px",
                padding: theme.space ? theme.space[3] : "10px",
              },
              position: "relative",
            }}
          >
            {isNewVersionAvailable ? (
              <Button
                data-testid="updateToNewVersion"
                onClick={updateToNewVersion}
              >
                <Trans id="settingsMenu.updateToNewVersion" />
              </Button>
            ) : null}
            <Box
              role="button"
              data-testid="settingsMenu.close"
              onClick={() => {
                showSettings(false);
              }}
              sx={{
                position: "absolute",
                top: "5px",
                right: "25px",
                fontSize: "36px",
                marginLeft: "50px",
                color: `${theme?.colors?.text}`,
                opacity: "0.4",
                cursor: "pointer",
                transition: "0.3s",
                zIndex: 9999,
                "&hover": {
                  opacity: 1,
                },
              }}
            >
              &times;
            </Box>
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                padding: 20,
                filter: settingsAvailable ? "" : "blur(10px)",
                pointerEvents: settingsAvailable ? "all" : "none",
              }}
            >
              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.general" />
                </SettingLabel>
              </FieldRow>
              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.language" />
                </SettingLabel>
                <SettingField>
                  <Switch
                    optionLabels={options}
                    id="showLanguage"
                    name="showLanguage"
                    checked={appSettings.ShowLanguage}
                    onChange={() => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        ShowLanguage: !appSettings.ShowLanguage,
                      });
                    }}
                  />
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.useCase" />
                </SettingLabel>
                <SettingField>
                  <Select
                    aria-label="Select app use case"
                    value={appSettings.UseCase}
                    onChange={(val) => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        UseCase: val.toLocaleLowerCase(),
                        Theme: appSettings?.OverrideTheme
                          ? appSettings?.Theme.toLocaleLowerCase()
                          : val === "washing"
                          ? "poppy"
                          : "eco",
                        Typography: appSettings?.OverrideTheme
                          ? appSettings?.Theme.toLocaleLowerCase()
                          : val === "washing"
                          ? "poppy"
                          : "bokamera",
                      });
                    }}
                    items={[
                      {
                        value: "conference",
                        text: i18n._(t("appSettings.useCase.conference")``),
                      },
                      {
                        value: "washing",
                        text: i18n._(t("appSettings.useCase.washing")``),
                      },
                    ]}
                  />
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Text>
                    <Trans id="appSettings.pinCode" />
                  </Text>
                </SettingLabel>
                <SettingField>
                  <Input
                    data-testid="pincodeInput"
                    id="pinCode"
                    pattern="[0-9]"
                    // @ts-ignore
                    maxLength="4"
                    type="text"
                    value={
                      appSettingsPinCode === undefined ? "" : appSettingsPinCode
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setAppSettingsPinCode(e.currentTarget.value);
                    }}
                    onBlur={(e) => {
                      if (
                        Number(e.currentTarget.value).toString().length !== 4 &&
                        e.currentTarget.value !== ""
                      ) {
                        setAppSettingsPinCode(undefined);
                        setError(
                          i18n._(
                            t(
                              "numberOfCharacters"
                            )`Pin code should have 4 characters.`
                          )
                        );
                      } else if (
                        Number(e.target.value).toString().length === 4
                      ) {
                        setError("");
                      }
                    }}
                  />
                  <Text
                    aria-label="Input error"
                    as="p"
                    sx={{
                      position: "absolute",
                      textAlign: "start",
                      // @ts-ignore
                      fontSize: theme?.fontSizes[0],
                      color: theme?.colors?.danger,
                    }}
                  >
                    {error}
                  </Text>
                </SettingField>
              </FieldRow>

              {appSettings.UseCase === "washing" ? (
                <React.Fragment>
                  <FieldRow>
                    <SettingLabel
                      sx={{
                        flexBasis: "100%",
                        fontSize: "3",
                      }}
                    >
                      <Trans id="appSettings.washing" />
                    </SettingLabel>
                  </FieldRow>
                  <FieldRow>
                    <SettingLabel>
                      <Trans id="appSettings.groupServices" />
                    </SettingLabel>
                    <SettingField>
                      <Switch
                        optionLabels={options}
                        id="groupServices"
                        name="groupServices"
                        checked={appSettings.GroupServices}
                        onChange={() => {
                          appSettings.setAppSettings({
                            ...appSettings,
                            GroupServices: !appSettings.GroupServices,
                          });
                        }}
                      />
                    </SettingField>
                  </FieldRow>
                </React.Fragment>
              ) : null}

              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.themeSettings" />
                </SettingLabel>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.overrideTheme" />
                </SettingLabel>
                <SettingField>
                  <Switch
                    optionLabels={options}
                    id="overrideTheme"
                    name="overrideTheme"
                    checked={appSettings.OverrideTheme}
                    onChange={() => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        OverrideTheme: !appSettings.OverrideTheme,
                      });
                    }}
                  />
                </SettingField>
              </FieldRow>
              {appSettings?.OverrideTheme ? (
                <React.Fragment>
                  <FieldRow>
                    <SettingLabel>
                      <Text>
                        <Trans id="appSettings.theme" />
                      </Text>
                    </SettingLabel>
                    <SettingField>
                      <Select
                        value={
                          appSettings.Theme.toString().toLocaleLowerCase() ||
                          "poppy"
                        }
                        onChange={(val) => {
                          appSettings.setAppSettings({
                            ...appSettings,
                            Theme: val.toLocaleLowerCase(),
                          });
                        }}
                        items={themes.map((theme) => ({
                          value: theme.value,
                          text: theme.value,
                        }))}
                      />
                    </SettingField>
                  </FieldRow>
                  <FieldRow>
                    <SettingLabel>
                      <Text>
                        <Trans id="appSettings.typography" />
                      </Text>
                    </SettingLabel>
                    <SettingField>
                      <Select
                        value={
                          appSettings.Typography.toString().toLocaleLowerCase() ||
                          "poppy"
                        }
                        onChange={(val) => {
                          appSettings.setAppSettings({
                            ...appSettings,
                            Typography: val.toLocaleLowerCase(),
                          });
                        }}
                        items={typographyThemes.map((theme) => ({
                          value: theme.value,
                          text: theme.value,
                        }))}
                      />
                    </SettingField>
                  </FieldRow>
                </React.Fragment>
              ) : null}
              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.conference" />
                </SettingLabel>
              </FieldRow>
              <FieldRow>
                <SettingLabel>
                  <Text>
                    <Trans id="appSettings.background" />
                  </Text>
                </SettingLabel>
                <SettingField>
                  <Input
                    type="file"
                    accept="image/*"
                    id="image-input"
                    onChange={(e) => {
                      if (
                        e &&
                        e.currentTarget.files &&
                        e.currentTarget.files[0]
                      ) {
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                          localStorage.setItem(
                            IMAGE_STORAGE_KEY,
                            reader.result as string
                          );
                        });

                        reader.readAsDataURL(e.currentTarget.files[0]);
                      }
                    }}
                  />
                </SettingField>
              </FieldRow>
              <FieldRow data-testid="selectResource">
                <SettingLabel>
                  <Trans id="appSettings.resource" />
                </SettingLabel>
                <SettingField>
                  <Select
                    aria-label="Select resource"
                    value={appSettings?.Resource?.Id.toString()}
                    onChange={(val) => {
                      const matchingResource = resources.data?.Results.find(
                        (res) => res.Id.toString() === val
                      );

                      appSettings.setAppSettings({
                        ...appSettings,
                        Resource: matchingResource,
                      });
                    }}
                    items={resources.data?.Results.map((resource) => ({
                      value: resource.Id.toString(),
                      text: resource.Name,
                    }))}
                  />
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.directBooking" />
                </SettingLabel>
                <SettingField>
                  <Switch
                    optionLabels={options}
                    id="directBooking"
                    name="directBooking"
                    checked={appSettings.ShowDirectBooking}
                    onChange={() => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        ShowDirectBooking: !appSettings.ShowDirectBooking,
                      });
                    }}
                  />
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.showName" />
                </SettingLabel>
                <SettingField>
                  <Switch
                    optionLabels={options}
                    id="showName"
                    name="showName"
                    checked={appSettings.ShowName}
                    onChange={() => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        ShowName: !appSettings.ShowName,
                      });
                    }}
                  />
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.bookingWithQR" />
                </SettingLabel>
                <SettingField>
                  <Switch
                    optionLabels={options}
                    id="bookingWithQR"
                    name="bookingWithQR"
                    checked={appSettings.ShowBookingWithQR}
                    onChange={() => {
                      appSettings.setAppSettings({
                        ...appSettings,
                        ShowBookingWithQR: !appSettings.ShowBookingWithQR,
                      });
                    }}
                  />
                </SettingField>
              </FieldRow>
              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.width" />
                </SettingLabel>
                <SettingField>
                  <Slider
                    min={1}
                    max={160}
                    defaultValue={appSettings?.CustomWidth}
                    onChange={(e) => {
                      const { value } = e.target;
                      appSettings.setAppSettings({
                        ...appSettings,
                        CustomWidth: Number(value),
                      });
                    }}
                    sx={{
                      "::-webkit-slider-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-moz-range-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-ms-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                    }}
                  />
                  <Text>{101 - appSettings?.CustomWidth}</Text>
                </SettingField>
              </FieldRow>
              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.washingRoom" />
                </SettingLabel>
              </FieldRow>
              <FieldRow data-testid="selectServicesRow">
                <SettingLabel>
                  <Trans id="appSettings.showableServices" />
                </SettingLabel>
                {services.isSuccess ? (
                  <SettingField>
                    <MultiSelect
                      data-testid="selectServices"
                      onUpdate={(items) => {
                        appSettings.setAppSettings({
                          ...appSettings,
                          selectedServicesToShow: items.length > 0 ? items.map((i) => i.value) : undefined,
                        });
                      }}
                      selectedItems={
                        appSettings.selectedServicesToShow
                          ? appSettings.selectedServicesToShow
                          : undefined
                      }
                      items={
                        services.data.Results.map((r) => ({
                          text: r.Name,
                          value: r.Id.toString(),
                        })) as Item[]
                      }
                    />
                  </SettingField>
                ) : (
                  <Loading />
                )}
              </FieldRow>
              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.opacity" />
                </SettingLabel>
              </FieldRow>
              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.left" />
                </SettingLabel>
                <SettingField>
                  <Slider
                    min={1}
                    max={100}
                    defaultValue={appSettings?.LeftContentOpacity || 80}
                    onChange={(e) => {
                      const { value } = e.target;
                      appSettings.setAppSettings({
                        ...appSettings,
                        LeftContentOpacity: Number(value),
                      });
                    }}
                    sx={{
                      "::-webkit-slider-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-moz-range-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-ms-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                    }}
                  />
                  <Text>{appSettings?.LeftContentOpacity || 80}</Text>
                </SettingField>
              </FieldRow>
              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.right" />
                </SettingLabel>
                <SettingField>
                  <Slider
                    min={1}
                    max={100}
                    defaultValue={appSettings?.RightContentOpacity || 90}
                    onChange={(e) => {
                      const { value } = e.target;
                      appSettings.setAppSettings({
                        ...appSettings,
                        RightContentOpacity: Number(value),
                      });
                    }}
                    sx={{
                      "::-webkit-slider-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-moz-range-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                      "::-ms-thumb": {
                        color: theme?.colors?.primary,
                        width: "40px",
                        height: "40px",
                      },
                    }}
                  />
                  <Text>{appSettings?.RightContentOpacity || 90}</Text>
                </SettingField>
              </FieldRow>

              <FieldRow>
                <SettingLabel
                  sx={{
                    flexBasis: "100%",
                    fontSize: "3",
                  }}
                >
                  <Trans id="appSettings.customButtonsSettings" />
                </SettingLabel>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.customButtons" />
                </SettingLabel>
                <SettingField
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Flex
                    sx={{
                      background: theme?.colors?.primary,
                      borderRadius: "6px",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "200px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      let defaultButton = {
                        id: Date.now().toString(),
                        label: "",
                        value: 10,
                      };
                      appSettings.setAppSettings({
                        ...appSettings,
                        Buttons: [...appSettings.Buttons, defaultButton],
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      size="1x"
                      icon={["fas", "plus"]}
                      sx={{
                        path: {
                          fill: theme?.colors?.background,
                        },
                      }}
                    />
                  </Flex>
                </SettingField>
              </FieldRow>

              <FieldRow>
                <Flex sx={{ flexDirection: "column" }}>
                  {appSettings.Buttons.map((button, index) => {
                    return (
                      <Flex
                        key={index}
                        sx={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          type="number"
                          sx={{
                            background: theme?.colors?.primary,
                            color: theme?.colors?.background,
                            border: "none",
                            textAlign: "center",
                            margin: "10px 0px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                          value={button.value === 0 ? "" : button.value}
                          onChange={(evt) => {
                            appSettings.Buttons.forEach((_button) => {
                              if (_button.id === button.id) {
                                _button.value = Number(evt.target.value);
                              }
                            });
                            appSettings.setAppSettings({ ...appSettings });
                          }}
                        />
                        <span
                          onClick={() => {
                            let restButtons = appSettings.Buttons.filter(
                              (_button) => {
                                return _button.id !== button.id;
                              }
                            );
                            appSettings.setAppSettings({
                              ...appSettings,
                              Buttons: [...restButtons],
                            });
                          }}
                          sx={{
                            border: `1px solid ${theme?.colors?.danger}`,
                            borderLeft: "none",
                            padding: "8px",
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={["fas", "times"]}
                            sx={{
                              path: {
                                fill: theme?.colors?.danger,
                              },
                            }}
                          />
                        </span>
                      </Flex>
                    );
                  })}
                </Flex>
              </FieldRow>

              <FieldRow>
                <SettingLabel>
                  <Trans id="appSettings.quickButtons" />
                </SettingLabel>
                <SettingField
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Flex
                    aria-label="Add quick reply button"
                    sx={{
                      background: theme?.colors?.primary,
                      borderRadius: "6px",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "200px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      let defaultQuickButton = {
                        id: Date.now().toString(),
                        value: "",
                      };
                      appSettings.setAppSettings({
                        ...appSettings,
                        QuickButtons: [
                          ...appSettings.QuickButtons,
                          defaultQuickButton,
                        ],
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      size="1x"
                      icon={["fas", "plus"]}
                      sx={{
                        path: {
                          fill: theme?.colors?.background,
                        },
                      }}
                    />
                  </Flex>
                </SettingField>
              </FieldRow>

              <FieldRow>
                <Flex sx={{ flexDirection: "column" }}>
                  {appSettings.QuickButtons.map((button, index) => {
                    return (
                      <Flex
                        key={index}
                        sx={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          sx={{
                            background: theme?.colors?.primary,
                            color: theme?.colors?.background,
                            border: "none",
                            textAlign: "center",
                            margin: "10px 0px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                          value={button.value}
                          onChange={(evt) => {
                            appSettings.QuickButtons.forEach((_button) => {
                              if (_button.id === button.id) {
                                _button.value = evt.target.value;
                              }
                            });
                            appSettings.setAppSettings({ ...appSettings });
                          }}
                        />
                        <span
                          onClick={() => {
                            let restButtons = appSettings.QuickButtons.filter(
                              (_button) => {
                                return _button.id !== button.id;
                              }
                            );
                            appSettings.setAppSettings({
                              ...appSettings,
                              QuickButtons: [...restButtons],
                            });
                          }}
                          sx={{
                            border: `1px solid ${theme?.colors?.danger}`,
                            borderLeft: "none",
                            padding: "8px",
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={["fas", "times"]}
                            sx={{
                              path: {
                                fill: theme?.colors?.danger,
                              },
                            }}
                          />
                        </span>
                      </Flex>
                    );
                  })}
                </Flex>
              </FieldRow>
            </Flex>
            <Flex
              sx={{
                marginLeft: "20px",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <UserButtons style={{ background: "red" }} buttons={["logout"]} />
            </Flex>
            <Flex
              sx={{
                position: "absolute",
                right: "2",
                bottom: "2",
                flexDirection: "column",
              }}
              variant="card"
            >
              <Flex>
                <em>
                  <Trans id="license.licenseId" />
                  {licenceContext?.licenceId}
                </em>
              </Flex>
              {appVersion.data?.build_number ? (
                <Flex>
                  <em>Build: {appVersion.data.build_number}</em>
                </Flex>
              ) : null}
            </Flex>
          </ContentLeft>
        </Content>
      </Box>
    </React.Fragment>
  );
};

export default withI18n()(Settings);
