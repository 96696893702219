/** @jsx jsx */
import { jsx, useThemeUI, Box } from "theme-ui";
import React, { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "utils/datetime";
import {
  addDays,
  parse,
  isToday,
  isPast,
  isWithinInterval,
  formatISO,
  getMinutes,
  differenceInMinutes,
  isAfter,
} from "date-fns";
import { Trans } from "@lingui/macro";
import Loading from "components/Loading/Loading";
import Scrollbar from "react-scrollbars-custom";
import Time from "./Time";
import { Flex } from "theme-ui";
import Button from "components/Button/Button";
import { useTime } from "providers/TimeProvider/TimeProvider";
import { UseMutationResult } from "react-query";
import { addMinutes } from "date-fns";
import { LEFT_TIME_MARGIN } from "./Service";

interface ScheduleProps {
  loading: boolean;
  bookingMutation?: UseMutationResult<BokaMera.Booking, any, any, unknown>;
  onClick: (time: BokaMera.Time, directBooking?: boolean) => void;
  availableTimes?: any;
  bookings?: BokaMera.Booking[] | null;
  startOfWeek: Date;
  findNextAvailbleTime: any;
}

interface Times {
  [key: string]: BokaMera.Time;
}

interface Days {
  [key: string]: Times;
}

interface BookedTime {
  [key: string]: BokaMera.Booking;
}

interface BookedDays {
  [key: string]: BookedTime;
}

const CurrentTimeMarker: React.FC<{ progress: number }> = ({ progress }) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: `${progress}%`,
        background: theme.colors?.danger,
        height: "1px",
      }}
    ></Box>
  );
};

const Week: React.FC<ScheduleProps> = ({
  loading,
  onClick,
  availableTimes,
  bookings,
  startOfWeek,
  findNextAvailbleTime,
  bookingMutation,
}) => {
  const { currentDate } = useTime();
  const [currentHourProgress, setCurrentHourProgress] = useState(
    getMinutes(new Date()) / 0.6
  );

  const getSlotDuration = (availableTimes: any) => {
    return availableTimes?.Times[0]
    ? differenceInMinutes(
        new Date(availableTimes?.Times[0].To),
        new Date(availableTimes?.Times[0].From)
      )
    : 30;
  }

  const createDateTimeSlot = (slot: any) => new Date(
    formatISO(
      new Date(
        `${new Date().toISOString().split("T")[0]}T${slot}`
      )
    ).split("+")[0]
  );

  useEffect(() => {
    const slotDuration = getSlotDuration(availableTimes);

    setCurrentHourProgress(getMinutes(currentDate) / (slotDuration / 100));
  }, [currentDate, availableTimes]);

  const days = useMemo(() => {
    const days: Days = {};

    // Import all days of week
    [...Array(7).keys()].forEach((offset) => {
      const date = addDays(startOfWeek, offset);
      const day = format(date, "yyyy-MM-dd");

      days[day] = {};
    });

    // Import available times
    availableTimes?.Times.forEach((item: BokaMera.Time) => {
      const day = item.From.substring(0, 10); // YYYY-MM-dd
      const time = item.From.substring(11, 16); // HH:mm

      if (days[day]) {
        days[day][time] = item;
      }
    });

    return days;
  }, [availableTimes, startOfWeek]);

  const bookedDays = useMemo(() => {
    const days: BookedDays = {};

    // Import all days of week
    [...Array(7).keys()].forEach((offset) => {
      const date = addDays(startOfWeek, offset);
      const day = format(date, "yyyy-MM-dd");

      days[day] = {};
    });

    // Import booked times
    bookings?.forEach((item: BokaMera.Booking) => {
      const day = item.From.substring(0, 10); // YYYY-MM-dd
      const time = item.From.substring(11, 16); // HH:mm

      if (days[day]) {
        days[day][time] = item;
      }
    });

    return days;
  }, [bookings, startOfWeek]);

  const uniqueSlots = useMemo(() => {
    const slots = Object.keys(days)
      .flatMap((day) => Object.keys(days[day]))
      .sort();

    return Array.from([...new Set<string>(slots)]);
  }, [days]);

  const refreshing = loading && availableTimes != null;
  const context = useThemeUI();
  const { theme } = context;
  const mediaMatch = window.matchMedia("screen and (min-width: 1081px)");
  const { matches } = mediaMatch;
  const SCROLLBAR_FIXED_HEIGHT = matches ? 600 : 400;
  const slotDuration = getSlotDuration(availableTimes);

  return (
    <Box>
      <Flex
        sx={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
          position: "relative",

          flexDirection: "column",
        }}
      >
        {uniqueSlots.length > 0 ? (
          <Flex
            sx={{
              marginRight: "20px",
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            {[...Array(7).keys()].map((offset) => {
              const date = addDays(startOfWeek, offset);
              const key = `th_${offset}`;
              return (
                <Box
                  key={key}
                  sx={{
                    flex: "0 0 calc(calc(100% + 10px) / 7)",
                    paddingBottom: "10px",
                    padding: "2px",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "600",
                      marginBottom: "8px",
                      fontSize: 12,
                      color: isToday(date) ? theme.colors?.primary : undefined,
                    }}
                  >
                    {format(date, "EEEEEE")}
                  </Box>
                  <Box
                    id="weekDayDate"
                    sx={{
                      color: isToday(date) ? theme.colors?.primary : undefined,
                    }}
                  >
                    {format(date, "MMM d")}
                  </Box>
                </Box>
              );
            })}
          </Flex>
        ) : null}
        <Box
          sx={{ overflowY: "hidden", paddingTop: "4px", paddingBottom: "4px" }}
        >
          <Scrollbar
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              minHeight: SCROLLBAR_FIXED_HEIGHT,
            }}
          >
            <Flex style={{ padding: 10, flexDirection: "column" }}>
              {uniqueSlots.length > 0 ? (
                uniqueSlots.map((slot, slotIndex) => {
                  const weekDays = Object.keys(days).sort();

                  const slotDateTime = createDateTimeSlot(slot);
                  const nextSlotDateTime = slotIndex + 1 < uniqueSlots.length
                    ? createDateTimeSlot(uniqueSlots[slotIndex + 1])
                    : new Date();

                  return (
                    <Flex
                      key={slot}
                      style={
                        refreshing
                          ? {
                              opacity: 0.8,
                              pointerEvents: "none",
                              position: "relative",
                            }
                          : { position: "relative" }
                      }
                    >
                      {isAfter(nextSlotDateTime, new Date()) && isWithinInterval(new Date(), {
                        start: slotDateTime,
                        end: addMinutes(slotDateTime, slotDuration),
                      })  ? (
                        <CurrentTimeMarker progress={currentHourProgress} />
                      ) : null}
                      {[...Array(7).keys()].map((offset) => {
                        const date = addDays(startOfWeek, offset);
                        const datetime = parse(slot, "HH:mm", date);

                        let booking = bookedDays[weekDays[offset]][slot];
                        if (
                          Object.keys(bookedDays[weekDays[offset]]).length >
                            0 &&
                          Object.keys(bookedDays[weekDays[offset]])[0].includes(
                            slot.split(":")[0]
                          ) &&
                          isToday(slotDateTime) &&
                          isWithinInterval(new Date(), {
                            start: addMinutes(new Date(
                              bookedDays[weekDays[offset]][
                                Object.keys(bookedDays[weekDays[offset]])[0]
                              ].From
                            ), LEFT_TIME_MARGIN),
                            end: new Date(
                              bookedDays[weekDays[offset]][
                                Object.keys(bookedDays[weekDays[offset]])[0]
                              ].To
                            ),
                          })
                        ) {
                          // TODO: handle direct booking showing futher down the
                          booking =
                            bookedDays[weekDays[offset]][
                              Object.keys(bookedDays[weekDays[offset]])[0]
                            ];
                        }

                        return (
                          <Box
                            sx={{
                              flex: "0 0 calc(100% / 7)",
                              maxWidth: "calc(100% / 7)",
                              padding: "2px",
                              boxSizing: "border-box",
                              textAlign: "center",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            data-testid={format(datetime, "yyyy-MM-dd'T'HH:mm")}
                            key={`${offset}-${slot}`}
                          >
                            <Time
                              slotDuration={slotDuration}
                              bookingMutation={bookingMutation}
                              offset={offset}
                              slot={slot}
                              time={days[weekDays[offset]][slot]}
                              booking={booking}
                              onClick={onClick}
                              past={isToday(datetime) && isPast(datetime)}
                            />
                          </Box>
                        );
                      })}
                    </Flex>
                  );
                })
              ) : loading ? (
                <Flex
                  sx={{
                    minHeight: SCROLLBAR_FIXED_HEIGHT,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading
                    color={theme?.colors?.primary}
                    message={
                      <Trans id="week.retrievingFreeTime">
                        Hämtar lediga tider
                      </Trans>
                    }
                  />
                </Flex>
              ) : (
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: SCROLLBAR_FIXED_HEIGHT,
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "calendar"]}
                    size="4x"
                    sx={{
                      color: `${theme?.colors?.primary}`,
                      marginBottom: 40,
                    }}
                  />
                  <h3>
                    <Trans id="noAvailableTimes">
                      Tyvärr finns det inga lediga tider denna veckan
                    </Trans>
                  </h3>
                  <Button
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      maxWidth: "100%",
                    }}
                    onClick={findNextAvailbleTime}
                  >
                    <Trans id="service.goToNextAvailableTime" />
                    {"  "}
                    <FontAwesomeIcon
                      size="1x"
                      icon={["fas", "angle-double-right"]}
                    />
                  </Button>
                </Flex>
              )}
            </Flex>
          </Scrollbar>
        </Box>
      </Flex>
    </Box>
  );
};

export default Week;
