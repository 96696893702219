/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React, { ReactNode } from "react";
import { RotateSpinner } from "react-spinners-kit";

interface LoadingProps {
  message?: ReactNode;
  size?: number;
  style?: object;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({
  message,
  size = 60,
  style,
  color = "#fff",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
      role="alert"
      aria-busy="true"
    >
      <RotateSpinner color={color} size={size} />
      <Box sx={{ marginTop: message ? 3 : undefined }}>
        <h3>{message}</h3>
      </Box>
    </Box>
  );
};

export default Loading;
