import { getFullLanguageCode, getCurrentLanguageCode } from "utils/i18n";
import axios from "axios";
import constants from "config/constants";
import { TOKEN_STORAGE_KEY } from "../config/constants";

let token = localStorage.getItem(TOKEN_STORAGE_KEY);

const headers: any = {
  "x-language": getFullLanguageCode(getCurrentLanguageCode())
};

if (token) {
  headers.Authorization = `Bearer ${token}`;
}

// sample configurations
const axiosInstance = axios.create({
  headers,
  baseURL: constants.BACKEND_URL,
  timeout: 30000,
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["x-api-key"] = constants.API_KEY;
    config.headers["Content-Type"] = "application/json";
    config.headers["x-language"] = getFullLanguageCode(
      getCurrentLanguageCode()
    );
    return config;
  },
);

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  const kcSessionId = localStorage.getItem('KC_SS_ID')


  if(token) {
    config.headers[
      "Authorization"
    ] = `Bearer ${token}`;
  }

  if(kcSessionId) {
    config.headers['x-ss-id'] = kcSessionId;
  }

  return config;
});

export default axiosInstance;
