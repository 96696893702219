import {
  format as _format,
  parseISO as _parseISO,
  toDate as _toDate,
  Locale,
  formatISO,
  addMinutes,
  isAfter,
  formatDistanceStrict as _formatDistanceStrict,
} from "date-fns";
import { enUS, sv, nb, fi } from "date-fns/locale";
import { LANGAUGE_STORAGE_KEY } from "providers/I18nProvider/I18nProvider";

//==================================================================================
// Module contains localised wrappers for date-fns functions and some custom utils
//==================================================================================

const locales = {
  en: enUS,
  sv,
  no: nb,
  fi,
};

type LocaleKey = "en" | "sv" | "no" | "fi";

const getLocale = (): Locale => {
  const key =
    (window.localStorage?.getItem(LANGAUGE_STORAGE_KEY) as LocaleKey) || "sv";
  return locales[key];
};

export const toDate = (value: Date | string | number): Date =>
  typeof value === "string" ? _parseISO(value) : _toDate(value);

export const format = (date: Date | string, formatStr: string): string => {
  return _format(toDate(date), formatStr, { locale: getLocale() });
};

export const toDateISOString = (date: Date, time?: string) => {
  const _time = time ? time : format(date, "kk:mm:ss");

  return formatISO(
    new Date(`${date.toISOString().split("T")[0]}T${_time}`)
  ).split("+")[0];
};

interface Event {
  From: string;
  To: string;
  [index: string]: any;
}
export const isAfterPredicate = (
  booking: Event,
  event: any,
  dateToCompare: Date
) =>
  isAfter(
    new Date(booking.From),
    event ? addMinutes(new Date(event.From), -1) : dateToCompare
  );

export const formatDistanceStrict = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    addSuffix?: boolean;
    unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
    roundingMethod?: "floor" | "ceil" | "round";
    locale?: Locale;
  }
) => {
  let _options = options || {};
  if (!_options.locale) {
    _options.locale = getLocale();
  }
  // console.log(+)
  return _formatDistanceStrict(date, baseDate, _options);
};

export const parseISO = (str: string): Date => _parseISO(str);

export const getRoundedDate = (
  margin: number,
  d = new Date(),
  method = Math.round
) => {
  if ((d.getMinutes() / margin) % 1 === 0) {
    d.setSeconds(0);
    return d;
  }

  d.setMinutes(getRoundedMinutes(d.getMinutes(), margin, method));
  d.setSeconds(0);

  return d;
};

const getRoundedMinutes = (minutes: number, margin = 5, method = Math.round) =>
  (method(minutes / margin) * margin) % 60;

