import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { withI18n } from "@lingui/react";
import * as iots from "io-ts";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { nanoid } from "nanoid";

interface ProviderProps {
  children: any;
  i18n: I18n;
}

interface AppSettingsValues {
  resetSettings: () => void;
  CustomWidth: number;
  ConferenceStyle: boolean;
  ShowLatestNews: boolean;
  Theme: string;
  Typography: string;
  UseCase: "washing" | "conference";
  Resource?: BokaMera.Resource;
  ShowDirectBooking?: any;
  ShowName: boolean;
  ShowBookingWithQR?: any;
  ShowLanguage?: boolean;
  AppSettingsPinCode?: string;
  selectedServicesToShow?: string[];
  OverrideTheme?: boolean;
  GroupServices?: boolean;
  Buttons: {
    id: string;
    label: string;
    /**
     * minutes
     */
    value: number;
  }[];
  QuickButtons: {
    id: string;
    value: string;
  }[];
  [index: string]: any;
}

export interface AppSettings extends AppSettingsValues {
  setAppSettings: Dispatch<SetStateAction<AppSettingsValues>>;
}

export const AppSettingsContext = React.createContext<AppSettings | undefined>(
  undefined
);

export const useAppSettings = () => useContext(AppSettingsContext);

const defaultAppSettings: AppSettingsValues = {
  resetSettings: () => {},
  CustomWidth: 80,
  AppWidth: "",
  ConferenceStyle: true,
  ShowLatestNews: false,
  Theme: "poppy",
  Typography: "poppy",
  ConferenceBackgroundUrl: "https://bokamera.se/demo/conference-room.jpg",
  UseCase: "washing",
  ShowDirectBooking: true,
  ShowBookingWithQR: true,
  ShowLanguage: false,
  AppSettingsPinCode: "",
  OverrideTheme: false,
  GroupServices: false,
  ShowName: true,
  Buttons: [
    { id: nanoid(8), label: "", value: 30 },
    { id: nanoid(8), label: "", value: 60 },
  ],
  QuickButtons: [],
};

const appSettingsCodecRequired = iots.type({
  CustomWidth: iots.number,
  AppWidth: iots.union([iots.string, iots.undefined]),
  ConferenceStyle: iots.boolean,
  ShowLatestNews: iots.boolean,
  Theme: iots.string,
  Typography: iots.string,
  ConferenceBackgroundUrl: iots.string,
  UseCase: iots.string,
  ShowDirectBooking: iots.boolean,
  ShowBookingWithQR: iots.boolean,
  ShowLanguage: iots.boolean,
  ShowName: iots.boolean,
  AppSettingsPinCode: iots.string,
  OverrideTheme: iots.boolean,
  Buttons: iots.array(
    iots.type({
      id: iots.string,
      label: iots.string,
      value: iots.number,
    })
  ),
  QuickButtons: iots.array(
    iots.type({
      id: iots.string,
      value: iots.string,
    })
  ),
});

const appSettingsCodecOptional = iots.partial({
  Resource: iots.type({
    Id: iots.number,
    Name: iots.union([iots.string, iots.undefined]),
    Color: iots.union([iots.string, iots.undefined]),
    ImageUrl: iots.union([iots.string, iots.undefined]),
    Email: iots.union([iots.string, iots.undefined]),
    MobilePhone: iots.union([iots.string, iots.undefined]),
    EmailNotification: iots.boolean,
    SMSNotification: iots.boolean,
    EmailReminder: iots.union([iots.boolean, iots.undefined]),
    SMSReminder: iots.union([iots.boolean, iots.undefined]),
    Active: iots.union([iots.boolean, iots.undefined]),
  }),
});

const appSettingsCodec = iots.intersection([
  appSettingsCodecRequired,
  appSettingsCodecOptional,
]);

const AppSettingsProvider = ({ children, i18n }: ProviderProps) => {
  useEffect(() => {
    const storedSettings = JSON.parse(
      window.localStorage.getItem("appSettings") ||
        JSON.stringify(defaultAppSettings)
    );

    const appCodecResult = appSettingsCodec.decode(storedSettings);

    if (appCodecResult._tag === "Left") {
      setAppSettings(defaultAppSettings);
    }
  }, []);

  useEffect(() => {
    const storedSettings = JSON.parse(
      window.localStorage.getItem("appSettings") ||
        JSON.stringify(defaultAppSettings)
    );

    const appCodecResult = appSettingsCodec.decode(storedSettings);

    defaultAppSettings.QuickButtons = [
      {
        id: "appSettings.quickButton1",
        value: i18n._("appSettings.quickButton1"),
      },
      {
        id: "appSettings.quickButton2",
        value: i18n._("appSettings.quickButton2"),
      },
    ];

    const translatedQuickButtons = appSettings.QuickButtons.map((button) => {
      const translationId = String(button.id);

      if (i18n._(translationId) !== translationId) {
        return {
          id: button.id,
          value: i18n._(translationId),
        };
      }

      return button;
    });

    appSettings.QuickButtons = translatedQuickButtons;

    if (appCodecResult._tag === "Left") {
      setAppSettings(defaultAppSettings);
    } else {
      const appSettingsWithTranslations = {
        ...appSettings,
        QuickButtons: translatedQuickButtons,
      };
      setAppSettings(appSettingsWithTranslations);
    }
  }, [i18n.language]);

  const resetResource = () => {
    setAppSettings(defaultAppSettings);
  };

  const [appSettings, setAppSettings] = useState<AppSettingsValues>(() => {
    defaultAppSettings.QuickButtons = [
      {
        id: "appSettings.quickButton1",
        value: i18n._("appSettings.quickButton1"),
      },
      {
        id: "appSettings.quickButton2",
        value: i18n._("appSettings.quickButton2"),
      },
    ];

    return JSON.parse(
      window.localStorage.getItem("appSettings") ||
        JSON.stringify(defaultAppSettings)
    );
  });

  useEffect(() => {
    window.localStorage.setItem("appSettings", JSON.stringify(appSettings));
  }, [appSettings]);

  return (
    <AppSettingsContext.Provider
      value={{ ...appSettings, setAppSettings, resetSettings: resetResource }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export default withI18n()(AppSettingsProvider);
