/** @jsx jsx */
import { jsx } from "theme-ui";
import { isWithinInterval } from "date-fns";
import React from "react";

interface LiveProps {
    booking: { From: string; To: string; Duration: number };
    date: Date;
    children?: React.ReactNode;
  }
  
export const Live: React.FC<LiveProps> = ({ booking, date, children }) => {
    if (!children) return null;
  
    return isWithinInterval(date, {
      start: new Date(booking.From),
      end: new Date(booking.To),
    }) ? (
      <React.Fragment>{children}</React.Fragment>
    ) : null;
  };