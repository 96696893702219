/** @jsx jsx */
import { jsx, Flex, useThemeUI, Text, Box } from "theme-ui";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "react-modal-hook";

import { RouteParams } from "routes/Routes";
import Dialog from "components/Dialog/Dialog";
import Button from "components/Button/Button";
import { format, parseISO, toDateISOString } from "utils/datetime";
import { Trans } from "@lingui/macro";
import { useToasts } from "react-toast-notifications";
import { AxiosError } from "axios";
import {
  addHours,
  addSeconds,
  getDay,
  isAfter,
  isSameDay,
  startOfHour,
  isWithinInterval,
  addMinutes,
  formatISO,
} from "date-fns";
import { getBWColorFromContext, LightenDarkenColor } from "../../theme";
import { useTime } from "providers/TimeProvider/TimeProvider";
import { UseMutationResult } from "react-query";
import Loading from "components/Loading/Loading";

interface TimeProps {
  offset: number;
  slot: string;
  slotDuration: number;
  time: BokaMera.Time;
  booking?: BokaMera.Booking | BokaMera.BookingWithCustomer;
  onClick: (time: BokaMera.Time, directBooking?: boolean) => void;
  bookingMutation?: UseMutationResult<BokaMera.Booking, any, any, unknown>;
  past: boolean;
  disableClick?: boolean;
}

const Time: React.FC<TimeProps> = ({
  offset,
  slot,
  time,
  booking,
  onClick,
  past,
  bookingMutation,
  slotDuration,
  disableClick = false,
}) => {
  const params = useParams<RouteParams>();
  const { addToast } = useToasts();
  const context = useThemeUI();
  const { theme } = context;
  const { currentDate, currentTime } = useTime();
  
  const LightTimeElement: React.FC = ({ children }) => {
    const context = useThemeUI();
    const { theme } = context;
    
    return <Flex
          key={key}
          sx={{
            padding: "10px 20px",
            margin: "2px",
            background: `${LightenDarkenColor(
              theme?.colors?.primary || "",
              50
            )}`,
            color: getBWColorFromContext(
              LightenDarkenColor(theme?.colors?.primary || "", 50)
            ),
            borderRadius: "6px",
            fontSize: "0.8em",
            cursor: "not-allowed",
            fontWeight: "bold",
            minHeight: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >{children}</Flex>
  }

  const slotDateTime = new Date(
    formatISO(
      new Date(
        `${new Date().toISOString().split("T")[0]}T${slot}`
      )
    ).split("+")[0]
  );

  useEffect(() => {
    if (bookingMutation?.isSuccess) {
      hideModal();
    }
  }, [bookingMutation]);


  const [showModal, hideModal] = useModal(
    () => {
      const _time = time?.From ? time : {
        From: toDateISOString(addSeconds(currentDate, 3)),
        To: toDateISOString(addHours(startOfHour(currentDate), 1)),
        Free: 1,
      };

      return (
      <Dialog style={{ paddingBottom: "1rem" }} onClose={bookingMutation?.isLoading ? () => {} : hideModal}>
        {isSameDay(parseISO(_time.From), parseISO(_time.To)) ? (
          <Flex sx={{ flexDirection: "column", padding: "15px 0px" }}>
            <Text sx={{ fontWeight: "bold" }}>
              {format(parseISO(_time.From), "PPP")}
            </Text>
            <Text as="h2" sx={{ marginBottom: "1rem" }}>
              {format(parseISO(_time.From), "EEEE")}{" "}
              {format(parseISO(_time.From), "p")} -{" "}
              {format(parseISO(_time.To), "p")}
            </Text>
          </Flex>
        ) : (
          <React.Fragment>
            <Flex
              sx={{
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  {format(parseISO(_time.From), "EEE PP")}
                </Text>
                <Text as="h1">{format(parseISO(_time.From), "p")}</Text>
              </Flex>
              <Box
                sx={{
                  margin: "0 12px",
                  fontWeight: "bold",
                  fontSize: "36px",
                }}
              >
                {" "}
                -{" "}
              </Box>
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  {format(parseISO(_time.To), "EEE PP")}
                </Text>
                <Text as="h1">{format(parseISO(_time.To), "p")}</Text>
              </Flex>
            </Flex>
          </React.Fragment>
        )}
        <Flex sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Button
            style={{ width: "150px", mr: "2", ml: "2" }}
            variant="outline"
            disabled={bookingMutation?.isLoading}
            onClick={hideModal}
          >
            <Trans id="common.goBack">Avbryt</Trans>
          </Button>

          <Button
            style={{ width: "150px" }}
            loading={bookingMutation?.isLoading}
            onClick={() => {
              const isDirectBooking = isWithinInterval(currentDate, {
                start: slotDateTime,
                end: addMinutes(slotDateTime, slotDuration),
              });

              onClick(_time, isDirectBooking);
            }}
          >
            <Trans id="common.bookTime">Boka tid</Trans>
          </Button>
        </Flex>
      </Dialog>
    )},
    [bookingMutation, time, onClick]
  );

  
  // @ts-ignore
  const isCurrentTimeLoading = bookingMutation?.isLoading && time &&  bookingMutation.context && bookingMutation.context.booking && bookingMutation.context.booking.From === time.From;

  const key = `${slot}_${offset}`;
  if (time && !booking) {
    if (time.Free > 0) {
      return (
        <Flex
          key={key}
          sx={{
            padding: "10px 20px",
            margin: "2px",
            background: `${theme?.colors?.primary}`,
            color: getBWColorFromContext(theme?.colors?.primary),
            borderRadius: "6px",
            fontSize: "0.8em",
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "50px",
            justifyContent: "center",
            alignItems: "center",
            ":active": {
              animation: "whileTap 300ms both",
            },
          }}
          onClick={() => showModal()}
        >
          {isCurrentTimeLoading ? (
            <Loading size={15} color={`${theme?.colors?.background}`} />
          ) : time.From ? (
            format(time.From, "p")
          ) : (
            slot
          )}
        </Flex>
      );
    } else {
      // @ts-ignore
      if (booking?.Customer) {
        // @ts-ignore
        const isMe = booking?.Customer?.Id === params.customerId;
        const bgColor = isMe
          ? `${LightenDarkenColor(theme?.colors?.primary || "", 120)}`
          : `${LightenDarkenColor(theme?.colors?.primary || "", 50)}`;

        return (
          <Flex
            key={key}
            sx={{
              padding: "10px 20px",
              margin: "2px",
              background: bgColor,
              color: getBWColorFromContext(bgColor),
              borderRadius: "6px",
              fontSize: "0.8em",
              cursor: "not-allowed",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              minHeight: "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={["fas", "check"]} size="1x" />{" "}
            <Text sx={{ paddingLeft: "4px" }}>
              {
                // @ts-ignore
                booking?.Customer?.Lastname
              }
            </Text>
          </Flex>
        );
      } else {
        return (
          <LightTimeElement>
            <Trans id="common.occupied" />
          </LightTimeElement>
        );
      }
    }
  } else {
    if (
      past &&
      isAfter(
        startOfHour(new Date()),
        new Date(toDateISOString(new Date(), slot))
      )
    ) {
      return (
       <LightTimeElement>
          <Trans id="common.passed" />
        </LightTimeElement>
      );
    } else {
      const currentDay = getDay(currentDate);

      if (booking) {
        const isMe = booking?.Customer?.Id === params.customerId;
        const bgColor = isMe
          ? `${LightenDarkenColor(theme?.colors?.primary || "", 120)}`
          : `${LightenDarkenColor(theme?.colors?.primary || "", 50)}`;

        return (
          <Flex
            key={key}
            sx={{
              padding: "10px 20px",
              margin: "2px",
              background: bgColor,
              color: getBWColorFromContext(bgColor),
              borderRadius: "6px",
              fontSize: "0.8em",
              cursor: "not-allowed",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              minHeight: "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={["fas", "check"]} size="1x" />
            <Text sx={{ paddingLeft: "4px" }}>
              {booking?.Customer?.Lastname}
            </Text>
          </Flex>
        );
      } else if (currentDay - 1 === offset && !isWithinInterval(currentDate, {
        start: slotDateTime,
        end: addMinutes(slotDateTime, slotDuration),
      })) {
        return (
          <Flex
            key={key}
            sx={{
              padding: "10px 20px",
              margin: "2px",
              background: `${LightenDarkenColor(
                theme?.colors?.primary || "",
                50
              )}`,
              color: getBWColorFromContext(
                LightenDarkenColor(theme?.colors?.primary || "", 50)
              ),
              borderRadius: "6px",
              fontSize: "0.8em",
              cursor: "not-allowed",
              fontWeight: "bold",
              minHeight: "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Trans id="common.passed" />
          </Flex>
        );
      } else if (currentDay - 1 === offset)
        return (
          <Flex
            key={key}
            sx={{
              padding: "3px 6px",
              margin: "2px",
              background: `${LightenDarkenColor(
                theme?.colors?.primary || "",
                -40
              )}`,
              color: getBWColorFromContext(
                LightenDarkenColor(theme?.colors?.primary || "", 50)
              ),
              borderRadius: "6px",
              fontSize: "0.8em",
              cursor: "pointer",
              fontWeight: "bold",
              pointerEvents: bookingMutation?.isLoading ? "none" : "all",
              minHeight: "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              showModal();
            }}
          >
            <Flex sx={{ flexDirection: "column" }}>
              {isCurrentTimeLoading ? (
                <Loading size={15} color={`${theme?.colors?.background}`} />
              ) : (
                <React.Fragment>
                  <Text>{currentTime}</Text>
                  <Text sx={{ fontSize: "0.65rem" }}>
                    <Trans id="common.bookRemainingTime" />
                  </Text>
                </React.Fragment>
              )}
            </Flex>
          </Flex>
        );
    }

    return <Box key={key}></Box>;
  }
};

export default Time;
