/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import React from "react";
import "./Switch.scss";

interface SwitchProps {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: any;
  optionLabels?: any;
  small?: boolean;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  id,
  name,
  checked = false,
  onChange = () => {},
  optionLabels = ["Yes", "No"],
  small = false,
  disabled = false,
}) => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        aria-label={name}
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            sx={{
              ":before": {
                background: theme?.colors?.primary,
              },
            }}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
          />
        </label>
      ) : null}
    </div>
  );
};

export default Switch;
