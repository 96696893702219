/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "providers/AuthenticationProvider/AuthenticationProvider";
import { useQuery } from "react-query";
import * as api from "api/api";

interface CompanyProviderProps {
  children: any;
}

export const CompanyContext = React.createContext<BokaMera.Company | undefined>(
  undefined
);

export const useCompany = () => useContext(CompanyContext);

const CompanyProvider: React.FC<CompanyProviderProps> = ({ children }) => {
  const history = useHistory();
  const { companyId, accessToken, authorised } = useAuthentication();
  const companies = useQuery(
    "companies",
    () =>
      api.getCompanies({
        params: {
          Id: companyId,
          IncludeSystemSettings: true,
          IncludeBookingAgreements: true,
        },
      }),
    {
      enabled: authorised,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    // we want this to be triggered only for /:companyId
    if (
      accessToken &&
      companyId &&
      history.location.pathname.match(/\//g)?.length === 1
    ) {
      history.push(companyId);
    }
  }, [accessToken, companyId]);

  const company =
    accessToken && companyId ? companies.data?.Results?.[0] : undefined;

  return (
    <CompanyContext.Provider value={company}>
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
