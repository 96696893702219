/** @jsx jsx */
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import { ReactComponent as CheckIcon } from "svg/checkMark.svg";

const StyledFlex = styled.span`
  max-width: 100%;

  @keyframes dash {
    0% {
      stroke-dashoffset: 745.74853515625;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const ANIMATION_DURATION = 2500;

const AnimatedCheck = ({
  color,
  duration = ANIMATION_DURATION,
  style,
}: {
  color?: string;
  duration?: number;
  style?: any;
}) => {
  return (
    <StyledFlex>
      <CheckIcon
        width="100%"
        height="100%"
        sx={{
          path: {
            stroke: color || "#FFFFFF",
            strokeDashoffset: "745.74853515625",
            strokeDasharray: "745.74853515625",
            animation: `dash ${ANIMATION_DURATION}ms ease-out forwards`,
          },
          ...style,
        }}
      />
    </StyledFlex>
  );
};

export default AnimatedCheck;
