/** @jsx jsx */
import { jsx, Image } from "theme-ui";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import { Text } from "theme-ui";
import Button from "components/Button/Button";

interface ServiceProps {
  service: BokaMera.Service;
}

const Service: React.FC<ServiceProps> = ({ service }) => {
  const history = useHistory();
  const params = useParams<RouteParams>();

  return (
    <Button
      variant="elevated"
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        WebkitJustifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
      }}
      onClick={() =>
        history.push(
          `/${params.companyId}/${params.customerId}/services/${service.Id}`
        )
      }
    >
      <Text as="h3">{service.Name}</Text>
      {service.ImageUrl && <Image alt={service.Name} src={service.ImageUrl} />}
    </Button>
  );
};

export default Service;
