/** @jsx jsx */
import { jsx, useThemeUI, Box, Badge, Text, Input } from "theme-ui";
import React, { useState, useCallback, useEffect } from "react";
import { t } from "@lingui/macro";
import { withI18n, withI18nProps } from "@lingui/react";
import { useMultipleSelection, useCombobox } from "downshift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBWColorFromContext } from "../../theme";

export interface Item {
  value: string; text: React.ReactText 
}

interface Props extends React.HTMLAttributes<HTMLDivElement>, withI18nProps  {
  onUpdate?: (items: Item[]) => void;
  value?: string | number;
  items?: Item[];
  selectedItems?: string[];
  label?: string;
  placeholder?: string;
}


const MultiSelect: React.FC<Props> = ({
  items = [],
  selectedItems = [],
  value,
  onUpdate = () => {},
  label,
  placeholder,
  i18n,
  ...rest
}) => {
  const [updatedSelectedItems, setUpdatedSelectedItems] = useState(items.filter(i => selectedItems.includes(i.value)));
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    onUpdate(updatedSelectedItems);
  }, [updatedSelectedItems]);

  const {
    removeSelectedItem,
    addSelectedItem,
    getDropdownProps,
    getSelectedItemProps,
  } = useMultipleSelection({
    selectedItems: updatedSelectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if(newSelectedItems) {
            setUpdatedSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    },
  });


  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.text.toString() : ''
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const {changes, type} = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          }
        default:
          return changes
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            setUpdatedSelectedItems([...updatedSelectedItems, newSelectedItem])
            setInputValue('')
          }
          break

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue!)

          break
        default:
          break
      }
    },
  })


  const context = useThemeUI();
  const { theme } = context;

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        borderColor: "transparent",
        borderRadius: "6px",
        fontSize: "1em",
        fontWeight: "600",
        letterSpacing: "2px",
        margin: "0 0 15px",
        padding: 1,
        textDecoration: "none",
        transition: "all 0.2s ease-in-out",
        whiteSpace: "nowrap",
        WebkitFontSmoothing: "antialiased",
        WebkitAppearance: "none",
        MozAppearance: "none",
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        color: `${theme?.colors?.text}`,

        cursor: "pointer",
        maxWidth: "100%",
        outline: "none",
        bg: `${theme?.colors?.background}`,

        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        border: `1px solid ${getBWColorFromContext(theme.colors?.background)}`,
        // @ts-ignore
        fontFamily: theme?.fonts?.body,
      }}
      {...rest}
    >
      <Box sx={{ display: "flex", flex: 1, flexWrap: "wrap", gap: 1 }}>
        {updatedSelectedItems.map((item, index) => {
          return (
            <Box
              {...getSelectedItemProps({
                selectedItem: item,
                index,
              })}
            >
              <Badge variant="outline" pr={4} sx={{ position: 'relative' }}>
                <Text as="span" mr={1}>
                  {item.text}
                </Text>
                <FontAwesomeIcon
                  sx={{ position: "absolute", right: 2, top: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(item);
                  }}
                  icon={["fas", "close"]}
                />
              </Badge>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Input
          {...getInputProps(
            getDropdownProps({
              preventKeyAction: isOpen,
              placeholder:
                items.length === updatedSelectedItems.length
                  ? i18n._(t`MultiSelect.nothingLeftPlaceholder`)
                  : i18n._(t`MultiSelect.startTypingPlaceholder`)
            })
          )}
          {...getToggleButtonProps()}
        />
        <ul
          sx={{
            maxHeight: "200px",
            overflowY: "auto",
            width: "100%",
            position: "absolute",
            margin: "0",
            border: isOpen
              ? `1px solid ${getBWColorFromContext(theme.colors?.background)}`
              : "",
            borderTop: "0",
            bg: `${theme?.colors?.background}`,
            color: `${theme?.colors?.text}`,
            listStyle: "none",
            padding: "0",
            borderRadius: "6px",
            outline: "none",
            zIndex: 100,
          }}
          {...getMenuProps()}
        >
          {isOpen &&
            items.map((dropdownItem, index) => (
              <li
                sx={{
                  // 
                  /**
                   * because normal filtering doesn't work for some reason
                   * we show based on input matching and what's not already selected, hide others
                   */
                  display: `${
                    !updatedSelectedItems
                      .map((s) => s.value)
                      .includes(dropdownItem.value) &&
                    dropdownItem.text
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                      ? "list-item"
                      : "none"
                  }`,
                  padding: " 12px 26px",
                  fontSize: '1rem!important',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  zIndex: 999,
                  backgroundColor:
                    highlightedIndex === index
                      ? `${theme?.colors?.primary}`
                      : "",
                  color:
                    highlightedIndex === index
                      ? `${theme?.colors?.background}`
                      : "",
                }}
                key={`${dropdownItem.value}${index}`}
                {...getItemProps({ item: dropdownItem, index })}
              >
                {dropdownItem.text}
              </li>
            ))}
        </ul>
      </Box>
    </Box>
  );
};

export default withI18n()(MultiSelect);
