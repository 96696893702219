/** @jsx jsx */
import { Flex, jsx, Text, Box } from "theme-ui";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useHistory, useParams } from "react-router-dom";
import { RouteParams } from "routes/Routes";
import Button from "components/Button/Button";

interface UserProps {
  customer: BokaMera.Customer;
}

// TODO: add appartment number

const User: React.FC<UserProps> = ({ customer }) => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const hasPinCode = !!customer?.AccessKeys?.find(key => key.AccessKeyTypeId === 1);

  return (
    <Button
      variant="elevated"
      style={{ width: "100%", margin: "10px 0px" }}
      onClick={() => {
        if (hasPinCode) {
          history.push(`/${params.companyId}/${customer.Id}`);
        } else {
          history.push(`/${params.companyId}/${customer.Id}/home`);
        }
      }}
      data-testid={customer.Firstname?.charAt(0) + " " + customer.Lastname}
    >
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {/* {
                  customer.CustomFieldValues?.find(
                    (value) => value.Name === "Lägenhetsnummer"
                  )?.Value
                } */}
          <Text as="h3">
            {customer.Firstname?.charAt(0)}. {customer.Lastname}
          </Text>
        </Box>
        <Box>
          <Box>
            {hasPinCode ? (
              <FontAwesomeIcon
                size="1x"
                icon={["fas", "lock"]}
                sx={{ marginRight: 10 }}
              />
            ) : null}
          </Box>
        </Box>
      </Flex>
    </Button>
  );
};

export default User;
