import React, { useEffect, useRef } from "react";

function SelectedLetter({
  timeout,
  letter,
  onTimeout,
}: {
  timeout: number;
  letter: string;
  onTimeout: () => void;
}) {
  const letterRef = useRef("");
  letterRef.current = letter;

  useEffect(() => {
    const timerId = setTimeout(() => {
      onTimeout();
    }, timeout);
    return () => {
      clearTimeout(timerId);
    };
  }, [letter]);

  return <span style={{ display: "none" }}>{letter}</span>;
}

export default SelectedLetter;
