import { getRoundedDate } from "utils/datetime";
import {
  isWithinInterval,
  isToday,
  isTomorrow,
  endOfDay,
  differenceInMinutes,
  isAfter,
  isBefore,
  formatISO,
  isFuture,
} from "date-fns";

const ROUND_TIME = 5;

export const calculateNextFreeTime = (events: any[]) => {
  if (!events) return;

  const date = getRoundedDate(ROUND_TIME, new Date(), Math.floor);

  const normalizedEvents = events.map((evt) => ({
    From: evt.FromTime
      ? formatISO(
          new Date(
            `${new Date(evt.From).toISOString().split("T")[0]}T${evt.FromTime}`
          )
        ).split("+")[0]
      : evt.From,
    To: evt.ToTime
      ? formatISO(
          new Date(
            `${new Date(evt.From).toISOString().split("T")[0]}T${evt.ToTime}`
          )
        ).split("+")[0]
      : evt.To,
    Duration: undefined,
  }));

  let hasOngoingEvent = false;
  let consecutiveEvents = false;
  let futureEvent = null;

  normalizedEvents.forEach((booking) => {
    if (
      isWithinInterval(new Date(), {
        start: new Date(booking.From),
        end: new Date(booking.To),
      })
    ) {
      hasOngoingEvent = true;
    }
  });

  let nextTime = normalizedEvents
    .sort((a, b) => {
      return isBefore(new Date(a.To), new Date(b.To)) ? 1 : 0;
    })
    .reduce(
      (acc, curr, index, arr) => {
        if (isFuture(new Date(curr.From))) {
          futureEvent = curr;
        }

        if (
          index > 0 &&
          arr[index - 1] &&
          differenceInMinutes(
            new Date(curr.From),
            new Date(arr[index - 1].To)
          ) === 0
        ) {
          consecutiveEvents = true;
        }

        if (
          !acc.From &&
          !acc.To &&
          isBefore(date, new Date(curr.From)) &&
          isToday(new Date(curr.From))
        ) {
          // from now till the next event
          acc.From = formatISO(date).split("+")[0];
          acc.To = curr.From;
          acc.Duration = differenceInMinutes(
            new Date(acc.To),
            new Date(acc.From)
          );
        } else if (
          !acc.From &&
          !acc.To &&
          isBefore(date, new Date(curr.From))
        ) {
          // till the end of the day
          acc.From = formatISO(date).split("+")[0];
          acc.To = formatISO(endOfDay(date)).split("+")[0];
          acc.Duration = differenceInMinutes(
            new Date(acc.To),
            new Date(acc.From)
          );
        } else if (
          !acc.From &&
          arr[index + 1] &&
          isWithinInterval(date, {
            start: new Date(curr.From),
            end: new Date(curr.To),
          })
        ) {
          acc.From = curr.To;
          acc.To = arr[index + 1].From;
          acc.Duration = differenceInMinutes(
            new Date(acc.To),
            new Date(acc.From)
          );
        } else if (
          differenceInMinutes(new Date(acc.To), new Date(acc.From)) <= 5 &&
          !arr[index + 1]
        ) {
          acc.From = curr.To;
          acc.To = formatISO(endOfDay(date)).split("+")[0];
          acc.Duration = differenceInMinutes(
            new Date(acc.To),
            new Date(acc.From)
          );
        }

        return acc;
      },
      { From: "", To: "", Duration: 0 }
    );

  if (consecutiveEvents) {
    nextTime = normalizedEvents.reduce(
      (acc, curr, index, arr) => {
        const isAfterAndNotTomorrow = isAfter(new Date(curr.From), date) &&
        arr[index - 1] &&
        isAfter(new Date(arr[index - 1].To), date) &&
        !isTomorrow(new Date(curr.From)) &&
        differenceInMinutes(
          new Date(curr.From),
          new Date(arr[index - 1].To)
        ) > 0;
        
        if (
          (
            !hasOngoingEvent &&
            index > 0 &&
            isAfterAndNotTomorrow
          ) ||
          (
            hasOngoingEvent &&
            index > 1 &&
            isAfterAndNotTomorrow
          )
        ) {
          acc.From = arr[index - 1].To;
          acc.To = curr.From;
          acc.Duration = differenceInMinutes(
            new Date(acc.To),
            new Date(acc.From)
          );
        }
        return acc;
      },
      { From: nextTime.From, To: nextTime.To, Duration: nextTime.Duration }
    );
  }

  if (hasOngoingEvent && nextTime.Duration === 0 && !futureEvent) {
    nextTime.From = normalizedEvents[normalizedEvents.length - 1].To;
    nextTime.To = formatISO(endOfDay(date)).split("+")[0];
    nextTime.Duration = differenceInMinutes(
      new Date(nextTime.To),
      new Date(nextTime.From)
    );
  } else if (nextTime.Duration === 0 && !futureEvent) {
    nextTime.From = formatISO(date).split("+")[0];
    nextTime.To = formatISO(endOfDay(date)).split("+")[0];
    nextTime.Duration = differenceInMinutes(
      new Date(nextTime.To),
      new Date(nextTime.From)
    );
  } else if (
    nextTime.Duration === 0 &&
    futureEvent &&
    !hasOngoingEvent &&
    !consecutiveEvents
  ) {
    nextTime.From = formatISO(date).split("+")[0];
    // @ts-ignore
    nextTime.To = futureEvent.From;
    nextTime.Duration = differenceInMinutes(
      new Date(nextTime.To),
      new Date(nextTime.From)
    );
  }

  if (nextTime && isTomorrow(new Date(nextTime.To))) {
    nextTime.To = formatISO(endOfDay(date)).split("+")[0];
    nextTime.Duration = differenceInMinutes(
      new Date(nextTime.To),
      new Date(nextTime.From)
    );
  }

  if (nextTime.Duration > ROUND_TIME) {
    return nextTime;
  }
};

export interface Time {
  From: Date;
  To: Date;
  Duration: number;
}

export const getFromNow = (time: Time, curentDate = new Date()) => {
  const displayFrom = new Date(new Date(time.From));
  displayFrom.setMinutes(curentDate.getMinutes());

  return displayFrom;
};
